<template>
  <b-modal
    id="result-details-modal"
    title="Result Details"
    size="xl"
    hide-footer
    @shown="getStudentStats"
  >
    <b-overlay :show="isLoading || !problem">
      <problems-report
        v-if="problem"
        :problem="problem"
      />
      <div v-else
           class="py-2"
      />
    </b-overlay>
  </b-modal>
</template>

<script setup>
import useJwt from '@/auth/jwt/useJwt'
import { BModal, BOverlay } from 'bootstrap-vue'
import ProblemsReport from '@/views/common/class/components/ProblemsReport.vue'
import { ref } from 'vue'

const props = defineProps({
  loaderId: {
    type: Number,
    default: () => null,
  },
})

const problem = ref(null)
const isLoading = ref(false)

const getStudentStats = () => {
  if (!problem.value) {
    isLoading.value = true
    useJwt.getStudentLessonStatsByLoader(props.loaderId).then(response => {
      problem.value = response.data.data
    }).finally(() => {
      isLoading.value = false
    })
  }
}
</script>
