<template>
  <b-card
    v-if="data"
    no-body
    class="card-developer-meetup"
  >
    <!-- <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div> -->
    <div class="p-2">
      <b-tabs pills>
        <b-tab>
          <template #title>
            <span>
              {{ $t('labels.past') }}
            </span>
            <b-badge variant="light-danger"
                     class="ml-1"
            >
              {{ data.past.length }}
            </b-badge>
          </template>
          <b-tabs>
            <b-card v-if="!data.past?.length">
              <b-card-body
                class="text-center mt-2"
              >
                <b-card-text>
                  {{ $t('messages.no-past-event') }}
                </b-card-text>
              </b-card-body>
            </b-card>
            <b-tab v-for="items,index of groupByKey(data.past, 'class_id')"
                   :key="index"
            >
              <template #title>
                {{ items[0].name }} <b-button
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  @click="openExternalLink(items[0])"
                >
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>
              </template>
              <event-lists :event-lists="items"
                           :class-id="index"
                           type="past"
              />
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab :title="$t('labels.ongoing')"
               active
        >
          <template #title>
            <span>
              {{ $t('labels.ongoing') }}
            </span>
            <b-badge variant="light-success"
                     class="ml-1"
            >
              {{ data.ongoing?.length }}
            </b-badge>
          </template>

          <b-tabs>
            <b-card v-if="!data.ongoing?.length">
              <b-card-body
                class="text-center mt-2 "
              >
                <b-card-text>
                  {{ $t('messages.no-ongoing-event') }}
                </b-card-text>
              </b-card-body>
            </b-card>
            <b-tab v-for="items,index of groupByKey(data.ongoing, 'class_id')"
                   :key="index"
            >
              <template #title>
                {{ items[0].name }} <b-button
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  @click="openExternalLink(items[0])"
                >
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>
              </template>
              <event-lists :event-lists="items"
                           :class-id="index"
                           type="ongoing"
              />
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab :title="$t('labels.upcoming')">
          <template #title>
            <span>
              {{ $t('labels.upcoming') }}
            </span>
            <b-badge variant="light-warning"
                     class="ml-1"
            >
              {{ data.upcoming?.length }}
            </b-badge>
          </template>

          <b-tabs>
            <b-card v-if="!data.upcoming?.length">
              <b-card-body
                class="text-center mt-2"
              >
                <b-card-text>
                  {{ $t('messages.no-event') }}
                </b-card-text>
              </b-card-body>
            </b-card>
            <b-tab v-for="items,index of groupByKey(data.upcoming, 'class_id')"
                   :key="index"
            >
              <template #title>
                {{ items[0].name }} <b-button
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  @click="openExternalLink(items[0])"
                >
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>
              </template>
              <event-lists :event-lists="items"
                           :class-id="index"
                           type="upcoming"
                           :clickable-link="false"
              />
            </b-tab>
          </b-tabs>
        </b-tab>

      </b-tabs>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  //  BImg,
  VBTooltip, BTabs, BTab, BBadge, BButton, BCardText, BCardBody,
} from 'bootstrap-vue'
import EventLists from './components/EventLists.vue'

export default {
  components: {
    BCard,
    // BImg,
    BTabs,
    BTab,
    EventLists,
    BBadge,
    BCardText,
    BButton,
    BCardBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openExternalLink(room) {
      window.open(`/view-students/${room.class_id}`)
    },
  },
}
</script>
<style lang="scss">
@media(width:375px) {
  .card-developer-meetup .nav-pills .nav-link {
    padding: 5px !important;

    .badge{
      margin-left: 5px !important;
    }
  }

}
</style>
