<template>
  <div>
    <page-edit
      :page-id="idOfPageBeingEdited"
      :show="idOfPageBeingEdited!=null"
      @update="getLessonPages"
      @close="idOfPageBeingEdited=null"
    />
    <page-show
      :page-id="idOfPageBeingViewed"
      :show="idOfPageBeingViewed!=null"
      @close="idOfPageBeingViewed=null"
    />

    <b-row>
      <b-col
        md="6"
      >
        <b-form-group>
          <b-form-input
            :placeholder="$t('search')"
            type="text"
            class="d-inline-block"
            @input="searchNetworksTable"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="d-flex justify-content-end"
      >
        <div>
          <b-button
            variant="outline-primary"
            @click="toggleCreateModal('create-lessonPage', 'createLessonPage')"
          >
            {{ $t("actions.create-lessonPage") }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <!-- list of users table -->
      <b-col md="12 mt-2">
        <b-row>
          <b-col>
            <p class="text-capitalize">
              <strong> {{ $t('lessonPage-module.list-of-lesson') }}</strong>
            </p>
          </b-col>
        </b-row>

        <b-overlay
          id="overlay-background"
          :show="isProcessing"
          variant="transparent"
          opacity="0.8"
          blur="0.8"
          rounded="sm"
        >
          <vue-good-table
            :columns="columnsForLessonsPageTable"
            :rows="lessonPages"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            theme="my-theme"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'name'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.name }}</span>
              </span>
              <span
                v-if="props.column.field === 'color_scheme'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.color_scheme }}</span>
              </span>
              <span
                v-if="props.column.field === 'pages'"
                class="text-nowrap"
              >
                <b-table
                  v-if="props.row.pages.length>0"
                  responsive="sm"
                  :fields="[{ key: 'title', label:$t('lesson-pages-module.title')} , {key: 'actions', label: $t('lessonPage-module.lesson-table.action')}]"
                  :items="props.row.pages"
                >
                  <template #cell(actions)="data">
                    <b-button
                      class="btn btn-icon mr-1"
                      size="sm"
                      variant="outline-primary"
                      @click="openLessonPage(props.row.id,data.item.id)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      class="btn btn-icon mr-1"
                      variant="outline-primary"
                      size="sm"
                      @click="idOfPageBeingEdited = data.item.id"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </template>
                </b-table>
              </span>
              <span
                v-else-if="props.column.field === 'action'"
                class="d-flex"
              >
                <span>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :disabled="isProcessing"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="
                      toggleUpdateModal(
                        props.row.id,
                        props.row.name,
                        'update-lessonPage',
                        'updateLessonPage',
                        props.row.color_scheme,
                        props.row
                      )
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </span>

                <span>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-delete-user
                    :disabled="isProcessing"
                    variant="outline-danger"
                    class="btn-icon sm-mt-2"
                    @click="
                      toggleDeleteModal(
                        props.row.id,
                        'delete-lessonPage',
                        'deleteLessonPage'
                      )
                    "
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span></span>

            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <b-form-select
                    v-model="paginationData.perPage"
                    :options="['3','5','10','15','20']"
                    :disabled="isProcessing"
                    class="mr-1"
                    @input="getLessonPages()"
                  />
                  <span class="text-nowrap"> {{ $t('of') }} {{ paginationData.totalItems }} {{ $t('lesson-pages-module.lesson-pages') }} </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="paginationData.totalItems"
                    :per-page="paginationData.perPage"
                    :disabled="isProcessing"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>getLessonPages(value)"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
            <template slot="emptystate">
              <p class="text-center">
                {{ $t('messages.no-data-available') }}
              </p>
            </template>
          </vue-good-table>
        </b-overlay>
        <!-- list of users table -->

      </b-col>
    </b-row>

    <b-modal
      id="modal-create"
      ref="modal-create"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.' + modalMode)"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer ref="createItemForm">
          <b-form
            class="auth-login-form"
            @submit.prevent="createItem"
          >
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('lesson-pages-module.course')"
                    >
                      <validation-provider
                        v-slot="{errors}"
                        :name="$t('lesson-pages-module.course')"
                        rules="required"
                        vid="course_id"
                      >
                        <b-form-select
                          id="event-lesson"
                          v-model="newItem.course_id"
                          :options="courses"
                          :state="errors.length > 0 ? false : null"
                          @change="(id)=>getLessonGroups(id,true)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('lesson-pages-module.lesson-group')"
                    >
                      <validation-provider
                        v-slot="{errors}"
                        :name="$t('lesson-pages-module.lesson-group')"
                        rules="required"
                        vid="lesson_group_id"
                      >
                        <b-form-select
                          id="event-lesson"
                          v-model="newItem.lesson_group_id"
                          :options="lessonGroups"
                          :disabled="!newItem.course_id"
                          :state="errors.length > 0 ? false : null"
                          @change="(id)=>getLessons(id,true)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('lesson-pages-module.lesson')"
                      label-for="event-lesson"
                    >
                      <validation-provider
                        v-slot="{errors}"
                        :name="$t('lesson-pages-module.lesson')"
                        rules="required"
                        vid="lesson_id"
                      >

                        <b-form-select
                          id="event-lesson"
                          v-model="newItem.lesson_id"
                          :disabled="!newItem.lesson_group_id"
                          :options="lessons"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Language Levels -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('labels.language-level')"
                      label-for="langLevel"
                      rules="required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="language level"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="langLevel"
                          v-model="newItem.lang_level"
                          name="langLevel"
                          :options="langLevels"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- name -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.' + modalMode)"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('labels.name')"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="newItem.name"
                      name="item_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      @keyup.enter="createItem(modalFunction)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- name -->
              <!-- Subjects -->
              <b-col>
                <b-form-group
                  :label="$t('standards.name.color_scheme')"
                  label-for="Color scheme"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="color scheme"
                    vid="color_scheme"
                    rules="required"
                  >
                    <b-form-select
                      id="color_scheme"
                      v-model="color_scheme"
                      :options="color_schemes"
                      :state="errors.length > 0 ? false : null"
                      name="color_scheme"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <theme-preview :theme="colorSchemeId" />
                <!-- Subjects -->
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="createItem(modalFunction)"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions." + modalMode) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal
      id="modal-update"
      ref="modal-update"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.' + modalMode)"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer ref="updateItemForm">
          <b-form
            class="auth-login-form"
            @submit.prevent="createItem"
          >
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('lesson-pages-module.course')"
                    >
                      <validation-provider
                        v-slot="{errors}"
                        :name="$t('lesson-pages-module.course')"
                        rules="required"
                        vid="course_id"
                      >
                        <b-form-select
                          id="event-lesson"
                          v-model="newItem.course_id"
                          :options="courses"
                          :state="errors.length > 0 ? false : null"
                          @change="(id)=>getLessonGroups(id,true)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('lesson-pages-module.lesson-group')"
                    >
                      <validation-provider
                        v-slot="{errors}"
                        :name="$t('lesson-pages-module.lesson-group')"
                        rules="required"
                        vid="lesson_group_id"
                      >
                        <b-form-select
                          id="event-lesson"
                          v-model="newItem.lesson_group_id"
                          :options="lessonGroups"
                          :disabled="!newItem.course_id"
                          :state="errors.length > 0 ? false : null"
                          @change="(id)=>getLessons(id,true)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('lesson-pages-module.lesson')"
                      label-for="event-lesson"
                    >
                      <validation-provider
                        v-slot="{errors}"
                        :name="$t('lesson-pages-module.lesson')"
                        rules="required"
                        vid="lesson_id"
                      >

                        <b-form-select
                          id="event-lesson"
                          v-model="newItem.lesson_id"
                          :disabled="!newItem.lesson_group_id"
                          :options="lessons"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Language Levels -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('labels.language-level')"
                      label-for="langLevel"
                      rules="required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="language level"
                        rules="required"
                      >
                        <b-form-radio-group
                          id="langLevel"
                          v-model="newItem.lang_level"
                          name="langLevel"
                          :options="langLevels"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <!-- name -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.' + modalMode)"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('labels.name')"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="newItem.name"
                      name="item_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      @keyup.enter="updateItem"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- name -->
              <!-- Subjects -->
              <b-col>
                <b-form-group
                  :label="$t('standards.name.color_scheme')"
                  label-for="Color scheme"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="color_scheme"
                    vid="color_scheme"
                    rules="required"
                  >
                    <b-form-select
                      id="color_scheme"
                      v-model="color_scheme"
                      :options="color_schemes"
                      :state="errors.length > 0 ? false : null"
                      name="color_scheme"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <theme-preview :theme="colorSchemeId" />

                <!-- Subjects -->
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="updateItem(modalFunction)"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions." + modalMode) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.' + modalDeleteMode)"
      @ok="deleteItem"
    >
      <h4> {{ $t('actions.delete-lessonPage-question') }} </h4>
      <template #modal-footer="{ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormInput, BFormGroup, BPagination, BOverlay, BFormSelect, BTable, BForm, BSpinner, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import ThemePreview from '@/views/common/components/ThemePreview.vue'
import _ from 'lodash'
import PageEdit from './PageEdit.vue'
import PageShow from './PageShow.vue'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BPagination,
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BCol,
    BFormGroup,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BTable,
    PageEdit,
    PageShow,
    ThemePreview,
    BSpinner,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isProcessing: false,
      idOfPageBeingEdited: null,
      idOfPageBeingViewed: null,
      courses: [],
      lessonGroups: [],
      lessons: [],
      searchTerm: '',
      pageLength: 10,
      columnsForLessonsPageTable: [
        {
          label: this.$i18n.tc('lessonPage-module.lesson-table.name'),
          field: 'name',
          width: '100px',
        },
        {
          label: this.$i18n.tc('lessonPage-module.lesson-table.color_scheme'),
          field: 'color_scheme',
        },
        {
          label: this.$i18n.tc('lessonPage-module.lesson-table.pages'),
          field: 'pages',
        },
        {
          label: this.$i18n.tc('lessonPage-module.lesson-table.action'),
          field: 'action',
        },
      ],
      lessonPages: [],
      paginationData: {
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        lastPage: 0,
      },
      self: getUserData(),
      modalMode: null,
      newItem: {},
      color_schemes: [
        { value: null, text: i18n.tc('lesson-pages-module.select-color-scheme') },
        { value: 'color_scheme_1', text: 'Theme 1', id: 1 },
        { value: 'color_scheme_2', text: 'Theme 2', id: 2 },
        { value: 'color_scheme_3', text: 'Theme 3', id: 3 },
        { value: 'color_scheme_4', text: 'Theme 4', id: 4 },
        { value: 'color_scheme_5', text: 'Theme 5', id: 5 },
        { value: 'color_scheme_6', text: 'Theme 6', id: 6 },
        { value: 'color_scheme_7', text: 'Theme 7', id: 7 },
        { value: 'color_scheme_8', text: 'Theme 8', id: 8 },
        { value: 'color_scheme_9', text: 'Theme 9', id: 9 },
      ],
      modalFunction: null,
      color_scheme: null,
      modalDeleteMode: null,
      deleteItemIndex: null,
      langLevels: [
        { value: 1, text: 'A1' },
        { value: 2, text: 'A2' },
        { value: 3, text: 'B1' },
        { value: 4, text: 'B2' },
      ],
    }
  },

  computed: {
    colorSchemeId() {
      return this.color_schemes.find(c => c.value === this.color_scheme).id
    },
  },

  mounted() {
    this.getLessonPages()
    this.getCourses()
  },

  methods: {
    getLessons(id, clear) {
      if (clear) this.newItem.lesson_id = null
      this.lessons = this.lessonGroups[this.lessonGroups.findIndex(e => e.id === id)].lessons.map(e => ({
        value: e.id, id: e.id, text: e.name,
      }))
      this.lessons.unshift({ value: null, text: i18n.tc('requests.select-lesson') })
    },
    getCourses() {
      useJwt.getCourses(null, {
        params: {
          type: 'curriculum',
        },
      }).then(res => {
        this.courses = res.data.data.map(e => ({
          value: e.id, id: e.id, groups: e.groups, text: e.name,
        }))
        this.courses.unshift({ value: null, text: i18n.tc('requests.select-course') })
      })
    },
    getLessonGroups(id, clear) {
      if (clear) this.newItem.lesson_group_id = null
      this.lessonGroups = this.courses[this.courses.findIndex(e => e.id === id)].groups.map(e => ({
        value: e.id, id: e.id, lessons: e.lessons, text: e.name,
      }))
      this.lessonGroups.unshift({ value: null, text: i18n.tc('requests.select-lesson-group') })
    },
    openLessonPage(lessonId, pageId) {
      const routeData = this.$router.resolve({ name: 'lesson-pages', params: { lessonId, pageId } })
      window.open(routeData.href, '_blank')
    },
    networkCreated(network) {
      this.networks.unshift(network)
    },
    searchNetworksTable: _.debounce(function (term) {
      this.searchTerm = term
      this.getLessonPages(1)
    }, 800),
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.last_page,
      }
    },
    getLessonPages(page = this.paginationData.currentPage) {
      const params = {
        page,
        perPage: this.paginationData.perPage,
        search: this.searchTerm,
      }
      this.isProcessing = true
      useJwt.getLessonPages({
        params,
      }).then(response => {
        this.isProcessing = false
        this.lessonPages = response.data.data.data
        this.assignPaginationData(response.data.data)
      })
    },
    toggleCreateModal(mode, funct, lessonPageId) {
      this.lesson_id = lessonPageId
      this.modalFunction = funct
      this.modalMode = mode
      this.newItem = {
        name: '',
        lang_level: 1,
      }
      this.color_scheme = null
      this.$refs['modal-create'].toggle()
      this.getCourses()
    },
    createItem(mode) {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          let param = { ...this.newItem }
          if (this.modalFunction === 'createLessonPage') param = { ...this.newItem, color_scheme: this.color_scheme }
          useJwt[mode](param)
            .then(response => {
              this.$refs['modal-create'].hide()
              this.newItem = {}
              this.getLessonPages()
              this.showSuccessMessage(response)
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.showError(error.response.data.errors[0])
                this.$refs.createItemForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    updateItem() {
      this.$refs.updateItemForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          let param = { ...this.newItem }
          param = { ...this.newItem, color_scheme: this.color_scheme }
          useJwt[this.modalFunction](this.newItem.id, param)
            .then(response => {
              this.$refs['modal-update'].hide()
              this.newItem = {}
              this.getLessonPages()
              this.showSuccessMessage(response)
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                // this.showError(error.response.data.errors[0])
                this.$refs.updateItemForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    deleteItem() {
      this.isProcessing = true
      useJwt[this.modalFunction](this.deleteItemIndex)
        .then(response => {
          this.$refs['modal-delete'].hide()
          this.getLessonPages()
          this.showSuccessMessage(response)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    toggleUpdateModal(id, itemValue, mode, funct, colorScheme, itemDetail = null) {
      this.newItem = {
        id,
        name: itemValue,
        course_id: itemDetail.lesson?.lesson_group?.course_id,
        lesson_group_id: itemDetail.lesson?.lesson_group?.id,
        lesson_id: itemDetail.lesson?.id,
        lang_level: itemDetail.version,
      }
      this.modalFunction = funct
      this.modalMode = mode
      this.$refs['modal-update'].toggle()
      this.color_scheme = colorScheme
      this.getCourses()
      this.getLessonGroups(this.newItem.course_id, false)
      this.getLessons(this.newItem.lesson_group_id, false)
    },
    toggleDeleteModal(index, mode, funct) {
      this.deleteItemIndex = index
      this.modalDeleteMode = mode
      this.modalFunction = funct
      this.$refs['modal-delete'].toggle()
    },

  },
}
</script>
