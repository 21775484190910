<template>
  <div id="real-time-monitor">
    <div
      v-if="$route.params.classId"
      class="pb-1"
    >
      <b-button
        variant="flat-secondary"
        class="btn-icon"
        @click="$router.push({name: `${self.usertype}-view-students`, params: {id: classId} })"
      >
        <feather-icon
          size="20"
          icon="ArrowLeftIcon"
        />
      </b-button>

      <span>
        {{ $t('actions.back') }}
      </span>
    </div>
    <div class="p-1 d-flex justify-content-between">
      <div>
        <h3>
          {{ $t('navigation-menu.real-time-monitor') }}  <span
            v-if="onlineStudents.length"
            class="text-success"
          >| <span v-if="!filters.comparativeView">{{ $t('user-module.user-table.online') }}:</span> {{ onlineStudents.length }} {{ $t('user') }}<span v-if="onlineStudents.length > 1">s</span> </span>
          <span> | {{ realTimeData.filter( loader => loader.status === 'in_progress' || loader.status === 'intervention' ).length }} {{ $t('in-progress') }}</span>
        </h3>
      </div>
      <div class="d-flex">
        <div class="d-flex">
          <div>{{ $t('labels.offline-view') }}</div>
          <div class="ml-2">
            <b-form-checkbox
              v-model="filters.comparativeView"
              checked="true"
              class="custom-control-success"
              name="check-button"
              switch
              @change="getRealTimeData"
            />
          </div>
        </div>
        <div class="ml-2 ">
          <b-button
            :disabled="isProcessing"
            variant="outline-secondary"
            size="sm"
            @click="getRealTimeData"
          >
            <feather-icon icon="RefreshCcwIcon" />  {{ $t('labels.reload') }}
          </b-button>
        </div>
      </div>
    </div>
    <b-card>
      <b-row>
        <b-col sm="4">
          <label>{{ $t('student-report-module.lesson-table.lesson') }}</label>
          <v-select
            v-model="lessonId"
            :placeholder="$t('select-lesson')"
            label="name"
            :clearable="false"
            :reduce="(lesson) => lesson.id"
            :options="lessonList"
          />
        </b-col>
        <b-col sm="4">
          <label>{{ $t('messages.tableHeader.status') }}</label>
          <v-select
            v-model="filters.status"
            label="label"
            :clearable="false"
            :reduce="(status) => status.value"
            :options="lessonStatusList"
          />
        </b-col>
        <b-col
          v-if="!classroomId"
          sm="4"
        >
          <label>{{ $t('class-room') }}</label>
          <v-select
            v-model="classId"
            label="name"
            :clearable="false"
            :reduce="(lesson) => lesson.id"
            :options="classRoomList"
            @input="getRealTimeData"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="isProcessing">
      <b-card body-class="p-0 table-responsive  mb-0 flipped">
        <b-table-simple class="table table-striped table-hover table-bordered">
          <b-thead>
            <b-tr>
              <b-th class="fixed-left-column">
                {{ $t('user-types.student') }}
              </b-th>
              <b-th
                :colspan="computedRealTimeData.problems.length || 1"
                class="text-center"
              >
                {{ $t('labels.problem') }}
              </b-th>
              <b-th class="fixed-right-column">
                {{ $t('messages.tableHeader.status') }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="timeData of computedRealTimeData.loaders"
              :key="timeData.id"
            >
              <b-td class="fixed-left-column">
                <b>{{ timeData.studentName }}</b>
              </b-td>
              <b-td v-if="computedRealTimeData.problems.length === 0" />
              <b-td
                v-for="(problem, problemIndex) of computedRealTimeData.problems"
                :key="problemIndex + '-'+ problem"
                v-b-tooltip.hover.bottom
                :class="[`bg-${getProblemStatColor(timeData, problem)} text-white`,
                         timeData.load == (problemIndex + 1) && 'is-playing'
                ]"
                :title="problem"
                class="text-center"
                @click="getDataForPreview(problem, timeData.userId, timeData.classId )"
              >
                {{ problemIndex + 1 }}
              </b-td>
              <b-td class="fixed-right-column">
                <div :style="{width: computedRealTimeData.problems.length > 10 && '120%' }">
                  <div>
                    <b-progress
                      v-b-tooltip.hover.bottom
                      :max="computedRealTimeData.problems.length"
                      :title="getStatusLabel(timeData.status)"
                      class="mb-1"
                      :class="`progress-bar-danger`"
                    >
                      <b-progress-bar
                        :value="timeData.pass.length"
                        :variant="getVariantByStatus(timeData.status)"
                      />
                    </b-progress>
                    <div style="margin-top: -10px">
                      {{ getPercentage(timeData.pass.length, computedRealTimeData.problems.length) }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="text-warning">
                      <b>{{ timeData.engine }}</b>
                    </div>
                    <div>
                      <b>|</b>
                    </div>
                    <div>
                      <b>R<span
                        :class="{
                          'badge badge-danger rounded-circle small-margin': timeData.round > 1,
                        }"
                      >{{ timeData.round }}</span></b>
                    </div>
                  </div>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot v-if="!computedRealTimeData.loaders.length">
            <b-td colspan="100%">
              <h3>{{ $t('messages.no-record-found') }}</h3>
            </b-td>
          </b-tfoot>
        </b-table-simple>
      </b-card>
      <!-- summary by lesson -->
      <summary-lesson
        :real-time-data="realTimeData"
        :lesson-list="lessonList"
        @selectLesson="(value) => lessonId = value"
      />
    </b-overlay>
    <question-preview
      :show="showPreview"
      :data="problemBeingPreviewed"
      :show-edit-mode="false"
      @close="showPreview=false"
    />
  </div>
</template>
<script>
import {
  BCard, BOverlay, BButton, VBTooltip, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTd, BTh, BTfoot, BFormCheckbox, BProgress, BProgressBar,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import useApollo from '@/@core/graphql/useApollo'
import i18n from '@/libs/i18n'
import SummaryLesson from './components/SummaryLesson.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BButton,
    vSelect,
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BTfoot,
    BFormCheckbox,
    QuestionPreview,
    BProgress,
    BProgressBar,
    SummaryLesson,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'studentName',
          label: i18n.tc('user-types.student'),
        },
        {
          key: 'problems',
          label: i18n.tc('labels.problem'),
        },
        {
          key: 'status',
          label: i18n.tc('messages.tableHeader.status'),
        },
      ],
      realTimeData: [],
      isProcessing: false,
      classId: this.classroomId,
      lessonId: null,
      lessonList: [],
      timeOutAttribute: null,
      classRoomList: [],
      lessonStatusList: [
        // {
        //   value: 'completed',
        //   label: 'Completed',
        // },
        {
          value: 'passed',
          label: i18n.tc('individual-report-module.status.passed'),
        },
        {
          value: 'in_progress',
          label: i18n.tc('individual-report-module.status.in_progress'),
        },
        {
          value: 'failed',
          label: i18n.tc('individual-report-module.status.failed'),
        },
        {
          value: null,
          label: i18n.tc('actions.all'),
        },
      ],
      filters: {
        status: null,
        comparativeView: false,
      },
      self: getUserData(),
      showPreview: false,
      problemBeingPreviewed: {},
      engineShortCuts: {
        adaptivepathengine: 'ADP',
        linearstandaloneengine: 'L',
        carryforwardengine: 'CAF',
        doublefallbackengine: 'DDF',
      },
    }
  },
  computed: {
    onlineStudents() {
      return [...new Set(this.realTimeData.map(data => data.user_id))]
    },
    computedRealTimeData() {
      const realTimeData = this.realTimeData.filter(data => {
        if (this.lessonId === 'all') {
          return true
        }
        return data.lesson && (data.lesson.id === this.lessonId)
      })
        .filter(data => {
          if (this.filters.status) {
            return data.status === this.filters.status
          }
          return true
        })
      const problems = []
      const loadersData = []
      realTimeData.forEach(loader => {
        loadersData.push({
          dateTime: loader.created_at,
          studentName: `${loader.user.firstname} ${loader.user.lastname}`,
          status: loader.status,
          lessonName: loader.lesson.name,
          queues: loader.queues,
          userId: loader.user.id,
          pass: loader.pass,
          fail: loader.fail,
          load: loader.load,
          round: loader.round,
          classId: loader.group_id,
          engine: this.engineShortCuts[loader.engine?.toLowerCase()],
        })
        loader.queues.forEach(problem => {
          if (!problems.find(p => p === problem)) {
            problems.push(problem)
          }
        })
      })
      return {
        loaders: [...loadersData],
        problems,
      }
    },
    classroomId() {
      return this.classRoomId ? this.classRoomId : this.$route.params.classId
    },
  },
  created() {
    if (this.classId) {
      this.initializeMonitoring()
    }
    this.getClassRoom()
  },
  beforeDestroy() {
    clearTimeout(this.timeOutAttribute)
  },
  methods: {
    initializeMonitoring() {
      this.getRealTimeData()
      this.startTracking()
    },
    startTracking() {
      this.timeOutAttribute = setTimeout(() => {
        useJwt.getRealTimeMonitor(this.classId, {
          params: {
            page: this.activePage,
            isComparative: this.filters.comparativeView ? this.filters.comparativeView : null,
          },
        }).then(response => {
          this.setRealTimeData(response)
        }).finally(() => {
          this.startTracking()
        })
      }, 20000)
    },
    setRealTimeData(response) {
      const getStatus = status => {
        if (status === 'intervention' || status === 'assessment') return 'in_progress'
        return status
      }
      const loadersInfo = response.data?.data?.map(data => ({
        ...data,
        status: getStatus(data.status),
      }))
      this.isProcessing = false
      const lessons = [
        // {
        //   id: 'all',
        //   name: 'All Lessons',
        // },
      ]
      loadersInfo.forEach(loader => {
        if (loader.lesson && !lessons.find(lesson => lesson.id === loader.lesson?.id)) {
          lessons.push({
            id: loader.lesson?.id,
            name: loader.lesson?.name,
          })
        }
        this.lessonList = lessons
      })
      this.realTimeData = loadersInfo
      if (!this.lessonId || !this.lessonList.find(list => list.id === this.lessonId)) {
        this.lessonId = this.lessonList[0]?.id
      }
    },
    getRealTimeData(page = this.activePage) {
      this.isProcessing = true
      useJwt.getRealTimeMonitor(this.classId, {
        params: {
          page,
          isComparative: this.filters.comparativeView ? this.filters.comparativeView : null,
        },
      }).then(response => {
        this.setRealTimeData(response)
      })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    getVariantByStatus(status) {
      switch (status) {
        case 'in_progress':
        case 'intervention':
          return 'warning'
        case 'assessment':
          return 'primary'
        case 'passed':
          return 'success'
        default:
          return 'danger'
      }
    },
    getProblemStatColor(items, problemID) {
      let color = 'secondary'
      if (Object.values(items.pass).includes(problemID)) {
        color = 'success'
      }
      if (Object.values(items.fail).includes(problemID)) {
        color = 'danger'
      }
      return color
    },
    getStatusLabel(status) {
      return this.lessonStatusList.find(lesson => lesson.value === status)?.label || status
    },
    async getTemplates(id) {
      if (!id) return
      const data = await useJwt.getTemplate(id)
      // eslint-disable-next-line consistent-return
      return data.data.data
    },
    getClassRoom() {
      useApollo
        .searchRooms('', this.self.id, null)
        .then(res => {
          this.classRoomList = [
            {
              id: 'all',
              name: i18n.tc('all-classrooms'),
            },
            ...res.data.searchRooms,
          ]
          if (!this.classId) {
            this.classId = this.classRoomList[0]?.id
            this.initializeMonitoring()
          }
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    getPercentage(value, total) {
      return `${((value / total) * 100).toFixed(0)}%`
    },
    async getDataForPreview(problemId, userID, classId) {
      const studentTestStat = (await useJwt.getTestStatBasedOnClassRoom(classId, {
        params: {
          user_id: userID,
          problem_id: problemId,
        },
      })).data?.data

      useJwt.getProblem(problemId).then(async res => {
        const problemData = res.data.data
        const index = studentTestStat ? studentTestStat.lang_level - 1 : 0
        const problemStatementLength = problemData.problem_statements.length
        const statementAndFeedback = problemData.problem_statements[index] ? problemData.problem_statements[index] : problemData.problem_statements[problemStatementLength - 1]
        const feedBackTemplate = await this.getTemplates(statementAndFeedback.fb_template_id)
        const statementTemplate = await this.getTemplates(statementAndFeedback.st_template_id)
        const problemPreview = {
          theme: problemData.theme ? problemData.theme : 0,
          problemType: problemData.problem_type.name,
          feedback: {
            type: statementAndFeedback.fb_type,
            text: statementAndFeedback.feedback,
            image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
            audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
            template: feedBackTemplate,
          },
          statement: {
            type: statementAndFeedback.st_type,
            text: statementAndFeedback.statement,
            image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
            audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
            template: statementTemplate,
            video: problemData.video_time_stamp,
          },
          // eslint-disable-next-line eqeqeq
          questions: problemData.questions.filter(e => e.lang_level == (index + 1).toString()),
          answers: studentTestStat?.user_input || [],
          level: index + 1,
        }
        this.problemBeingPreviewed = problemPreview
        this.showPreview = true
      })
    },
  },
}
</script>
<style lang="scss">
#real-time-monitor {
  .fixed-left-column {
    position: sticky;
    left: 0;
    background: #F3F2F7 !important;
    z-index: 2;
  }
   .fixed-right-column {
    position: sticky;
    right: 0;
    background: #F3F2F7 !important;
    z-index: 2;
  }
  .is-playing {
    border-top: 3px solid #ff7f2f
  }
  /* Designing for scroll-bar */
  ::-webkit-scrollbar {
    width: 2px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: gainsboro;
      border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: rgb(115, 114, 114);
      border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }

  .small-margin {
    margin-left: 3px;
  }
  .table th {
    background-color: #343d55 !important;
  }
}

</style>
