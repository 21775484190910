<template>
  <div
    class="navbar-container d-flex content align-items-center dd"
    :class="( (isATeacher||isAStudent || isAParent ||isASchool) && isModernUI) ? 'col-md-12 col-xl-10 col-lg-10 ml-auto mr-auto' : ''"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- Logo -->
    <span
      v-if="(isAStudent || isATeacher || isAParent || isASchool) && isModernUI"
      class="brand-logo d-xs-none"
    >
      <a
        :href="frontUrl"
        target="_self"
      >
        <b-img
          :src="appNavLogo"
          alt="logo"
          size="sm"
          style="height: 40px;"
        />
      </a>
    </span>
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler
        v-if="!isAStudent"
      />
      <semi-light-toggler
        v-if="isAStudent"
      />

      <!-- Left Nav Items -->
      <b-navbar-nav
        v-if="(isAStudent || isATeacher || isAParent || isASchool) && isModernUI"
        class="nav ml-3 align-items-center text-left student-navbar"
      >
        <div
          v-for="item in getNavMenuItems"
          :key="item.route"
        >
          <b-nav-item-dropdown
            v-if="item.children"
            id="dropdown"
            right
            class="drop"
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-nav-item
                class="new-nav-items"
              >
                <feather-icon
                  :icon="item.icon"
                  size="20"
                  class="menu-icon"
                />
                <span class="menu-title mt-2">{{ item.title }}</span>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                  style="margin-left: 5px"
                />
              </b-nav-item>
            </template>

            <div
              v-for="child of item.children"
              :key="child.route"
              exact
            >
              <b-nav-item-dropdown
                v-if="child.children"
                id="childDropdown"
                class="m-md-2"
                dropright
              >
                <template #button-content>
                  <b-nav-item>
                    <feather-icon
                      :icon="child.icon"
                      size="20"
                      class="menu-icon"
                    />
                    <span class="menu-title mt-2">{{ child.title }}</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="20"
                      style="margin-left: 5px"
                    />
                  </b-nav-item>
                </template>
                <div
                  v-for="childItem of child.children"
                  :key="childItem.route"
                  exact
                >
                  <b-dropdown-item
                    :to="{name:childItem.route}"
                  >
                    <feather-icon
                      :icon="childItem.icon"
                      size="20"
                    />
                    {{ childItem.title }}
                  </b-dropdown-item>
                </div>
              </b-nav-item-dropdown>

              <b-dropdown-item
                v-else
                :to="{name:child.route}"
              >
                <feather-icon
                  :icon="child.icon"
                  size="20"
                />
                {{ child.title }}
              </b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item
            v-else
            class="new-nav-items"
            :to="{name:item.route}"
          >
            <feather-icon
              :icon="item.icon"
              size="20"
              class="menu-icon"
            />
            <span class="menu-title mt-2">{{ item.title }}</span>
          </b-nav-item>
        </div>
      </b-navbar-nav>
    <!-- /Left Nav Items -->
    </div>

    <!-- Right Nav Items -->
    <b-navbar-nav
      class="nav align-items-center ml-auto"
    >
      <locale />
      <chat-Toggler
        v-if="showNavbarChat"
      />
      <notes-drop-down v-if="isAStudent" />
      <notification-dropdown v-if="!hideNotification" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ firstName }}
            </p>
            <span class="user-status">{{ lastName }}</span>
          </div>
          <b-avatar
            v-if="avatar"
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />

          <avatar
            v-else
            :size="40"
            :username="`${firstName} ${lastName}`"
          />

        </template>

        <!-- Profile -->
        <b-dropdown-item
          v-if="!hideProfile"
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.profile") }}</span>
        </b-dropdown-item>

        <!-- Teacher Schedule -->
        <b-dropdown-item
          v-if="isASchool || isATeacher"
          :to="{ name: `${user.usertype}-schedular` }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CalendarIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.teacher-schedule") }}</span>
        </b-dropdown-item>

        <!-- Calendar -->
        <b-dropdown-item
          v-if="isAStudent"
          :to="{ name: 'student-calendar' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CalendarIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.calendar") }}</span>
        </b-dropdown-item>

        <!-- Diary -->
        <b-dropdown-item
          v-if="isAStudent"
          :to="{ name: 'my-attendance' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ClockIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.diary") }}</span>
        </b-dropdown-item>

        <!-- General Settings -->
        <b-dropdown-item
          v-if="!hideGeneralSetting"
          :to="{ name: 'general-settings' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="FileTextIcon"
            class="mr-50"
          />
          <span>{{ $t('navigation-menu.general-settings') }}</span>
        </b-dropdown-item>

        <!-- My Items -->
        <!-- <b-dropdown-item
          v-if="isAStudent"
          :to="{ name: 'my-items' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>{{ $t('navigation-menu.my-animated-items') }}</span>
        </b-dropdown-item> -->

        <!-- Change Password -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'change-password' }"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>{{ $t('navigation-menu.change-password') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: `app-downloads` }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="DownloadCloudIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.app-download") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <!-- <b-dropdown-item
          link-class="align-items-center pl-1"
          v-if="isAStudent || isATeacher ||isAParent || isASchool"
        >
          <ui-toggler
            class="d-xs-none d-lg-block"
          />
        </b-dropdown-item> -->

        <!-- Goto Workzone -->
        <b-dropdown-item
          v-if="!cannotViewWorkZoneLink && false"
          link-class="d-flex align-items-center"
          @click="goToWorkzone"
        >
          <feather-icon
            size="16"
            icon="ShareIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.go-to-workzone") }}</span>
        </b-dropdown-item>

        <!-- Log Out -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t("navigation-menu.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <float-call-bar
      v-if="showFloatCall"
      ref="showFloatBar"
      :caller-name="callerName"
    />

    <!-- Sound effect when a sticker is received -->
    <audio
      ref="stickerAudio"
      src="/sfx/sticker-send.mp3"
    />
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BLink, BNavbarNav, BNavItemDropdown, BNavItem, BDropdownItem, BDropdownDivider, BAvatar, BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SemiLightToggler from '@/@core/layouts/components/app-navbar/components/SemiLightToggler.vue'
import ChatToggler from '@core/layouts/components/app-navbar/components/ChatToggler.vue'
// import UiToggler from '@core/layouts/components/app-navbar/components/UiToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT, USER_TYPE_TEACHER, USER_TYPE_SCHOOL, USER_TYPE_PARENT,
} from '@/const/userType'
import NotesDropDown from '@views/student/note/NotesDropDown.vue'
import navMenuItems from '@/navigation/vertical'
import {
  VaniEvent,
  MessagePayload,
} from 'vani-meeting-client'
// import {
//   VaniEvent,
// } from 'vani-meeting-client'
// import VideoHandler from '@/views/common/chat/VideoHandler'
// import router from '@/router'
// import ChatHandler from '../../views/common/chat/ChatHandler'
import FloatCallBar from '@views/common/chat/call/FloatCallBar.vue'
import ChatHandler from '@views/common/chat/ChatHandler'
import VideoHandler from '@views/common/chat/VideoHandler'
import { mapGetters } from 'vuex'
import { isVisible } from '@/utils/visibilitySettings'
import GEC_EVENT_BUS from '@/utils/eventBus'

const { newAppLogo } = $themeConfig.app
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BNavItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    ChatToggler,
    // Navbar Components
    DarkToggler,
    SemiLightToggler,
    // UiToggler,
    Locale,
    NotificationDropdown,
    NotesDropDown,
    FloatCallBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isATeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
      isAParent: getUserData() && getUserData().usertype === USER_TYPE_PARENT,
      isASchool: getUserData() && getUserData().usertype === USER_TYPE_SCHOOL,
      firstName: '',
      lastName: '',
      avatar: '',
      user: getUserData(),
      incomingCallMessagePayload: undefined,
      logo: newAppLogo,
      navMenuItems,
      showFloatCall: false,
      callerName: '',
      isDropdown2Visible: false,
      authAuth: this.AUTH_USER(),
      canViewCalibration: false,
      hideEngagement: false,
      hideMapper: false,
      hideUserMenu: false,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    cannotViewWorkZoneLink() {
      return this.authAuth.usertype === 'student' || this.authAuth.usertype === 'parent'
    },
    frontUrl() {
      return this.$store.state.app.partnerCompanyInfo?.cms_domain || window.location.origin.replace('edu.', '')
    },
    getNavMenuItems() {
      const formData = {
        data: '{}',

      }
      // check district level setting
      const distEngagementMenu = this.getDistrictSettings?.find(metaValue => metaValue.key === 'engagement_menu') ?? '0'
      // check school level setting
      const engagementMenu = this.getSchoolSettings?.find(metaValue => metaValue.key === 'engagement_menu') ?? '0'

      if (distEngagementMenu?.value === '1' || engagementMenu?.value === '1') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hideEngagement = true
      }

      // check district level setting
      const distMapperMenu = this.getDistrictSettings?.find(metaValue => metaValue.key === 'nav_mapper') ?? '0'
      // check school level setting
      const schoolMapperMenu = this.getSchoolSettings?.find(metaValue => metaValue.key === 'nav_mapper') ?? '0'

      if (distMapperMenu?.value === '1' || schoolMapperMenu?.value === '1') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hideMapper = true
      }

      // check district level setting
      const distUserMenu = this.getDistrictSettings?.find(metaValue => metaValue.key === 'user_menu') ?? '0'
      // check school level setting
      const schoolUserMenu = this.getSchoolSettings?.find(metaValue => metaValue.key === 'user_menu') ?? '0'

      if (distUserMenu?.value === '1' || schoolUserMenu?.value === '1') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hideUserMenu = true
      }

      useJwt.getCalibrationPermission(formData)
        .then(response => {
          this.canViewCalibration = response.data.data.permissions.find(permission => permission.slug === 'settings.calibration')?.allow
        })
      const userData = getUserData()
      if (userData) {
        let menuItem = this.navMenuItems[getUserData().usertype] || []
        menuItem = menuItem.map(item => ({
          ...item,
          children: item.children?.filter(child => {
            if (child.route === 'teacher-report-calibration') {
              return !!this.canViewCalibration
            }
            return true
          }),
        }))

        if (this.hideEngagement) {
        // If hideEngagement is true, remove the 'Engagements' item
          menuItem = menuItem.filter(item => item.type !== 'engagements')
        }

        if (this.hideUserMenu && this.isAStudent) {
          menuItem = menuItem.filter(item => item.type !== 'users')
        }
        if (this.hideMapper) {
          // If hideMapper is true, filter out children with title 'Mapper'
          menuItem = menuItem.map(item => ({
            ...item,
            children: item.children?.filter(child => child.type !== 'Mapper'),
          }))
        }
        return menuItem
      }
      return []
    },
    appNavLogo() {
      return this.$store.state.app.partnerCompanyInfo?.nav_icon || this.$store.state.app.partnerCompanyInfo?.logo || '/img/new-logo.5e5f5955.svg'
    },
    ui() {
      return this.$store.state.appConfig.layout.ui
    },

    isModernUI() {
      return this.ui === 'modern'
    },

    token() {
      return localStorage.getItem('accessToken')
    },
    showNavbarChat() {
      const distNavbarChat = this.getDistrictSettings?.find(metaValue => metaValue.key === 'nav_chat')?.value ?? '0'
      // check school level setting
      const schoolNavbarChat = this.getSchoolSettings?.find(metaValue => metaValue.key === 'nav_chat')?.value ?? '0'
      let navbarChatValue = false
      if (distNavbarChat !== '1' && schoolNavbarChat !== '1') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        navbarChatValue = true
      }
      return navbarChatValue
    },
    hideProfile() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('nav_profile', schoolSetting, distSetting)
    },
    hideGeneralSetting() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('nav_general_setting', schoolSetting, distSetting)
    },
    hideNotification() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('nav_notification', schoolSetting, distSetting)
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.firstName = user ? user.firstname : ''
    this.lastName = user ? user.lastname : ''
    this.avatar = user ? user.avatar : ''
    this.$root.$on('userUpdate', () => {
      this.firstName = JSON.parse(localStorage.getItem('userData')).firstname
      this.lastName = JSON.parse(localStorage.getItem('userData')).lastname
      this.avatar = JSON.parse(localStorage.getItem('userData')).avatar
    })
  },
  mounted() {
    GEC_EVENT_BUS.$on('update-avatar', avatar => {
      this.avatar = avatar
    })
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'childDropdown') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'childDropdown') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
    ChatHandler.getInstance().eventEmitter.on('ShowAudioCall', this.showAudioCall)
    ChatHandler.getInstance().eventEmitter.on('AudioVideoCallAccepted', () => {
      this.$nextTick(() => {
        if (this.$refs.showFloatBar) {
          this.$refs.showFloatBar.onMeetingStartTime()
        }
      })
    })
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnNewChatMessageReceived, messagePayload => {
      if (messagePayload.extraData && messagePayload.extraData.isBusy) {
        this.showError('User is busy')
      }
      if (messagePayload.type === 'VideoCallEnded' || messagePayload.type === 'VideoCallDecline') {
        ChatHandler.getInstance().eventEmitter.emit('HideAudioCall', {})
        ChatHandler.getInstance().eventEmitter.emit('HideCallLayout', {})
        VideoHandler.getInstance().cleanUp()
      }
    })

    ChatHandler.getInstance().eventEmitter.on('UserIsVacentOnCall', incomingCallMessagePayload => {
      const { userId } = incomingCallMessagePayload.sender
      const chatPayload = new MessagePayload('VideoCallDecline', userId)
      chatPayload.type = 'VideoCallDecline'
      chatPayload.extraData = { ...incomingCallMessagePayload.extraData || {}, isBusy: true }
      ChatHandler.getInstance().sendMessage(chatPayload)
      // cancel call for user in other tabs
      useJwt.sendCancelCallEvent({
        receiver: this.user.id,
      }).then(() => {
        ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
        ChatHandler.getInstance().eventEmitter.emit('HideCallLayout', {})
      })
    })

    window.Echo.channel(`call-cancel-busy${this.user.id}`)
      .listen('CallCancelBusyEvent', () => {
        ChatHandler.getInstance().eventEmitter.emit('HideVideoLayout', {})
        ChatHandler.getInstance().eventEmitter.emit('HideCallLayout', {})
      })

    window.Echo.channel('sticker').listen('StickerAlertEvent', res => {
      if (parseInt(res.receiver, 10) === JSON.parse(localStorage.getItem('userData')).id && parseInt(res.sender, 10) !== JSON.parse(localStorage.getItem('userData')).id) {
        this.$swal({
          titleText: `${res.sender.firstname} ${res.sender.lastname} sent you an sticker!`,
          imageUrl: res.sticker,
          imageHeight: 90,
          imageWidth: 'auto',
          showConfirmButton: false,
          position: 'top',
          timer: 2000,
        })
        this.$store.dispatch('appConfig/setRefreshRewardStats', true)
        this.$refs.stickerAudio.play()
      }
    })
  },
  methods: {
    eventHandler() {
    },
    logout() {
      useJwt
        .logout({
          // mobileFcmToken is used when the app is logged in from the mobile app with Firebase
          mobileFcmToken: localStorage.getItem('mobileFcmToken') || null,
        }).finally(() => {
          this.emptyLocalStorageAndRedirectToLogin()
        })
    },
    showAudioCall() {
      if (window.incomingCallMessagePayload) {
        this.callerName = window.incomingCallMessagePayload?.extraData.callerName
      }
      this.showFloatCall = true
      ChatHandler.getInstance().eventEmitter.on('HideAudioCall', this.hideAudioCall)
    },
    hideAudioCall() {
      ChatHandler.getInstance().eventEmitter.off('HideAudioCall', this.hideAudioCall)
      this.showFloatCall = false
    },
    emptyLocalStorageAndRedirectToLogin() {
      localStorage.clear()
      this.$router.push({ name: 'login' })
      this.$store.dispatch('authEvents/onUserLogout')
      this.$store.commit('appConfig/UPDATE_SELECTED_CHARACTER', null)
      location.reload()
    },

    goToWorkzone() {
      window.open(`${process.env.VUE_APP_WORKZONE_URL}/gecBearerToken/callback?code=${this.token}`)
    },
  },
}
</script>
<style>
.dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
</style>

<style scoped>
  .menu-icon {
    margin-bottom: 2px;
  }

  .menu-title {
    margin-left: 4px;
  }

  .drop .nav-link {
    padding: 0 !important;
  }
  .dark-layout .router-link-exact-active,
  .dark-layout .router-link-active {
    color: #fff !important;
  }
  .dropdown-user {
    z-index: 10000;
  }
</style>
