<template>
  <div>
    <template v-if="isDashboardLoaded">
      <div v-if="dashboardSetting?.value === 'slp'">
        <LessonDashBoard />
      </div>
      <div v-else>
        <DefaultDashBoard />
      </div>
    </template>

  </div>
</template>
<script setup>
import {
  ref,
  // watchEffect,
  watch,
  computed,
} from 'vue'
import store from '@/store'
import DefaultDashBoard from './default/index.vue'
import LessonDashBoard from './lesson-dashboard/index.vue'

const isDashboardLoaded = ref(false)
// const selectedDashboard = ref(null)
// const schoolInfo = JSON.parse(localStorage.getItem('schoolInfo'))
// const districtInfo = JSON.parse(localStorage.getItem('districtInfo'))
const schoolSetting = computed(() => store.state.appConfig?.schoolInfo?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard'))
const distSetting = computed(() => store.state.appConfig?.districtInfo?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard'))

const dashboardSetting = computed(() => (schoolSetting.value || distSetting.value))
// watchEffect(() => {
//   const schoolSetting = store.state.appConfig?.schoolInfo
//   const distSetting = store.state.appConfig?.districtInfo
//   let dashboard = schoolSetting?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard')
//   if (!dashboard && distSetting) {
//     dashboard = distSetting?.user_meta?.find(metaValue => metaValue.key === 'student_dashboard')
//   }

//   if (dashboard?.value === 'slp') {
//     selectedDashboard.value = 'slp'
//   } else {
//     selectedDashboard.value = 'default'
//   }
// })
watch(
  () => ({
    schoolInfo: store.state.appConfig?.schoolInfo,
    districtInfo: store.state.appConfig?.districtInfo,
  }),
  ({ schoolInfo, districtInfo }) => {
    if (schoolInfo || districtInfo) {
      isDashboardLoaded.value = true
    }
  },
  { immediate: true, deep: true }, // Ensure it runs on initialization and watches deeply
)
</script>
