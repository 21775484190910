<template>
  <b-tabs
    pills
    class="academic-group-report"
  >
    <b-tab
      v-for="report of filteredReportTypes"
      :key="report.value"
      :title="report.text"
      lazy
      @click="selectedReport = report.value"
    >
      <component
        :is="report.component"
        :class-room="classRoom"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  VBTooltip, BTabs, BTab,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import ProblemSolvingSkill from '../GroupReport/ProblemSolvingSkill.vue'
import AcademicReport from '../GroupReport/AcademicReport.vue'
import ScoreReport from '../GroupReport/ScoreReport.vue'

export default {
  components: {
    AcademicReport,
    ProblemSolvingSkill,
    ScoreReport,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
    academicReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedReport: 'skill-report',
      reportType: [
        {
          text: i18n.tc('setting-module.problem-solving-skills-title'),
          value: 'problem-solving-skill',
          component: 'ProblemSolvingSkill',
        },
        {
          text: i18n.tc('setting-module.proficiency-on-lessons-title'),
          value: 'lesson-solving-skill',
          component: 'ScoreReport',
        },
        {
          text: i18n.tc('setting-module.lesson-completion-rate-title'),
          value: 'academic-report',
          component: 'AcademicReport',
        },
      ],
    }
  },
  computed: {
    filteredReportTypes() {
      let filteredTypes = this.reportType.slice() // Make a copy of the report types
      if (this.academicReport.problemSolving === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'problem-solving-skill')
      }
      if (this.academicReport.proficiencyOnLessons === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'lesson-solving-skill')
      }
      if (this.academicReport.lessonCompletion === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'academic-report')
      }
      return filteredTypes
    },
  },
}
</script>
<style lang="scss">
.academic-group-report {
  ul.nav {
    .nav-item {
      a {
        border-radius: 23px;
        padding: 7px 10px;
      }
    }
  }
}
</style>
