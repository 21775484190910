<template>
  <div>
    <h5>
      {{ $t('courses-module.lang-level-indicator') }}
    </h5>
    <b-row>
      <b-col
        v-for="v,key of value"
        :key="key"
      >
        <b-form-group :label="key">
          <b-form-input
            v-model="value[key].label"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
