<template>
  <div>
    <h3>{{ $t('report.hours-tracked') }}</h3>
    <BOverlay :show="isProcessing">
      <BButton class="btn-icon"
               variant="flat-success"
               @click="subtractWeek"
      >
        <feather-icon icon="ChevronsLeftIcon"
                      size="20"
        />
      </BButton>
      {{ weekStartEnd.format('MMM Do YYYY') }} - {{ endOfWeek.format('MMM Do YYYY') }}
      <BButton v-if="endOfWeek.isBefore()"
               class="btn-icon"
               variant="flat-success"
               @click="addWeek"
      >
        <feather-icon icon="ChevronsRightIcon"
                      size="20"
        />
      </BButton>
      <div>
        <bar-chart
          :series="seriesData"
          :categories="category.map(cat=> cat.text)"
        />
      </div>
    </BOverlay>
  </div>
</template>
<script>
import BarChart from '@/views/common/Charts/HoursTracked.vue'
import useJwt from '@/auth/jwt/useJwt'
import { BButton, BOverlay } from 'bootstrap-vue'

const moment = require('moment')

export default {
  components: {
    BarChart,
    BButton,
    BOverlay,
  },
  props: {
    userId: {
      type: [Number, Object],
      default: () => null,
    },
  },
  data() {
    return {
      attendance: [],
      seriesData: [],
      currentDate: moment(),
      isProcessing: false,
    }
  },
  computed: {
    weekStartEnd() {
      return this.currentDate.clone().startOf('week')
    },
    endOfWeek() {
      return this.currentDate.clone().endOf('week')
    },
    category() {
      const startOfMonth = this.weekStartEnd
      const { endOfWeek } = this
      const category = []
      for (
        const m = moment(startOfMonth);
        m.isBefore(endOfWeek);
        m.add(1, 'days')
      ) {
        category.push({
          day: m.format('YYYY-MM-DD'),
          text: m.format('MMM Do'),
        })
      }
      return category
    },
  },
  mounted() {
    this.getUserTimeTracked()
  },
  methods: {
    getUserTimeTracked() {
      this.isProcessing = true
      this.getSeries(this.attendance)
      useJwt.getUserTimeTracked(this.userId, {
        params: {
          start_date: this.weekStartEnd.format('YYYY-MM-DD'),
          end_date: this.endOfWeek.format('YYYY-MM-DD'),
        },
      }).then(response => {
        this.attendance = response.data?.time_spent
        this.getSeries(response.data?.time_spent)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    addWeek() {
      this.currentDate = this.currentDate.clone().add('1', 'week')
      // this.$nextTick(() => {
      this.getUserTimeTracked()
      // })
    },
    subtractWeek() {
      this.currentDate = this.currentDate.clone().subtract('1', 'week')
      // this.$nextTick(() => {
      this.getUserTimeTracked()
      // })
    },
    getSeries(attendance) {
      const seriesData = []
      this.category.forEach(category => {
        const timeWorked = attendance.find(date => date.work_day === category.day)?.time_spent ?? 0
        seriesData.push(
          (timeWorked * 0.000277778).toFixed(2), // seconds to hours
        )
      })
      this.seriesData = [
        { data: seriesData, name: 'Attendance' },
      ]
    },
  },
}
</script>
