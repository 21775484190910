var render = function render(){var _vm=this,_c=_vm._self._c;return _c('body',{class:_vm.variant},[_c('div',{staticClass:"container-xl p-0"},[_c('header',{staticClass:"text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v(_vm._s(_vm.lesson.page.title)+" "),_c('div',{staticClass:"float-right"},[_vm._t("ebook-selector")],2)])])])]),_c('div',{staticClass:"row no-gutters position-relative"},[_c('div',{staticClass:"top-img"},[_c('img',{staticClass:"img-fluid border-radius-img content-left-top img-thumbnail shadow-sm",staticStyle:{"float":"right","margin-right":"-200px","z-index":"20","width":"300px"},attrs:{"src":_vm.lesson.top_image
              ? _vm.lesson.top_image
              : 'https://via.placeholder.com/400',"alt":""}})]),_c('div',{staticClass:"col-lg-6 bg-light ebook-text col-print-6"},[_c('div',{staticClass:"content-left left-1"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.wordnetWords)},on:{"click":_vm.handleClick}})])]),_c('div',{staticClass:"col-lg-6 col-print-6",staticStyle:{"z-index":"-1"}},[_c('div',{staticClass:"bg-image left-2",style:(`background-image: url(${
            _vm.lesson.vocab_image
              ? _vm.lesson.vocab_image
              : 'https://via.placeholder.com/400'
          });`)},[_c('div',{staticClass:"list-style-wrapper"},[_c('div',{staticClass:"list-style-wrapper"},[_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"row"},_vm._l((_vm.lesson.vocabulary),function(vocab,index){return _c('div',{key:index,staticClass:"col-md-6",style:(`margin-top:${index >= 2 ? '60px' : '0'}`)},[_c('div',{staticClass:"list-style"},[_c('div',{staticClass:"list-style-top"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(vocab.word_in_eng)+" ")]),_c('p',[_vm._v(" "+_vm._s(vocab.word_in_jap)+" ")])]),_c('div',{staticClass:"list-style-bottom"},[_c('p',[_vm._v(_vm._s(vocab.synonym_1)+" "),_c('br'),_vm._v(_vm._s(vocab.synonym_2))])])])])}),0)])])]),_vm._m(0)])]),_c('div',{staticClass:"row no-gutters position-relative"},[_c('div',{staticClass:"col-lg-6 bg-light col-print-6"},[_c('div',{staticClass:"question-box ml-0 mr-0"},[_vm._m(1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.lesson.questions)}})]),_c('div',{staticClass:"question-ans mb-0"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.lesson.extra_questions)}}),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr'),_c('hr')])]),_c('div',{staticClass:"col-lg-6 bg-light col-print-6"},[_c('div',{staticClass:"bg-image p-1 question-img",style:(`background-image: url(${
            _vm.lesson.question_bg_image
              ? _vm.lesson.question_bg_image
              : 'https://via.placeholder.com/400'
          });`)},[_c('img',{staticClass:"img-fluid border-radius-img",attrs:{"src":_vm.lesson.question_radius_image
                ? _vm.lesson.question_radius_image
                : 'https://via.placeholder.com/400',"alt":""}}),_c('p',{staticClass:"transparent-paragraph",domProps:{"innerHTML":_vm._s(_vm.lesson.bottom_question)}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('p',[_vm._v("LEGEND")]),_c('ul',{staticClass:"list-inline"},[_c('li',{staticClass:"list-inline-item"},[_c('span',{staticClass:"legend-word"}),_vm._v("Word ")]),_c('li',{staticClass:"list-inline-item"},[_c('span',{staticClass:"legend-synonym"}),_vm._v("Synonym ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("REVIEW QUESTIONS:")]),_c('br')])
}]

export { render, staticRenderFns }