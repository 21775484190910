<template>
  <div>
    <b-table-simple bordered responsive>
      <b-thead>
        <b-tr>
          <b-th>
            {{ title }}
          </b-th>
          <b-th>
            {{ strongest }}
          </b-th>
          <b-th>
            {{ weakest }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="student,skill of summary"
          :key="skill"
        >
          <b-td>
            {{ capitalizeFirstLetter(student.title) }}
          </b-td>
          <b-td>
            {{ joinArrayWith(student.strongest, 'fullName') }}
          </b-td>
          <b-td>
            {{ joinArrayWith(student.weakest, 'fullName') }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- <b-row> -->
    <!-- <b-col cols="6">
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th class="bg-success text-white">
                Strongest
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="student,index of summary.strongest"
              :key="index"
            >
              <b-td>
                {{ student.fullName }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col> -->
    <!-- <b-col cols="6">
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th class="bg-danger text-white">
                Weakest
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="student,index of summary.weakest"
              :key="index"
            >
              <b-td>
                {{ student.fullName }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col> -->
    <!-- </b-row> -->
  </div>
</template>
<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
  },
  props: {
    summary: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    strongest: {
      type: String,
      default: i18n.tc('student-report-module.report-pages.strongest'),
    },
    weakest: {
      type: String,
      default: i18n.tc('student-report-module.report-pages.weakest'),
    },
  },
}
</script>
