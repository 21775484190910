<template>
  <validation-observer
    ref="classTestForm"
  >
    <b-row>
      <b-col v-if="selectedTgpProblem.length"
             md="3"
      >
        <fieldset class="mt-2 border  border-dark p-2 rounded-top">
          <legend class="w-auto small mx-2">
            &nbsp;{{ $t('template-module.selected-tgp-problems') }}   &nbsp;
          </legend>
          <b-list-group>
            <b-list-group-item v-for="item of selectedTgpProblem"
                               :key="item.code"
                               class="d-flex justify-content-between align-items-center"
            >
              <span>{{ item.label }}</span>
              <feather-icon icon="XIcon"
                            class="text-danger"
                            role="button"
                            @click="$emit('selectedTgp', item.code)"
              />
            </b-list-group-item>
          </b-list-group>
        </fieldset>
      </b-col>
      <b-col>
        <fieldset class="mt-2 border  border-dark p-2 rounded-top">
          <legend class="w-auto small mx-2">
            &nbsp;{{ $t('template-module.test-form') }}   &nbsp;
          </legend>
          <b-row style="margin-top: -10px">
            <b-col md="4">
              <validation-provider
                v-slot="{ errors }"
                name="Test Name"
                rules="required"
              >
                <b-form-group :label="$t('class-test.name')">
                  <b-form-input
                    v-model="testInfo.name"
                    :placeholder="$t('class-test.name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                v-slot="{ errors }"
                name="Engine Type"
                rules="required"
              >
                <b-form-group>
                  <label style="font-size:14px">
                    {{ $t('class-module.engine') }}

                    <feather-icon
                      size="16"
                      icon="InfoIcon"
                      style="cursor: pointer"
                      @click="showEngineModal = true"
                    />
                  </label>
                  <b-form-select
                    v-model="testInfo.engine"
                    label="text"
                    :options="defaultEngineOptions"
                    placeholder="Select Engine"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('class-module.test-time')">
                <cleave
                  v-model="testInfo.testTime"
                  class="form-control"
                  :raw="false"
                  :options="{
                    delimiter: ':',
                    blocks: [2, 2],
                  }"
                  placeholder="mm:ss"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="4">
                  <b-form-group :label="$t('class-test.all-students')">
                    <b-form-checkbox
                      v-model="testInfo.allStudents"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <h5>
                      {{ $t('template-module.has-minimal-view') }}
                      <feather-icon
                        v-b-tooltip.hover="$t('template-module.minimal-view-info')"
                        size="20"
                        icon="InfoIcon"
                      />
                    </h5>
                    <b-form-checkbox
                      v-model="testInfo.hasMinimalView"
                      switch
                      default="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="!testInfo.allStudents"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Students"
                rules="required"
              >
                <b-form-group :label="$t('class-test.select-student')">
                  <v-select
                    v-model="testInfo.students"
                    multiple
                    :reduce="student => student.id"
                    label="fullname"
                    :placeholder="$t('class-test.select-student')"
                    :options="students"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="isSuperAdmin">
              <b-button variant="outline-secondary"
                        @click="showTemplatePicker = true"
              >
                {{ $t('class-test.template-picker') }}
              </b-button>
            </b-col>
          </b-row>
        </fieldset>
      </b-col>
      <TemplatePicker v-if="showTemplatePicker"
                      :event-id="eventId"
                      @close="showTemplatePicker = false"
                      @onChange="handleTemplateUpdate"
      />
    </b-row>
    <engine-info-modal
      :show-engine-modal="showEngineModal"
      @close="showEngineModal = false"
    />
  </validation-observer>
</template>
<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BFormSelect, BFormCheckbox, VBTooltip, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'
import Cleave from 'vue-cleave-component'
import { USER_TYPE_SUPER } from '@/const/userType'
import EngineInfoModal from '@/views/common/components/EngineInfoModal.vue'
import TemplatePicker from './TemplatePicker.vue'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    vSelect,
    BRow,
    BCol,
    BFormSelect,
    BFormCheckbox,
    ValidationObserver,
    BButton,
    TemplatePicker,
    Cleave,
    EngineInfoModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  inject: {
    injectedClassRoomId: {
      default: null,
    },
  },
  props: {
    eventId: {
      type: [Number, String],
      default: 0,
    },
    lessons: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      required: true,
    },
    hasMinimalView: {
      type: Boolean,
      default: true,
    },
    selectedTgpProblem: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      required,
      defaultEngineOptions: [
        { text: 'SDP', value: 'adp' },
        { text: 'DDF', value: 'ddf' },
        { text: 'CAF', value: 'caf' },
        { text: 'LINEAR', value: 'linear' },
      ],
      students: [],
      showTemplatePicker: false,
      testInfo: {
        name: this.value?.name,
        engine: this.value?.engine || 'ddf',
        allStudents: !!this.value?.all_students_included,
        students: this.value?.students || [],
        id: this.value?.id,
        hasMinimalView: this.value?.rules?.has_minimal_view ?? true,
        testTime: this.value?.rules?.test_time || 0,
        templateInfo: [],
      },
      showEngineModal: false,
    }
  },
  computed: {
    isSuperAdmin() {
      return this.AUTH_USER().usertype === USER_TYPE_SUPER
    },
  },
  watch: {
    testInfo: {
      handler() {
        this.$emit('onTestInfoChange', this.testInfo)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getStudents()
  },
  methods: {
    handleTemplateUpdate(value) {
      this.testInfo.templateInfo = value
    },
    getStudents() {
      useApollo.getClassRoomStudents(this.injectedClassRoomId || this.$route.params.classId).then(response => {
        this.students = response.data?.room?.students.map(student => ({
          ...student,
          id: Number(student.id),
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>
<style scoped>
.border-dark {
  border-color: #ccc !important;
}
</style>
