<template>
  <b-overlay :show="isProcessing">
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col>
          <b-row>
            <b-col
              md="4"
            >
              <b-form-group :label="$t('image-library-module.upload-csv-file')">
                <b-form-file
                  v-model="wordCsv"
                  :browse-text="$t('browseButton')"
                  :placeholder="$t('issue-report-module.choose-a-file')"
                  accept=".csv"
                  @input="uploadVocabCsv"
                />
                <span
                  class="cursor-pointer"
                  style="margin-top:5px"
                  @click="downloadSample"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    style="margin-right:5px"
                  />
                  <span class="text-muted">{{ $t('curriculum-pages-module.download-sample') }}</span>
                </span>
              </b-form-group>

            </b-col>
            <b-col
              md="4"
            >
              <b-form-group :label="$t('search-vocab')">
                <b-form-input
                  v-model="searchWord"
                  :placeholder="$t('search-vocab')"
                  @change="searchWordFromIL"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group :label="$t('fetch-lesson-words')">
                <b-form-checkbox
                  v-model="fetchFromLesson"
                  switch
                  :value="true"
                  :unchecked-value="false"
                  :label="$t('fetch-from-lesson')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="fetchFromLesson"
               class="p-2 border mb-2"
          >
            <GradeCoursePicker :class-id="classId"
                               :only-cb="true"
                               @lessonChange="fetchFromLessonWords"
            />
          </div>
          <b-row>
            <b-col
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Students"
                rules="required"
              >
                <b-form-group :label="$t('class-test.select-student')">
                  <b-input-group>
                    <v-select
                      v-model="studentsForWords"
                      multiple
                      :reduce="student => student.id"
                      label="fullname"
                      style="width: 92%;"
                      :placeholder="$t('class-test.select-student')"
                      :options="ARRAY_UNIQUE_BY_KEY(studentsList, 'id')"
                    />
                    <b-input-group-append>
                      <b-form-checkbox
                        v-model="selectAllStudents"
                        v-b-tooltip="selectAllStudents ? $t('actions.unselect-all') : $t('actions.select-all')"
                        style="margin-top: 8px; margin-left:5px"
                        @change="handleSelectAllStudents"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group :label="$t('class-test.select-answer-delivery-option')">
                <v-select
                  v-model="answerDeliveryOption"
                  :reduce="ansDeliveryOption => ansDeliveryOption.value"
                  :placeholder="$t('class-test.select-answer-delivery-option')"
                  :options="ansDeliveryOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Start Date -->
              <validation-provider
                v-slot="validationContext"
                :name="$t('class-module.start-date')"
                rules="required"
              >

                <b-form-group
                  :label="$t('class-module.start-date')"
                  label-for="start-date"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    v-model="wordStartDate"
                    class="form-control"
                    :min="new Date()"
                    @input="vocabStartDateChanged"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- End Date -->
              <validation-provider
                v-slot="validationContext"
                :name="$t('class-module.end-date')"
                rules="required"
              >

                <b-form-group
                  :label="$t('class-module.end-date')"
                  label-for="end-date"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="wordEndDate"
                    :min="wordStartDate"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <template
              v-if="isSearching"
            >
              <b-col md="12"
                     class="text-center"
              >
                <b-spinner
                  class="mr-1"
                  variant="primary"
                />
              </b-col>
            </template>
            <template v-else>
              <template
                v-if="searchedWords.length > 0"
              >
                <b-col
                  v-for="word, index in searchedWords"
                  :key="index"
                  md="4"
                >

                  <div class="word-options border rounded shadow-sm">
                    <div class="border-bottom synonym-word">
                      <b-form-checkbox
                        :id="`word-${index}`"
                        v-model="selectedWords"
                        :value="word"
                        class="word-checkbox"
                      >
                        {{ word.vocab_word }}
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="word.synonyms && word.synonyms.length"
                      class="item-wrapper"
                    >
                      <p class="item-price mb-0">
                        {{ $t('image-library-module.synonyms') }}:
                      </p>
                      <span
                        class="w-100"
                        style="display:inline-grid"
                      >
                        <span v-for="synonym,sIndex in word.synonyms"
                              :key="synonym.name+sIndex"
                              class="d-flex justify-content-between word-synonym"
                        >
                          {{ synonym.text }}
                          <span v-if="synonym.audio"
                                class="float-right"
                          >
                            <feather-icon
                              icon="PlayIcon"
                              class="cursor-pointer play-button"
                              @click="playAudio(synonym.audio)"
                            />
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </b-col>
              </template>
              <template v-else-if="filteredSearchedWords?.length != 0">
                <b-col
                  class="mb-1"
                  md="12"
                  sm="12"
                />
                <b-tabs pills>
                  <b-tab title="Select Difficulty Level:"
                         disabled
                  />
                  <b-tab v-for="filteredSearchedWord,index in filteredSearchedWords"
                         :key="index"
                         :title="filteredSearchedWord.ver"
                  >
                    <b-row>
                      <b-col
                        v-for="word, windex in filteredSearchedWord.words"
                        :key="windex"
                        md="4"
                        class="mb-1"
                      >

                        <div class="word-options border rounded shadow-sm">
                          <div class="border-bottom synonym-word">
                            <b-form-checkbox
                              :id="`word-${index}-${windex}`"
                              v-model="selectedWords"
                              :value="word"
                              class="word-checkbox"
                            >
                              {{ word.word }}
                            </b-form-checkbox>
                          </div>
                          <div
                            v-if="word.synonyms && word.synonyms.length"
                            class="item-wrapper"
                          >
                            <p class="item-price mb-0">
                              {{ $t('image-library-module.synonyms') }}:
                            </p>
                            <span
                              class="w-100"
                              style="display:inline-grid"
                            >
                              <span v-for="synonym,sIndex in word.synonyms"
                                    :key="synonym.name+sIndex"
                                    class="d-flex justify-content-between word-synonym"
                              >
                                {{ synonym.text }}
                                <span v-if="synonym.audio"
                                      class="float-right"
                                >
                                  <feather-icon
                                    icon="PlayIcon"
                                    class="cursor-pointer play-button"
                                    @click="playAudio(synonym.audio)"
                                  />
                                </span>
                              </span>
                            </span>
                            <!-- {{ word.synonyms?.map(item => item.text).join(', ') }} -->

                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </template>
              <template
                v-else
              >
                <b-col md="12">
                  <p class="text-center">
                    {{ $t('messages.no-data-available') }}
                  </p>
                </b-col>
              </template>
            </template>
          </b-row>
        </b-col>
        <b-col
          md="5"
        >
          <b-row>
            <template v-if="selectedWords.length >0">
              <b-col
                v-for="word,windex in selectedWords"
                :key="windex"
                class="mb-1"
                md="6"
                sm="12"
              >
                <div class="border-bottom">
                  {{ word.vocab_word ||word.word }}
                  <span
                    v-b-tooltip="`Remove word`"
                    class="float-right text-danger"
                    style="cursor: pointer;"
                    @click="removeWord(windex)"
                  >
                    X
                  </span>
                </div>
                <div
                  v-if="word.synonyms && word.synonyms.length"
                  class="item-wrapper"
                >
                  <p class="item-price">
                    {{ $t('image-library-module.synonyms') }}:  {{ word.synonyms?.map(item => item.text).toString() }}
                  </p>
                </div>
              </b-col>
            </template>
            <template v-else>
              <b-col>
                <p class="text-danger text-center">
                  Please select vocab words to create problem
                </p>
              </b-col>
            </template>
            <template v-if="missingWords.length > 0">
              <span class="border-bottom mr-1">Missing words:</span>
              <span v-for="missingWord, index in missingWords"
                    :key="index"
                    class="text-danger mr-1"
              >
                {{ missingWord }}
              </span>
            </template>
          </b-row>
        </b-col>
      </b-row>
      <div class="d-flex mt-2 justify-content-end">
        <b-button
          v-if="eventId"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mr-2"
          variant="outline-danger"
          @click="$emit('remove-event');"
        >
          {{ $t('actions.delete') }}
        </b-button>
        <b-button
          v-if="!eventId"
          class="ml-1"
          variant="primary"
          :disabled="isProcessing || selectedWords.length === 0"
          @click="createWordProblem"
        >
          <b-spinner
            v-if="isProcessing"
            class="mr-1"
            small
            variant="light"
          />
          {{ eventId ? $t('actions.update') : $t('actions.create-problem') }}
        </b-button>
      </div>
    </validation-observer>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BButton,
  BFormInput,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  BFormInvalidFeedback,
  VBTooltip,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'
import GradeCoursePicker from '@/views/common/lessons/components/GradeCourseFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    GradeCoursePicker,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  inject: {
    injectedClassRoomId: {
      default: null,
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['resetForm', 'getValidationState', 'activeTab', 'eventId', 'defaultStartDate'],
  data() {
    return {
      wordCsv: [],
      isProcessing: false,
      selectedWords: [],
      studentsForWords: [],
      isSearching: false,
      wordStartDate: this.defaultStartDate ? this.FORMAT_DATE(this.defaultStartDate) : '',
      wordEndDate: '',
      answerDeliveryOption: '',
      searchWord: '',
      filteredSearchedWords: [],
      searchedWords: [],
      missingWords: [],
      studentsList: [],
      fetchFromLesson: false,
      ansDeliveryOptions: [
        {
          label: 'Example Sentence',
          value: 'example_sen',
        },
        {
          label: 'Description Sentence',
          value: 'desc_sen',
        },
      ],
      selectAllStudents: false,
    }
  },
  computed: {
    classId() {
      return +(this.injectedClassRoomId || this.$route.params.classId)
    },
    isEditMode() {
      return !!this.eventId
    },
  },
  watch: {
    activeTab(value) {
      if (value === 'vocabWordsProblem') {
        this.getStudentsForVw()
      }
    },
    eventId: {
      handler(value) {
        if (value) {
          this.getEventDetail()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getEventDetail() {
      this.getStudentsForVw()
      this.isProcessing = true
      useJwt.getVocabEventInfo(this.eventId).then(response => {
        const vocab = response.data.data.data
        const loader = vocab.vocabLoaders
        this.studentsForWords = loader.map(i => i.user_id)
        this.wordStartDate = vocab.start_time
        this.wordEndDate = vocab.end_time
        this.answerDeliveryOption = loader[0]?.rule?.type || null
        this.selectedWords = response.data.data.words
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getStudentsForVw() {
      useApollo.getClassRoomStudents(this.classId).then(response => {
        this.studentsList = response.data?.room?.students.map(student => ({
          ...student,
          id: Number(student.id),
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    removeWord(wordIndex) {
      this.selectedWords.splice(wordIndex, 1)
    },
    vocabStartDateChanged() {
      if (new Date(this.wordEndDate).getTime() < new Date(this.wordStartDate).getTime()) {
        this.wordEndDate = this.wordStartDate
      }
    },
    createWordProblem() {
      if (this.eventId) {
        this.$emit('update')
        return
      }
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.isProcessing = true
          const formData = new FormData()

          this.selectedWords.forEach(word => {
            formData.append('word_ids[]', word.id)
          })

          this.studentsForWords.forEach(user => {
            formData.append('user_ids[]', user)
          })
          formData.append('start_time', this.wordStartDate)
          formData.append('end_time', this.wordEndDate)
          formData.append('class_id', this.classId)
          formData.append('answer_delivery_option', this.answerDeliveryOption)
          useJwt.createVocabEventProblem(formData)
            .then(response => {
              this.searchWord = ''
              this.searchedWords = []
              this.filteredSearchedWords = []
              this.selectedWords = []
              this.missingWords = []
              this.studentsForWords = []
              this.wordStartDate = ''
              this.wordEndDate = ''
              // this.activeTab = 'vocabWordsProblem'
              this.answerDeliveryOption = ''
              this.showSuccessMessage(response)
              this.resetForm()
              this.$emit('refetch-events')
            }).catch(error => {
              this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    fetchFromLessonWords(lessonId) {
      this.isSearching = true
      useJwt.getLessonWords({
        params: {
          lesson_id: lessonId,
        },
      }).then(response => {
        this.searchedWords = []
        this.filteredSearchedWords = response.data.data.data
        // this.searchedWords = response.data.data.data
      }).catch(() => {
        this.searchedWords = []
        this.filteredSearchedWords = []
      }).finally(() => {
        this.isSearching = false
      })
    },
    uploadVocabCsv() {
      if (this.wordCsv) {
        const formData = new FormData()
        this.isSearching = true
        formData.append('wordCsv', this.wordCsv)
        useJwt.uploadVocabCsv(formData)
          .then(response => {
            this.selectedWords = response.data.data?.imageLibrary
            this.missingWords = response.data.data?.missingWords
            this.isSearching = false
            this.wordCsv = []
          })
      }
    },
    searchWordFromIL() {
      this.isSearching = true
      if (!this.searchWord) {
        this.searchedWords = []
        this.filteredSearchedWords = []
        this.isSearching = false
      } else {
        useJwt.getImageLibrary({
          params: {
            term: this.searchWord,
            lang: 'english',
            getAll: true,
          },
        }).then(response => {
          this.isSearching = false
          this.filteredSearchedWords = []
          this.searchedWords = response.data.data.map(word => ({
            id: word.id,
            vocab_word: word.vocab_word,
            synonyms: word.synonyms,
          }))
        })
      }
    },
    handleSelectAllStudents() {
      if (this.selectAllStudents) {
        // Select all student IDs
        this.studentsForWords = this.studentsList.map(student => student.id)
      } else {
        // Deselect all
        this.studentsForWords = []
      }
    },
    downloadSample() {
      const href = '/sample-file/vocab-words.csv'
      const extension = '.csv'
      const link = document.createElement('a')
      link.href = href // Replace with your file URL
      link.download = `vocab-words${extension}` // Specify the file name
      link.click()
    },
    playAudio(audioSrc) {
      const audio = new Audio(audioSrc)
      audio.play()
    },
  },
}
</script>
<style>
.word-options {
  padding:5px;
}
.word-options .synonym-word {
  font-weight: 500;
}
.word-options .item-price {
  font-size:12px;
}
.word-options .word-synonym {
  font-style: italic;
}
.play-button {
  color: #28c76f;
}
.play-button:hover {
  color: #afe0c9;
}
</style>
