<template>
  <div id="studentTableNab">
    <b-nav
      id="dropdown-1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="w-100 bg-blue"
      toggle-class="text-decoration-none"
      variant="link"
      no-caret
      style="border-radius: 0; display:block"
    >
      <template v-for="(nav, index) of navMenusWithActiveState">
        <b-nav-item
          v-if="nav.condition"
          :key="index"
          v-b-modal="nav.hasModal"
          :class="[nav.class, { active: nav.isActive }]"
          :disabled="isProcessing"
          @click="nav.action"
        >
          <span
            class="d-flex"
            :class="{ 'justify-content-center': !showFullSidebar }"
          >
            <span :class="{ 'mr-1': showFullSidebar }">
              <feather-icon
                v-b-tooltip.hover="nav.name"
                :icon="nav.icon"
                :size="showFullSidebar ? '14' : '20'"
              />
            </span>
            <span v-if="showFullSidebar">{{ nav.name }}</span>
          </span>
        </b-nav-item>
      </template>
    </b-nav>
    <!-- For Mobile View -->
  </div>
</template>
<script>
import i18n from '@/libs/i18n'
import {
  BNav, BNavItem, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import { isVisible } from '@/utils/visibilitySettings'

export default {
  components: {
    BNav,
    BNavItem,
  },
  directives: {
    Ripple,
    VBTooltip,
  },
  props: {
    room: {
      type: [Object, Array],
      default: () => { },
    },
    isHighLevelUser: {
      type: [String, Boolean],
      default: false,
    },
    isATeacher: {
      type: [String, Boolean],
      default: false,
    },
    isAStudent: {
      type: [String, Boolean],
      default: false,
    },
    isProcessing: {
      type: [String, Boolean],
      default: false,
    },
    isAClassConductor: {
      type: [String, Boolean],
      default: false,
    },
    isAClassTeacher: {
      type: [String, Boolean],
      default: false,
    },
    classId: {
      type: [Number, String],
      default: () => { },
    },
    hasClassTest: {
      type: Boolean,
      default: false,
    },
    isSelfStudy: {
      type: Boolean,
      default: false,
    },
    hideClassTestSetting: {
      type: Number,
      default: 0,
    },
    hideViewEssay: {
      type: Number,
      default: 0,
    },
    hideShowScript: {
      type: Number,
      default: 0,
    },
    navType: {
      type: String,
      default: 'class-management',
    },
    showFullSidebar: {
      type: Boolean,
      default: () => true,
    },
    activeNav: {
      type: String,
      default: () => 'assignment',
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    schoolSetting() {
      return this.$store.state.appConfig?.schoolInfo?.user_meta
    },
    distSetting() {
      return this.$store.state.appConfig?.districtInfo?.user_meta
    },
    hideLanguageSkillReport() {
      return !isVisible('language_skills_report', this.schoolSetting, this.distSetting)
    },

    hideGameReport() {
      return !isVisible('game_report', this.schoolSetting, this.distSetting)
    },
    hideClassTestReport() {
      return !isVisible('class_test_report', this.schoolSetting, this.distSetting)
    },

    hideAcademicReport() {
      const problemSolvingSkillsValue = !isVisible('problem_solving_skills', this.schoolSetting, this.distSetting)
      const proficiencyOnLessonsValue = !isVisible('proficiency_on_lessons', this.schoolSetting, this.distSetting)
      const lessonCompletionRateValue = !isVisible('lesson_completion_rate', this.schoolSetting, this.distSetting)

      return problemSolvingSkillsValue
         && proficiencyOnLessonsValue
         && lessonCompletionRateValue ? 1 : 0
    },
    hideStudySkillsReport() {
      const attendanceReportValue = !isVisible('attendance_report', this.schoolSetting, this.distSetting)
      const activityReportValue = !isVisible('activity_report', this.schoolSetting, this.distSetting)
      const lessonCompletionRateValue = !isVisible('schedule_report', this.schoolSetting, this.distSetting)

      return attendanceReportValue
         && activityReportValue
         && lessonCompletionRateValue ? 1 : 0
    },
    hideSocialSkillsReport() {
      const noteReportValue = !isVisible('note_report', this.schoolSetting, this.distSetting)
      const chatReportValue = !isVisible('chat_report', this.schoolSetting, this.distSetting)
      const roleReportValue = !isVisible('role_report', this.schoolSetting, this.distSetting)

      return noteReportValue
         && chatReportValue
         && roleReportValue ? 1 : 0
    },

    navMenusWithActiveState() {
      let navOption = this.navMenus
      if (this.navType === 'report-management') {
        navOption = this.reportMenus
      }
      if (this.navType === 'student-management') {
        navOption = this.studentMenu
      }
      return navOption.map(nav => ({
        ...nav,
        isActive: nav.navName === this.activeNav,
      }))
    },
    navMenus() {
      return [
        {
          name: i18n.tc('class-module.assignments'),
          navName: 'assignment',
          icon: 'BookOpenIcon',
          action: () => this.selectedNav('assignment'),
          disabled: false,
          condition: true,
          class: 'assignment',
        },
        {
          name: i18n.tc('student-diary-module.class-test'),
          icon: 'PenToolIcon',
          action: () => this.selectedNav('class-test'),
          navName: 'class-test',
          class: this.hasClassTest && 'bg-danger',
          condition: this.hideClassTestSetting === 0,
        },
        {
          name: i18n.tc('class-module.events'),
          navName: 'calendar-event',
          class: 'calendar-event',
          icon: 'CalendarIcon',
          action: () => this.selectedNav('calendar-event'),
          disabled: false,
          condition: true,
        },
        {
          name: i18n.tc('student-diary-module.scoreboard'),
          icon: 'UsersIcon',
          action: () => {
            window.open(`/student-role/${this.classId}`, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=625,height=400')
          },
          condition: this.isATeacher || this.isHighLevelUser || this.isAClassConductor,
        },
        {
          name: i18n.tc('navigations.show-instruction'),
          icon: 'BookOpenIcon',
          condition: this.hideShowScript === 0,
          action: () => this.$emit('showInstruction'),
        },
        {
          name: i18n.tc('student-diary-module.attendance'),
          icon: 'CalendarIcon',
          action: () => this.selectedNav('attendance'),
          condition: this.isATeacher || this.isHighLevelUser,
          navName: 'attendance',
        },
        {
          name: i18n.tc('student-diary-module.rubrics'),
          icon: 'GridIcon',
          action: () => this.$emit('showClassRubrics'),
          condition: this.isATeacher,
        },
        {
          name: i18n.tc('class-module.compose-email'),
          icon: 'MailIcon',
          action: () => this.selectedNav('compose-email'),
          disabled: false,
          class: 'compose-email',
          navName: 'compose-email',
          condition: this.isHighLevelUser && !this.isAStudent,
        },
        {
          name: i18n.tc('navigation-menu.settings'),
          icon: 'SettingsIcon',
          condition: (this.isATeacher || this.isHighLevelUser),
          action: () => this.selectedNav('game-settings'),
          navName: 'game-settings',
        },
      ]
    },

    reportMenus() {
      return [
        {
          name: i18n.tc('course-completion-module.course-report'),
          icon: 'BarChart2Icon',
          action: () => this.selectedNav('course-report'),
          disabled: !this.room?.id,
          condition: true,
          class: 'course-report',
          navName: 'course-report',
        },
        {
          name: i18n.tc('course-completion-module.academic-skills'),
          icon: 'TrendingUpIcon',
          action: () => this.selectedNav('academic-skills'),
          class: 'academic-skills',
          navName: 'academic-skills',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent && !this.hideAcademicReport,
        },
        {
          name: i18n.tc('course-completion-module.language-skills'),
          icon: 'BarChartIcon',
          action: () => this.selectedNav('language-skills'),
          class: 'language-skills',
          navName: 'language-skills',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent && !this.hideLanguageSkillReport,
        },
        {
          name: i18n.tc('course-completion-module.study-skills'),
          icon: 'BookOpenIcon',
          action: () => this.selectedNav('study-skills'),
          class: 'study-skills',
          navName: 'study-skills',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent && !this.hideStudySkillsReport,
        },
        {
          name: i18n.tc('course-completion-module.social-skills'),
          icon: 'BarChart2Icon',
          action: () => this.selectedNav('social-skills'),
          class: 'social-skills',
          navName: 'social-skills',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent && !this.hideSocialSkillsReport,
        },
        {
          name: i18n.tc('course-completion-module.game-report'),
          icon: 'BarChartIcon',
          action: () => this.selectedNav('game-report'),
          class: 'game-report',
          navName: 'game-report',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent && !this.hideGameReport,
        },
        {
          name: i18n.tc('course-completion-module.class-test-report'),
          icon: 'BookOpenIcon',
          action: () => this.selectedNav('class-test-report'),
          class: 'class-test-report',
          navName: 'class-test-report',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent && !this.hideClassTestReport,
        },
        {
          name: i18n.tc('student-diary-module.class-monitor'),
          icon: 'MonitorIcon',
          action: () => this.selectedNav('class-monitor'),
          class: 'class-monitor',
          navName: 'class-monitor',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent,
        },
        {
          name: i18n.tc('class-module.overall-report'),
          icon: 'SidebarIcon',
          action: () => this.selectedNav('overall-report'),
          class: 'overall-report',
          navName: 'overall-report',
          condition: (this.isHighLevelUser || this.isATeacher) && !this.isAStudent,
        },
      ]
    },

    studentMenu() {
      return [
        {
          name: i18n.tc('class-module.add-students'),
          icon: 'UsersIcon',
          action: () => this.$emit('showAddStudentsModal'),
          condition: (this.isHighLevelUser || this.isAClassTeacher),
        },
        {
          name: i18n.tc('class-module.pair-students'),
          icon: 'UsersIcon',
          action: () => this.selectedNav('pair-students'),
          condition: (this.isHighLevelUser || this.isAClassTeacher),
          class: 'pair-students',
          navName: 'pair-students',
        },
        {
          name: i18n.tc('labels.badges-info'),
          icon: 'InfoIcon',
          class: 'd-lg-none d-md-none',
          condition: this.isATeacher || this.isHighLevelUser || this.isAClassConductor,
          hasModal: 'badges-info',
          action: () => { },
        },
      ]
    },

  },
  watch: {
    navType(val) {
      if (val === 'report-management') {
        this.activeNav = 'course-report'
      } else if (val === 'class-management') {
        this.activeNav = 'assignment'
      }
    },
  },
  methods: {
    selectedNav(navType) {
      this.activeNav = navType
      this.$emit('showClassManagementSide', navType)
    },
  },
}
</script>

<style lang="scss" scoped>
.nav {
  padding: 0px;
  margin: 0;
}

.dark-layout .nav {
  /* background: #354061; */
  padding: 0px;
  margin: 0;
}

.nav-item,.b-nav-dropdown {
  font-size: 12px;
  /* border-right: 1px solid #56756b; */
}
.nav-link {
  padding: 0.65rem 0.5rem;
}

.nav-item a:hover {
  color: #FF9F1C  !important;
  background-color: rgba(115, 103, 240, 0.12);
}
.nav-item.active a {
  color: #FF9F1C  !important;
  background-color: rgba(115, 103, 240, 0.12);
}

.nav-item, a {
  color: #6e6b7b;
  font-size: 14px;
}
.b-nav-dropdown, a {
  color: #6e6b7b;
}
::v-deep a.nav-link.dropdown-toggle {
  color: #6e6b7b !important;
}
::v-deep a.nav-link.dropdown-toggle {
  padding-top: 4px !important;
  display:flex;
  align-items:center;
  padding-right:10px !important;
}
::v-deep .dropdown-item:hover {
  color: #e19f27 !important;
}
.dark-layout {
  .nav-link {
    color: #dfdfdf;
  }
}
</style>
<style>
#studentTableNab .b-nav-dropdown .dropdown-toggle::after {
  margin-right: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important
}
</style>
