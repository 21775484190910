var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[(!_vm.data.result && !_vm.teachersStudentId && !_vm.processing)?_c('b-col',{staticClass:"pt-3"},[(_vm.resultInfo.is_answer_prepared && _vm.resultInfo.status === 'failed')?_c('FailedSympathy',{attrs:{"processing":_vm.processing,"self":_vm.self,"lesson":_vm.resultInfo.lesson,"result-info":_vm.resultInfo},on:{"viewResult":_vm.getResult}}):(_vm.resultInfo.is_answer_prepared)?_c('congratulation',{attrs:{"processing":_vm.processing,"self":_vm.self,"lesson":_vm.resultInfo.lesson},on:{"viewResult":_vm.getResult}}):_c('wait-for-answer',{attrs:{"processing":_vm.processing,"self":_vm.self,"lesson":_vm.resultInfo.lesson,"result-info":_vm.resultInfo},on:{"viewResult":_vm.getResult}})],1):(_vm.data.result)?_c('b-col',{staticClass:"p-0",attrs:{"md":"12"}},[(_vm.data)?_c('b-card',{staticClass:"card not-student-card h-100 rounded-0",attrs:{"text-variant":"center"}},[(!_vm.isFrameView)?_c('b-col',{staticClass:"p-0 d-flex justify-content-start flex-wrap pb-1",attrs:{"md":"12"}},[_c('div',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.GO_BACK()}}},[_c('feather-icon',{attrs:{"size":"20","icon":"ArrowLeftIcon"}})],1),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.$router.push({name: `${_vm.self.usertype}-view-students`,params: { id: _vm.classId },})}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('labels.classroom'))+" ")])]),_vm._v(" | "),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-secondary"},on:{"click":() => {
              if (_vm.classTestId) {
                return _vm.$router.push({name:`${_vm.self.usertype}-class-test`,params:{classId: _vm.classId} })
              }
              _vm.$router.push({
                name: `${_vm.self.usertype}-lessons`,
                params: { id: _vm.data.course },
                query: { group: _vm.data.gid },
              })
            }}},[_vm._v(" "+_vm._s(_vm.$t("actions.list-of-lessons"))+" ")])],1)]):_vm._e(),_c('b-col',[_c('b-row',[(_vm.data.image)?_c('b-col',{attrs:{"md":_vm.data.image ? '2' : '0',"sm":_vm.data.image ? '2' : '0',"offset":"3"}},[_c('b-img',{staticStyle:{"max-height":"200px"},attrs:{"src":_vm.data.image,"thumbnail":"","fluid":""}})],1):_vm._e(),_c('b-col',{staticClass:"mt-2 sm-mt-0 d-flex flex-column justify-content-end pl-0",attrs:{"md":_vm.data.image ? '6' : '12',"sm":"12"}},[_c('h1',{staticClass:"mb-1 mt-50"},[_vm._v(" "+_vm._s(_vm.$t("student-test-module.lesson-completed"))+" ")]),_c('b-card-text',[_c('h5',[(_vm.teachersStudentId)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.data.userName))]),_vm._v(" "+_vm._s(_vm.$t("student-test-module.completed-all-standards-student"))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("student-test-module.completed-all-standards"))+" ")]),_c('strong',[_vm._v(" "+_vm._s(_vm.data.lesson))])])])],1)],1)],1),(!_vm.isResultDDF)?_c('div',{staticClass:"d-flex justify-content-around flex-wrap mt-4"},[_c('div',{staticClass:"text-center"},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("student-test-module.required-percentage"))+" ")]),_c('span',{staticClass:"font-large-1 font-weight-bold"},[_vm._v(_vm._s(_vm.data.required_percentage || 40)+"%")])],1),_c('div',{staticClass:"text-center"},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("student-test-module.obtained-percentage"))+" ")]),_c('span',{staticClass:"font-large-1 font-weight-bold",class:{
              'text-danger': _vm.hasFailed,
              'text-success': !_vm.hasFailed,
            }},[_vm._v(_vm._s(_vm.data.score_percentage)+"%")])],1),_c('div',{staticClass:"text-center"},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("student-test-module.obtain-point"))+" ")]),_c('span',{staticClass:"font-large-1 font-weight-bold"},[_vm._v(_vm._s(_vm.data.obtained_points))])],1),_c('div',{staticClass:"text-center"},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("student-test-module.taken-time"))+" ")]),(_vm.data.total_time_spent)?_c('span',{staticClass:"font-large-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.total_time_spent)+" ")]):_c('span',{staticClass:"font-large-1 font-weight-bold"},[_vm._v(" N/A ")])],1)]):_vm._e(),(_vm.viewDetailTable)?_c('div',{staticClass:"mt-2"},[(_vm.showReportDetails)?_c('b-table',{staticClass:"table-hover",attrs:{"responsive":"sm","items":_vm.getResultRows,"fields":_vm.fields,"borderless":""},scopedSlots:_vm._u([{key:"cell(status)",fn:function(d){return [_c('span',[(d.item.status === 'passed' || _vm.isResultDDF)?_c('feather-icon',{staticClass:"text-success",attrs:{"size":"20","icon":"CheckIcon"}}):_c('feather-icon',{staticClass:"text-danger",attrs:{"size":"20","icon":"XIcon"}})],1)]}},{key:"cell(passed_problems)",fn:function(d){return [(_vm.isResultDDF)?_c('span',{staticClass:"text-success"},[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(d.item.passed_problems)+" ")]),_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(d.item.failed_problem)+" ")])],1):_c('span',{class:{
                'text-danger': d.item.status != 'passed',
                'text-success': d.item.status == 'passed',
              }},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(d.item.passed_problems)+" ")]),_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(d.item.failed_problem)+" ")])],1)]}}],null,false,202505986)}):_vm._e()],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[(!_vm.viewDetailTable)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.result-details-modal",modifiers:{"result-details-modal":true}}],staticClass:"mt-1",attrs:{"variant":"outline-primary"}},[_vm._v(" "+_vm._s(_vm.$t('View Report Details'))+" ")]):_vm._e(),(_vm.data.next_lesson != null && _vm.data.next_lesson !== 0 && _vm.viewDetailTable)?_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-primary"},on:{"click":() => {
              if (_vm.classTestId) {
                return _vm.$router.push({name:`${_vm.self.usertype}-class-test`,params:{classId: _vm.classId} })
              }
              _vm.$router.push({
                name: `${_vm.self.usertype}-lessons`,
                params: { classId: _vm.classId, id: _vm.resultInfo.course },
                query: { group: _vm.data.gid },
              })
            }}},[_vm._v(" "+_vm._s(_vm.$t("student-test-module.next-lesson"))+" ")]):(_vm.data.is_course_completed)?_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-primary"},on:{"click":() => {
                if (_vm.classTestId) {
                  return _vm.$router.push({name:`${_vm.self.usertype}-class-test`,params:{classId: _vm.classId} })
                }
                _vm.$router.push({
                  name: `${_vm.self.usertype}-course-result`,
                  params: { id: _vm.data.course },
                })
              }}},[_vm._v(" "+_vm._s(_vm.$t("student-test-module.course-result"))+" ")]):_vm._e()],1)],1)],1):_vm._e()],1):_c('b-col',[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}})],1),(!_vm.viewDetailTable)?_c('ResultDetailsModal',{attrs:{"loader-id":_vm.routeParams.loaderId}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }