<template>
  <b-card
    v-if="data"
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-card-body
      style="height: 100%"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <b-avatar
        variant="primary"
        size="70"
        class="shadow mb-2"
      >
        <feather-icon
          size="28"
          icon="AwardIcon"
        />
      </b-avatar>
      <template v-if="fromTeacher">
        <h1 class="mb-1 mt-50 text-white">
          {{ data.name }}
        </h1>
      </template>
      <template v-else>
        <h1 class="mb-1 mt-50 text-white">
          {{ $t('labels.good') }} {{ data.time }} {{ data.name }},
        </h1>
        <strong>{{ data.text }}
          <!-- <span style="text-transform: lowercase;">{{ data.time }}</span> -->
        </strong>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardBody,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fromTeacher: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
