<template>
  <span v-if="scores.length > 0"
        class="dialogs"
        role="button"
  >
    <b-badge variant="primary"
             class="mb-2"
             @click="showModal = true"
    >
      Report
    </b-badge>
    <component :is="!isReportView ? 'portal' : 'div'"
               to="dragModalTargetArea"
    >
      <DialogDrag v-if="showModal"
                  size="lg"
                  class="open-ai-score"
                  style="z-index: 999999999999999999999999;"
                  @close="showModal = false"
      >
        <h5
          slot="title"
          class="m-0 text-capitalize"
        >Report
        </h5>
        <b-table-simple>
          <thead>
            <tr>
              <th>Label</th>
              <th>Score</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item,index of scores"
                :key="index"
            >
              <td class="text-dark">
                {{ item.label }}
              </td>
              <td class="text-nowrap text-dark">
                {{ item.score }} / {{ item.total_score }}
              </td>
              <td class="text-dark">

                <feather-icon
                  v-if="item.description"
                  v-play-voice
                  icon="Volume2Icon"
                  role="button"
                  class="mr-1"
                  :text="item.description"
                />
                <small>{{ item.description }}</small>
              </td>
            </tr>
            <tr>
              <td>
                Total Score
              </td>
              <td>
                {{ number_format(getTotalScore, 2) }} / 10
              </td>
              <td>
                -
              </td>
            </tr>
          </tbody>
        </b-table-simple>
        <div class="ml-2">
          <h4>
            Sentence Count
          </h4>
          <div class="row">
            <div class="col-6">
              <span class="text-dark">Total Sentence:</span>
              <span class="text-dark ml-2">{{ countedSentencesAndWords.sentenceCount }}</span>
            </div>
            <div class="col-6">
              <ul style="list-style-type: none;">
                <li v-for="word,index of countedSentencesAndWords.wordCounts"
                    :key="index"
                    class="text-dark"
                >
                  Sentence {{ index + 1 }} :- {{ word }} Words
                </li>
              </ul>
            </div>
          </div>
        </div>
      </DialogDrag>
    </component>
    <div v-if="isATeacher">
      <b-button variant="gradient-primary"
                :disabled="isProcessing"
                @click="saveOpenAiScore"
      >
        <b-spinner v-if="isProcessing"
                   small
        />  ReGenerate AI Score
      </b-button>
    </div>
  </span>
</template>
<script>
import {
  BTableSimple, BButton, BSpinner, BBadge,
} from 'bootstrap-vue'
import DialogDrag from 'vue-dialog-drag'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BTableSimple,
    BButton,
    BSpinner,
    BBadge,
    DialogDrag,
    AudioPlayer,
  },
  props: {
    scores: {
      type: Array,
      default: () => [],
    },
    answer: {
      type: String,
      default: '',
    },
    testId: {
      type: Number,
      default: 0,
    },
    hasTeacherScored: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      isProcessing: false,
    }
  },
  computed: {
    isReportView() {
      return this.$route.path.includes('course/reporting')
    },
    isATeacher() {
      return ['teacher', 'super'].includes(this.AUTH_USER().usertype)
    },
    getTotalScore() {
      if (!this.scores.length) {
        return 0
      }
      const scoreCount = this.scores.reduce((c, p) => c + Number(p.score), 0)
      return (scoreCount / (10 * this.scores.length)) * 10
    },
    countedSentencesAndWords() {
      const text = this.answer
      // Count the number of sentences
      const sentences = text.split(/[.!?]+/g).filter(Boolean)
      const sentenceCount = sentences.length

      // Count the number of words in each sentence
      const wordCounts = sentences.map(sentence => {
        const words = sentence.trim().split(/\s+/)
        return words.length
      })

      return {
        sentenceCount,
        wordCounts,
      }
    },
  },
  methods: {
    saveOpenAiScore() {
      this.isProcessing = true
      useJwt.updateOpenAiScore(this.testId).then(response => {
        const openAiScore = response.data.data.open_ai_score
        if (openAiScore.length === 0) {
          this.showInfo('Calculation was not successful. Please try again')
        } else {
          this.showSuccessMessage(response)
          this.$emit('refetch', response.data.data.open_ai_score, response.data.data.test_stat.score)
          this.$root.$emit('reFetchStudentLessonStatReport')
          this.$forceUpdate()
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
