<template>
  <b-modal
    id="lesson-visualize-modal"
    :title="`${$t('fractal-visualization')} - ${props.lessonGroupName}`"
    ok-title="Close"
    size="lg"
    ok-only
    no-close-on-backdrop
  >
    <b-row v-if="visualizationInfo.visualize.length">
      <!-- Lessons -->
      <b-col
        v-for="lesson, index in visualizationInfo.visualize"
        :key="lesson.id"
        class="border px-0"
        :class="lesson.lesson_id == visualizationInfo.selectedLesson ? 'border-primary' : ''"
      >
        <!-- Lesson Title -->
        <div class="text-center bg-light-secondary mx-0">
          <b-avatar
            v-b-tooltip.hover="lesson.name"
            variant="primary"
            :text="index === 0 ? 'S' : String.fromCharCode(index + 64)"
            class="cursor-default"
          />
        </div>

        <div class="mt-1 d-flex justify-content-around">
          <!-- Thesis -->
          <b-avatar
            v-if="getThesis(lesson)"
            v-b-tooltip.hover="getThesis(lesson).content"
            variant="primary"
            text="SC"
            class="cursor-default"
          />

          <!-- Main Points -->
          <div
            v-for="mainPoint, mpIndex in getMainPoints(lesson)"
            :key="`${mainPoint.order_index}-${mainPoint.page_id}`"
          >
            <b-avatar
              v-b-tooltip.hover="mainPoint.content"
              :text="`${mpIndex + 1}`"
              variant="primary"
              class="cursor-default"
            />
          </div>
        </div>

        <!-- Pages -->
        <div
          v-if="lesson.pages.length"
          class="my-1"
        >
          <div class="d-flex justify-content-around">
            <div
              v-for="page, pageIndex in lesson.pages"
              :key="page.id"
            >
              <div v-for="answer in getAnswer(page)"
                   :key="`${answer.order_index}-${answer.set_no}`"
                   :set="problemStatus = getProblemVariant(page, answer, lesson.lesson_id)"
              >
                <div
                  role="button"
                  class="mb-1"
                  @click="() => {
                    if (!getProblemVariant(page, answer, lesson.lesson_id).isCompleted) {
                      startConversationalMode(answer, lesson, problemStatus)
                    }
                  }"
                >
                  <b-avatar
                    v-b-tooltip.hover="answer.content"
                    class="cursor-default lesson-visualize-pointer"
                    :variant="problemStatus.variant"
                  >
                    {{ pageIndex === 0 ? `S${answer.set_no}` : `${pageIndex}${String.fromCharCode(answer.set_no + 64).toLowerCase()}` }}
                  </b-avatar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          v-else
          class="text-center"
        >
          -
        </p>
      </b-col>
    </b-row>

    <p
      v-else
      class="text-center"
    >
      {{ $t('lesson-pages-module.no-visualization-data') }}
    </p>
    <ConversationalMode v-if="conversationalModeInfo"
                        :mode-info="conversationalModeInfo"
                        :class-id="classId"
                        @close="conversationalModeInfo = null"
                        @onSuccess="conversationalModeInfo = null; $emit('reFetch')"
    />
  </b-modal>
</template>

<script setup>
import {
  BModal, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import { ref, computed } from 'vue'
import ConversationalMode from '../conversational-mode/index.vue'

const props = defineProps({
  lessonGroupName: {
    type: String,
    required: true,
  },
  visualizationInfo: {
    type: Object,
    required: true,
  },
  classId: {
    type: Number,
    default: 0,
  },
})

const getThesis = lesson => lesson.common_metas.find(meta => meta.type === 'TS')
const getMainPoints = lesson => lesson.common_metas.filter(meta => meta.type === 'MP')
const getAnswer = page => page.page_metas.filter(meta => meta.type === 'ANS')

const conversationalModeInfo = ref(null)

const startConversationalMode = (answer, lesson) => {
  conversationalModeInfo.value = {
    set_no: answer.set_no,
    page_id: answer.page_id,
    lesson_id: lesson.lesson_id,
  }
  props.visualizationInfo.selectedLesson = lesson.lesson_id
}

const getProperArray = data => {
  if (!data) {
    return []
  }
  if (!Array.isArray(data)) {
    return Object.values(data)
  }
  return data
}

// eslint-disable-next-line no-unused-vars
const passFailedProblem = computed(() => {
  let passed = []
  let failed = []
  props.visualizationInfo.loader.forEach(item => {
    passed = [...passed, ...getProperArray(item.passed_problems.map(i => i.name))]
    failed = [...failed, ...getProperArray(item.failed_problems.map(i => i.name))]
  })
  return {
    passed,
    failed,
  }
})

const getProblemVariant = (page, answer, lessonId) => {
  const problemName = `L${lessonId}-P${page.page_no}-S${answer.set_no}-${props.visualizationInfo.convTyeProblem}`
  if (passFailedProblem.value.passed.find(i => i === problemName)) {
    return {
      variant: 'success',
      isCompleted: true,
    }
  }
  if (passFailedProblem.value.failed.find(i => i === problemName)) {
    return {
      variant: 'warning',
      isCompleted: false,
    }
  }
  return {
    variant: 'secondary',
    isCompleted: false,
  }
}
</script>
<style>
.lesson-visualize-pointer {
  cursor: pointer;
}
</style>
