<template>
  <b-modal
    id="lesson-delete"
    ref="modal-delete"
    ok-only
    ok-variant="danger"
    :ok-title="$t('confirm')"
    modal-class="modal-danger"
    centered
    :title="$t('actions.' + modalDeleteMode)"
    @ok="deleteItem"
  >
    <b-card-text>
      {{ $t("questions." + modalDeleteMode) }}
    </b-card-text>
    <template #modal-footer="{ok }">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        :disabled="isProcessing"
        @click="ok()"
      >
        <b-spinner
          v-show="isProcessing"
          small
        />
        {{ $t("confirm") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BSpinner, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BButton,
    BSpinner,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    modalDeleteMode: {
      type: String,
      default: 'delete-lesson',
    },
    modalFunction: {
      type: String,
      default: 'deleteLesson',
    },
    deleteItemIndex: {
      type: Number,
      default: () => null,
    },
    lesson_group_id: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  methods: {
    deleteItem() {
      this.isProcessing = true
      const params = {
        lesson_group_id: this.lesson_group_id,
      }

      useJwt[this.modalFunction](this.deleteItemIndex, { params })
        .then(response => {
          this.$bvModal.hide('lesson-delete')
          this.showSuccessMessage(response)
          this.$emit('deleted', 'deleteItemIndex', null)
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
