<template>
  <div>
    <b-card
      :title="$t('report.latest-rubric')"
    >
      <div>
        <v-select
          v-model="searchTermForClasses"
          :options="searchRooms"
          :filterable="false"
          :reduce="(room) => room.id"
          :disabled="isProcessing"
          :placeholder="$t('class-module.search-classroom')"
          class="m-md"
          label="name"
          :clearable="false"
          @input="getRubrics"
          @search="searchClass"
        />
      </div>
      <div class="pt-2">
        <b-overlay
          :show="isProcessing"
        >
          <student-rubric-table
            :is-a-teacher="false"
            :no-action="true"
            :rubrics="studentRubrics"
            @navigateToCoursePage="
              (id, lessons) =>{
                $router.push({
                  name: `${self.usertype}-lessons`,
                  params: { classId: searchTermForClasses, id: id },
                  query: { student_id: userId },
                })
              }

            "
          />
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import StudentRubricTable from '@/views/common/class/components/rubric/components/StudentRubricTable.vue'
import formatRubricData from '@/utils/formatRubricData'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    // BButton,
    vSelect,
    BOverlay,
    StudentRubricTable,
  },
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchRooms: [],
      searchTermForClasses: null,
      isProcessing: false,
      fetchingTimeout: null,
      self: getUserData(),
      columns: [
        {
          key: 'rubric',
          label: 'Rubric',
        },
        {
          key: 'actual',
          label: 'Actual',
        },
        {
          key: 'expected',
          label: 'Excepted',
        },
        {
          key: 'percent_completed',
          label: '% Completed',
          thClass: 'text-nowrap',
        },
        {
          key: 'due',
          label: 'Due',
        },
      ],
      studentRubrics: [],
    }
  },
  mounted() {
    this.searchClass('')
  },
  methods: {
    searchClass(value) {
      this.searchClassInput = value
      if (this.fetchingTimeout) clearTimeout(this.fetchingTimeout)
      const selectedClassType = null
      const { userId } = this
      this.fetchingTimeout = setTimeout(() => {
        useApollo
          .searchRooms(value, userId, selectedClassType)
          .then(res => {
            this.searchRooms = res.data.searchRooms
            if (this.searchRooms.length) {
              this.searchTermForClasses = this.searchRooms[0].id
              this.getRubrics()
            }
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
      }, 500)
    },
    getRubrics() {
      this.isProcessing = true
      useJwt.getStudentRubrics(this.userId, this.searchTermForClasses).then(response => {
        const { rubrics, events } = formatRubricData(response)
        this.studentRubrics = [...rubrics, ...events]
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
