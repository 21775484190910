<template>
  <b-card class="mb-0 p-2">
    <h5
      v-if="selectedTest.name"
      class="mb-2"
    >
      {{ $t("labels.tests-in") }} <strong>{{ selectedTest.name }}</strong>
    </h5>

    <div v-if="selectedLessonLists.length > 0">
      <div
        v-for="lesson of selectedLessonLists"
        :key="lesson.id"
        class="group"
      >
        <b-card
          border-variant="secondary"
          no-body
          class="mb-0 p-2 mb-1"
          role="button"
          @click="!isATeacher && openTestLesson(lesson)"
        >
          <!-- Completed Icon -->
          <img
            v-if="lesson.completed_at"
            :src="require(`@/assets/images/${lessonCompletedBadge}`)"
            class="lesson-completed-badge"
          >
          <!-- Lesson Details -->
          <b-row class="align-items-start justify-content-left">
            <b-col
              md="2"
              sm="3"
              class="mb-1 mb-md-0"
            >
              <!-- Lesson Image -->
              <avatar
                :image="lesson.image"
                :rounded="true"
                :default-icon="'book'"
                size="60px"
              />
            </b-col>

            <!-- Details -->
            <b-col
              :md="10"
              :sm="8"
              class="d-flex justify-content-between"
            >
              <div :set="progressInfo = getProgressBarInfo(lesson)">
                <h4>{{ lesson.name }}</h4>
                <span v-if="lesson.loader">
                  {{ progressInfo.percentage }}
                </span>
                <b-progress
                  v-if="lesson.loader"
                  :max="lesson.totalItem"
                  :class="`progress-bar-${progressInfo.variant}`"
                  style="margin-bottom: 5px;width: 40vh;"
                >
                  <b-progress-bar
                    :value="lesson.totalPass"
                    :variant="progressInfo.variant"
                  />
                </b-progress>
                <h6
                  v-if="lesson.startedAt"
                  class="font-italic"
                >
                  {{ $t('started') }} {{ formatDate(lesson.startedAt) }}
                </h6>
                <h6
                  v-if="lesson.completedAt"
                  class="font-italic text-success"
                >
                  <span v-if="lesson.user_id == self.id">
                    <b>{{ $t('you') }}</b>
                  </span>
                  <!-- <span v-else>
                  <b>{{ lesson.firstname }}</b>
                </span> -->
                  {{ $t('completed') }} {{ formatDate(lesson.completedAt) }}
                </h6>
              </div>
              <div>
                <img
                  v-if="lesson.status === 'passed'"
                  :src="require(`@/assets/images/${lessonCompletedBadge}`)"
                  class="lesson-completed-badge"
                >
                <feather-icon
                  v-else-if="!canStartLesson"
                  icon="LockIcon"
                  class="lesson-completed-badge"
                />
                <b-badge
                  v-if="lesson.round"
                  class="rounded attempt-badge"
                  variant="warning"
                >
                  R{{ lesson.round }}
                </b-badge>
                <b-badge v-if="isATeacher"
                         variant="light-primary"
                         @click="openTestLesson(lesson)"
                >
                  Check Test
                </b-badge>
              </div>
            </b-col>

          </b-row>
        </b-card>
      </div>
    </div>
    <p v-else>
      {{ $t("labels.no-test-lessons") }}
    </p>
  </b-card>
</template>
<script>
import {
  BCard, BCol, BRow, BBadge, BProgress, BProgressBar,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import Avatar from '@/views/common/components/Avatar.vue'
import { LESSON_COMPLETED_BADGE } from '@/const/badges'
import { getLessonProgressColor } from '@/utils/colors'

export default {
  components: {
    BCard,
    Avatar,
    BCol,
    BRow,
    BBadge,
    BProgress,
    BProgressBar,
  },
  props: {
    selectedLessonLists: {
      type: Array,
      default: () => [],
    },
    selectedTest: {
      type: Object,
      default: () => {},
    },
    canStartLesson: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      self: getUserData(),
      lessonCompletedBadge: LESSON_COMPLETED_BADGE,
    }
  },
  computed: {
    classId() {
      return this.roomId
    },
    isATeacher() {
      return this.AUTH_USER().usertype === 'teacher'
    },
  },
  methods: {
    formatDate(date) {
      return moment
        .utc(date)
        .local()
        .fromNow()
    },
    openTestLesson(lesson) {
      if (!this.canStartLesson && lesson.status !== 'passed') {
        this.$swal({
          title: 'Locked',
          text: this.$parent.getClassTestTimeMessage(),
          icon: 'warning',
        })
        return
      }
      this.$emit('openTestLesson', lesson, this.selectedTest.event_id)
      // this.$router.push({
      //   name: `${this.self.usertype}-lesson`,
      //   params: { id: lesson.id, classId: this.classId },
      //   query: { event_id: this.selectedTest.event_id },
      // })
    },
    getProgressBarInfo(lesson) {
      if (lesson.totalItem <= 0) {
        return {
          percentage: 'N/A',
        }
      }
      const percentage = ((lesson.totalPass / lesson.totalItem) * 100).toFixed(2)
      return {
        percentage: `${percentage}%`,
        variant: getLessonProgressColor(percentage),
      }
    },
  },
}
</script>
<style>
.lesson-completed-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}
.attempt-badge {
  position: absolute;
  bottom: 0;
  margin-right: -10px;
  margin-left: -20px;
}
</style>
