<template>
  <b-overlay class="w-100 h-100"
             :show="isProcessing"
  >
    <section
      id="chatbot-body"
      ref="refChatLogPS"
      class="ps-container position-relative scroll-area ps chatbot"
      style="background-repeat:no-repeat; background-size: 100%;"
      :style="{ 'background-image': `url(${require(`@/assets/images/chatbot/${activeImage}`)})`}"
    >
      <!-- <div class="position-absolute setting-icon"
           draggable="false"
           style="right:10px; top:10px; display:none;"
      >
        <feather-icon
          icon="SettingsIcon"
          size="21"
          class="text-primary cursor-pointer"
          @click.stop="openChatbotSetting"
        />
      </div> -->
      <div class="ps__rail-x"
           style="left: 0px; bottom: 0px"
      >
        <div class="ps__thumb-x"
             tabindex="0"
             style="left: 0px; width: 0px"
        />
      </div>
      <div class="ps__rail-y"
           style="top: 0px; right: 0px"
      >
        <div class="ps__thumb-y"
             tabindex="0"
             style="top: 0px; height: 0px"
        />
      </div>
    </section>
    <chat-footer
      :is-processing="isProcessing"
      :is-playing="isPlaying"
      :is-speaking="isSpeaking"
      @sendMessage="sendMessage"
    />
    <b-modal
      id="bot-setting-modal"
      title="Chatbot Settings"
      hide-footer
    >
      <bot-setting />
    </b-modal>
    <audio
      ref="successAudio"
      src="https://assets.mixkit.co/sfx/preview/mixkit-fantasy-game-success-notification-270.mp3"
    />
  </b-overlay>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { BOT_OBJ, IDLE, SPEAKING } from '@/const/chatbot'
import PlayBase64Audio from '@/utils/base64-audio-player'
import useJwt from '@/auth/jwt/useJwt'
import ChatFooter from './ChatFooter.vue'
import BotSetting from './BotSetting.vue'

const synth = window.speechSynthesis
export default {
  components: {
    ChatFooter,
    BotSetting,
  },
  props: {
    userName: {
      type: String,
      required: true,
    },
    lessonInfo: {
      type: Object,
      required: true,
    },
    testStatId: {
      type: Number,
      required: true,
    },
    isSpeaking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      botURl: 'https://president-assitant.vercel.app/abc',
      isProcessing: false,
      speechSynthesisUtterance: null,
      isPlaying: false,
      messages: [],
      isSuccessAudio: false,
    }
  },
  computed: {
    activeImage() {
      return this.isPlaying ? SPEAKING[this.gender] : IDLE[this.gender]
    },
    gender() {
      return this.$store.getters['appConfig/currentChatbotCharacter']
    },
  },
  created() {
    setTimeout(() => {
      this.initializeSpeech()
    }, 2000)
    window.onbeforeunload = () => {
      this.stop()
    }
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    initializeSpeech() {
      this.stop()
      const speechSynthesisUtterance = new SpeechSynthesisUtterance()
      speechSynthesisUtterance.lang = 'en-US'
      this.speechSynthesisUtterance = speechSynthesisUtterance
      speechSynthesisUtterance.addEventListener('end', () => {
        this.isPlaying = false
      })
    },
    pause() {
      if (synth?.speaking) {
        synth.pause()
        this.isPlaying = false
      }
    },
    stop() {
      if (synth?.speaking) {
        synth.cancel()
      }
    },
    async getBase64FromTextAndPlay(message) {
      this.isProcessing = true
      return useJwt.convertTextToBase64({
        text: message,
        gender: this.gender,
      }).then(response => {
        this.play(response.data.audio_file)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    play(base64) {
      this.isPlaying = true
      PlayBase64Audio(base64, () => {
        this.isPlaying = false
        if (this.isSuccessAudio) {
          this.$emit('onSuccess')
        }
      })
    },

    // eslint-disable-next-line no-unused-vars
    sendMessage(msg) {
      this.isProcessing = true
      useJwt.checkConversationalModeProblem(this.testStatId, {
        user_input: msg,
      }).then(async response => {
        await this.getBase64FromTextAndPlay(response.data.data.content)
        if (response.data.data.passed) {
          this.$refs.successAudio.volume = 0.05
          this.$refs.successAudio.play()
          this.isSuccessAudio = true
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    openChatbotSetting() {
      this.$bvModal.show('bot-setting-modal')
    },
  },
}
</script>
<style lang="scss">
  .chatbot {
    height: 150px;
    width: 150px;
    margin:auto;

    &:hover{
      .setting-icon {
        display: block !important;
      }
    }
    /* overflow: scroll !important; */
  }
</style>
