<template>
  <b-modal
    id="modal-1"
    ref="courseCompletionModal"
    :hide-footer="true"
    :hide-header="true"
    size="xl"
    @close="$emit('courseCompletionModalClosed')"
    @hide="$emit('courseCompletionModalClosed')"
  >
    <b-overlay :show="isProcessing">
      <div>
        <ExportReport :follow-up="followUps"
                      :in-progress="inProgress"
        />
      </div>
      <b-tabs>
        <b-tab>
          <template #title>
            <span>{{ $t('student-report-module.student-table.inprogress-tab') }}</span>
            <b-badge
              variant="warning"
              class="rounded-circle count-badges"
            >
              {{ inProgress.length }}
            </b-badge>
          </template>
          <course-completion-by-status
            :is-processing="isProcessing"
            :rows="inProgress"
            :class-id="classId"
            type="in-progress"
            :selected-student-id="selectedStudentId"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <span>
              {{ $t('student-report-module.student-table.completed-tab') }}
            </span>
            <b-badge
              variant="success"
              class="rounded-circle count-badges"
            >
              {{ completed.length }}
            </b-badge>
          </template>
          <course-completion-by-status
            :is-processing="isProcessing"
            :rows="completed"
            :class-id="classId"
            type="completed"
            :selected-student-id="selectedStudentId"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <span>
              {{ $t('student-report-module.student-table.follow-up-tab') }}
            </span>
            <b-badge
              variant="danger"
              class="rounded-circle count-badges"
            >
              {{ followUps.length }}
            </b-badge>
          </template>
          <course-completion-by-status
            :is-processing="isProcessing"
            :rows="followUps"
            :class-id="classId"
            :event-id="eventId"
            type="follow_up"
            :selected-student-id="selectedStudentId"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <span>
              {{ $t('student-report-module.voca-lesson') }}
            </span>
          </template>
          <VocabLessonReportByStatus
            :class-id="classId"
            :selected-student-id="selectedStudentId"
            type="vocab-lesson"
          />
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-modal>

</template>
<script>
import {
  BTabs, BTab, BOverlay, BBadge,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import CourseCompletionByStatus from './CourseCompletionByStatus.vue'
import VocabLessonReportByStatus from './VocabLEssonReportByStatus.vue'
import ExportReport from './ExportReport.vue'

export default {
  components: {
    BTabs,
    BTab,
    CourseCompletionByStatus,
    VocabLessonReportByStatus,
    BOverlay,
    BBadge,
    ExportReport,
  },
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: Number,
      required: true,
    },
    selectedStudentId: {
      type: [Number, Object, String],
      default: () => null,
    },
    // eslint-disable-next-line vue/require-default-prop
    eventId: {
      type: Number,
    },
  },
  data() {
    return {
      rows: [],
      isProcessing: false,
    }
  },
  computed: {
    inProgress() {
      return this.rows.filter(s => !s.completed_at && this.isPracticeType(s.type))
    },
    completed() {
      return this.rows.filter(s => s.completed_at && this.isPracticeType(s.type))
    },
    followUps() {
      return this.rows.filter(s => s.type === 'follow_up')
    },
  },
  watch: {
    toggle(value) {
      if (value === true) {
        this.showModal()
        this.getRowDetails()
      } else {
        this.hideModal()
      }
    },
  },
  methods: {
    isPracticeType(type) {
      return ['homework', 'scorm'].includes(type)
    },
    getRowDetails() {
      this.isProcessing = true
      useJwt.getClassStudentReport(this.classId, this.selectedStudentId).then(response => {
        this.rows = response.data.data.report.map(report => ({
          lessonName: report.lesson_name,
          status: report.status,
          percentage: Math.round(report.percent_score, 2),
          cefr_rate: '',
          lessonId: report.lesson_id,
          cefr_history: report.cefr_rate,
          completed_at: report.completed_date,
          points: report.points,
          totalPass: report.passed ?? 0,
          created_at: report.started_date,
          is_first_done: report.is_first_done,
          totalLesson: report.total_problems,
          type: report.type,
          event_id: report.event_id,
          loader_id: report.loader_id,
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    showModal() {
      this.$refs.courseCompletionModal.show()
    },
    hideModal() {
      this.$refs.courseCompletionModal.hide()
    },
  },
}
</script>
<style scoped>
  .count-badges {
    zoom: 1.1;
    margin-left:5px
  }
</style>
