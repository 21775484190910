<template>
  <div class="marquee-container">
    <div class="marquee">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Marquee',
}
</script>

  <style scoped>
  .marquee-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 30s linear infinite;
    /* font-size: 24px;
    font-weight: bold;*/
  }

  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  </style>
