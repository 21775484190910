<template>
  <b-modal
    id="share-modal"
    :title="typeof lessonId === 'number' ? 'Share Lesson' : 'Share Lessons'"
    ok-title="Share"
    @ok="handleSubmit"
    @hidden="clearModal"
  >
    <b-overlay :show="isProcessing">
      <h4>{{ typeof lessonId === 'number' ? 'Share Lesson to:' : 'Share Lessons to:' }}</h4>
      <validation-observer ref="shareLessons">
        <b-form
          @submit.prevent="handleSubmit"
        >
          <b-form-group label="Course">
            <validation-provider
              v-slot="{errors}"
              name="Course"
              rules="required"
              vid="course_id"
            >
              <b-form-select
                v-model="course_id"
                :options="getCourses"
                @change="(id) => getLessonGroups(id)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Lesson Group">
            <validation-provider
              v-slot="{errors}"
              name="Lesson Group"
              rules="required"
              vid="lesson_group_id"
            >
              <b-form-select
                v-model="lesson_group_id"
                :options="lessonGroups"
                :disabled="!lessonGroups.length"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
    lessonId: {
      type: [Number, Array],
      default: () => null,
    },
    currentCourse: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      course_id: null,
      lesson_group_id: null,
      lessonGroups: [],
      isProcessing: false,
    }
  },
  computed: {
    getCourses() {
      if (this.courses) {
        const courseList = this.courses.filter(c => c.id !== this.currentCourse)
          .map(course => ({
            value: course.id,
            id: course.id,
            lesson_groups: course.lesson_groups,
            text: course.name,
          }))
        courseList.unshift({ value: null, text: 'Please Select a Course', disabled: true })
        return courseList
      }
      return []
    },
  },
  methods: {
    getLessonGroups(id) {
      this.lesson_group_id = null
      this.lessonGroups = this.courses[this.courses.findIndex(e => e.id === id)].lesson_groups.map(e => ({
        value: e.id, id: e.id, lessons: e.lessons, text: e.name,
      }))
      this.lessonGroups.unshift({ value: null, text: 'Please Select a Lesson Group', disabled: true })
    },
    handleSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.shareLessons.validate().then(success => {
        const params = {
          lesson_group_id: this.lesson_group_id,
          lesson_id: this.lessonId,
        }

        if (success) {
          this.isProcessing = true
          useJwt.storeSharedLessons(params).then(response => {
            this.showSuccessMessage(response)
          }).finally(() => {
            this.$bvModal.hide('share-modal')
            this.$emit('shared', 'selected_id', null)
            this.isProcessing = false
          })
        }
      })
    },
    clearModal() {
      this.course_id = null
      this.lesson_group_id = null
    },
  },
}
</script>
