<template>
  <div>
    <b-overlay
      :show="isProcessing"
      rounded="sm"
    >
      <b-card no-body>
        <div class="p-1">
          <b-row class="px-1 align-items-center">
            <b-col
              class="px-0  mb-md-0"
              :class="!notScormLesson ? 'mb-1' : ''"
            >
              <strong
                class="text-primary"
              >{{ $t('course-completion-module.lesson-name') }}:
                <span
                  role="button"
                  @click="redirectToLesson(lessonIds[0])"
                >{{
                  lesson.name || lessonName
                }}</span></strong>
            </b-col>
            <b-col
              :md="!notScormLesson ? 3 : 1"
              :sm="!notScormLesson ? 12 :''"
              class=" px-0"
              :class="!notScormLesson ? 'd-flex' : 'col-1'"
            >
              <select v-if="!notScormLesson"
                      v-model="filterScormBy"
                      class="form-control"
                      @change="filterScormReport"
              >
                <option value="first-score">
                  {{ $t('filter-by-first-score') }}
                </option>
                <option value="max-score">
                  {{ $t('filter-by-max-score') }}
                </option>
                <option value="latest-score">
                  {{ $t('filter-by-latest-score') }}
                </option>
              </select>

              <span
                class="float-right"
              >
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="link"
                  toggle-class="text-decoration-none px-0"
                  no-caret
                  right
                  class="pr-0 pl-0"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-left vertical-icon"
                    />
                  </template>

                  <b-dropdown-item
                    v-b-tooltip.hover="'Upload Lesson Template'"
                    @click="exportExcel()"
                  >

                    <feather-icon icon="FileTextIcon" /> {{ $t('course-completion-module.excel-export') }}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item>
                    <strong>{{ $t('course-completion-module.total-problems') }}</strong>:
                    <b-badge variant="light-secondary">
                      {{ lesson.totalProblems || 0 }}
                    </b-badge>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <strong>{{ $t('course-completion-module.total-student') }}</strong>:
                    <b-badge
                      v-if="loaders.length"
                      variant="light-secondary"
                    >
                      {{ loaders.length }}
                    </b-badge>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
            <!-- <b-col md="6">
              <div class="d-flex justify-content-end">
                <div>
                  <strong>{{ $t('course-completion-module.total-problems') }}</strong>:
                  <b-badge variant="light-secondary">
                    {{ lesson.totalProblems || 0 }}
                  </b-badge>
                </div>
              </div>
              <div
                class="d-flex justify-content-end"
                style="margin-top: 5px"
              >
                <div>
                  <strong>{{ $t('course-completion-module.total-student') }}</strong>:
                  <b-badge
                    v-if="loaders.length"
                    variant="light-secondary"
                  >
                    {{ loaders.length }}
                  </b-badge>
                </div>
              </div>
            </b-col> -->
          </b-row>
        </div>
        <b-table-simple
          hover
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>
                {{ $t('course-completion-module.table-header.student-name') }}
              </b-th>
              <b-th>
                {{ notScormLesson ? $t('course-completion-module.table-header.problem') : $t('course-completion-module.table-header.completion-rate') }}
              </b-th>
              <b-th v-if="notScormLesson">
                {{ $t('course-completion-module.table-header.cefr-rate') }}
              </b-th>
              <b-th v-if="notScormLesson">
                {{ $t('course-completion-module.table-header.lang-level') }}
              </b-th>
              <b-th>
                {{ $t('course-completion-module.table-header.points') }}
              </b-th>
              <b-th>
                {{ $t('course-completion-module.table-header.time-spent') }}
              </b-th>
              <b-th>
                {{ $t('course-completion-module.table-header.status') }}
              </b-th>
              <b-th>
                {{ $t('course-completion-module.table-header.completed-at') }}
              </b-th>
              <b-th>
                {{ $t('course-completion-module.table-header.actions') }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td v-if="loaders.length == 0 && !isProcessing"
                    colspan="8"
                    class="text-center"
              >
                {{ $t('student-report-module.student-have-not-attempt') }}
              </b-td>
            </b-tr>
            <b-tr
              v-for="(loader, index) in loaders"
              :key="index"
            >
              <b-td>
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="UserIcon"
                    size="17"
                  />
                  <span class="ml-1">
                    {{ loader.fullname }}
                    <img
                      v-if="highestPointUser == loader.id"
                      v-b-tooltip.hover.top="'Highest Points'"
                      class="top-badge"
                      :src="
                        require(`@/assets/images/${highestPointGainerBadge}`)
                      "
                      height="20"
                    >
                    <img
                      v-if="quickestUser == loader.id"
                      v-b-tooltip.hover.top="'First Lesson achiever'"
                      class="top-badge"
                      :src="
                        require(`@/assets/images/${firstLessonAchieverBadge}`)
                      "
                      height="20"
                    >
                  </span>
                </div>
              </b-td>
              <b-td v-if="notScormLesson"
                    class="text-nowrap"
              >
                <!-- Failed -->
                <b-badge
                  :id="`failedProblemCount-${index}`"
                  variant="danger"
                  :style="{ padding: '6px' }"
                  class="text-white mr-1"
                >
                  {{ loader.totalFail }}
                </b-badge>
                <b-tooltip
                  :target="`failedProblemCount-${index}`"
                  triggers="hover"
                >
                  Problem Failed
                </b-tooltip>
                <!-- Failed -->

                <!-- Passed -->
                <b-badge
                  :id="`passedProblemCount-${index}`"
                  variant="success"
                  :style="{ padding: '6px' }"
                  class="text-white mr-1"
                >
                  {{ loader.totalPass }}
                </b-badge>
                <b-tooltip
                  :target="`passedProblemCount-${index}`"
                  triggers="hover"
                >
                  Problem Passed
                </b-tooltip>
                <!-- Passed -->
              </b-td>
              <b-td v-else>
                {{ getScormCompetitionScore(loader) }}
              </b-td>
              <b-td v-if="notScormLesson">{{
                loader.cefrRate === "NaN" ? "0.00%" : loader.cefrRate
              }}</b-td>
              <b-td v-if="notScormLesson">
                <span
                  class="text-white badge"
                  :style="{ background: loader.cefrColor, padding: '6px' }"
                >{{
                  loader.cefrLevel === "invalid" ? "-" : getLangLevelTransText(loader.cefrLevel)
                }}</span></b-td>
              <b-td>
                {{ loader.points }}
              </b-td>
              <b-td>{{ loader.timeSpent }}</b-td>
              <b-td>
                <span>
                  <b-badge :variant="getStatusVariant(loader.status)">
                    {{ $t(`course-completion-module.${loader.status}`) }}
                  </b-badge>
                </span>
              </b-td>
              <b-td>
                {{ FROM_NOW(loader.completedAt) }}
              </b-td>
              <b-td
                class="d-flex"
              >
                <template v-if="notScormLesson">
                  <b-button
                    :id="`stat-report-${index}`"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.stop="$emit('showStatReport', loader, lessonIds[0])"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.stop="studentIdToViewEssay = Number(loader.id)"
                  >
                    <feather-icon icon="BookOpenIcon" />
                  </b-button>
                  <b-tooltip
                    :target="`stat-report-${index}`"
                    triggers="hover"
                  >
                    {{ $t('actions.view-statistics') }}
                  </b-tooltip>
                </template>
                <template v-else>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.stop="loaderToShowScormRound = loader"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </template>
              </b-td>
            </b-tr>
          </b-tbody>

        </b-table-simple>
      </b-card>
    </b-overlay>
    <ScormRoundReport
      v-if="!!loaderToShowScormRound"
      :loader="loaderToShowScormRound"
      @close="loaderToShowScormRound = null"
    />
    <lesson-essay-modal
      :show="!!studentIdToViewEssay"
      :lesson-id="lessonIds.length && Number(lessonIds[0])"
      :student-id="studentIdToViewEssay"
      @close="studentIdToViewEssay = null"
    />
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCard,
  BBadge,
  BTooltip,
  BButton,
  BOverlay,
  BRow,
  BCol,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import useApollo from '@/@core/graphql/useApollo'
import moment from 'moment'
import { getColorForLevel, getLevelFromScore } from '@/const/langLevel'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT, USER_TYPE_TEACHER, USER_TYPE_PARENT } from '@/const/userType'
import {
  HIGHEST_POINTS_GAINER_BADGE,
  FIRST_LESSON_ACHEIVER_BADGE,
} from '@/const/badges'
import exportFromJSON from 'export-from-json'
import useJwt from '@/auth/jwt/useJwt'
import LessonEssayModal from '../components/LessonEssayModal.vue'
import ScormRoundReport from './scorm/round.vue'

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BCard,
    BBadge,
    BTooltip,
    BButton,
    BOverlay,
    LessonEssayModal,
    ScormRoundReport,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    roomId: {
      type: Number,
      default: () => 0,
    },
    lessonIds: {
      type: Array,
      default: () => [],
    },
    activeCourseId: {
      type: Number,
      default: null,
    },
    viewMode: {
      type: String,
      default: () => 'course',
    },
    lessonName: {
      type: String,
      default: '',
    },
    classTestId: {
      type: Number,
      default: 0,
    },
    loaderType: {
      type: String,
      default: 'homework',
    },
  },
  data: () => ({
    room: {},
    loaders: [],
    isProcessing: false,
    lesson: {},
    highestPointUser: null,
    quickestUser: null,
    highestPointGainerBadge: HIGHEST_POINTS_GAINER_BADGE,
    firstLessonAchieverBadge: FIRST_LESSON_ACHEIVER_BADGE,
    self: getUserData(),
    studentIdToViewEssay: null,
    lessonType: 'normal',
    loaderToShowScormRound: null,
    filterScormBy: 'latest-score',
  }),
  computed: {
    notScormLesson() {
      return this.lessonType !== 'scorm'
    },
    isAStudent() {
      return this.self && this.self.usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return this.self && this.self.usertype === USER_TYPE_PARENT
    },
    isATeacher() {
      return this.self && this.self.usertype === USER_TYPE_TEACHER
    },
  },
  watch: {
    lessonIds(value) {
      if (!value.length) {
        this.fetchLessonIds().then(ids => {
          this.fetchReport(ids)
        })
      } else this.fetchReport(this.lessonIds)
      this.getLessonTags()
    },
  },
  created() {
    if (!this.lessonIds?.length) {
      this.fetchLessonIds().then(ids => {
        this.fetchReport(ids)
      })
    } else this.fetchReport(this.lessonIds)
    this.getLessonTags()
  },
  methods: {
    getStatusVariant(status) {
      switch (status) {
        case 'passed':
          return 'success'
        case 'failed':
          return 'danger'
        default:
          return 'primary'
      }
    },
    calcVariant(loader) {
      let status = 'danger'
      if (loader?.status === 'passed') status = 'success'
      else if (loader?.status === 'in_progress') status = 'warning'
      return status
    },
    redirectToLesson(id) {
      this.$router.replace({
        name: `${getUserData().usertype}-lesson`,
        params: { classId: this.roomId, id },
      })
    },
    calcAverage(numbers) {
      const sum = numbers.reduce((a, b) => a + b, 0)
      const avg = (sum / numbers.length).toFixed(2) || 0
      return avg
    },
    cefrLevel(value) {
      return getLevelFromScore(value)
    },
    addTime(times) {
      let sum = '00:00:00'
      times.forEach(t => {
        const d = moment.duration(sum).add(moment.duration(t))
        sum = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
      })
      return sum
    },
    fetchLessonIds() {
      return new Promise(resolve => {
        useApollo
          .fetchLessonIds(this.roomId, this.activeCourseId)
          .then(response => {
            let lessonIds = []
            if (response?.data?.room?.courses[0]?.lessons?.length) lessonIds = response.data.room.courses[0].lessons.map(l => parseInt(l.id, 10))
            resolve(lessonIds)
          })
      })
    },
    fetchReport(lessonIds) {
      this.fetchCourseReportByGroup(lessonIds)
    },
    filterScormReport() {
      const { loaders } = this
      const filterScorm = []
      loaders.forEach(item => {
        const filter = {}
        if (this.filterScormBy === 'first-score') {
          let data = item.history.filter(i => i.sessionKey === item.history[0].sessionKey)
          data = data[data.length - 1]
          filter.points = data.points
          filter.maxScore = data.max_score
          filter.completedAt = data.completed_at
          filter.timeSpent = this.SECOND_TO_TIME(data.time)
        } else if (this.filterScormBy === 'max-score') {
          const maxScore = Math.max(...item.history.map(i => Number(i.points)))
          const data = item.history.find(i => Number(i.points) === maxScore)
          filter.points = data.points
          filter.maxScore = data.max_score
          filter.completedAt = data.completed_at
          filter.timeSpent = this.SECOND_TO_TIME(data.time)
        } else {
          const data = item.history[item.history.length - 1]
          filter.points = data.points
          filter.maxScore = data.max_score
          filter.completedAt = data.completed_at
          filter.timeSpent = this.SECOND_TO_TIME(data.time)
        }

        filterScorm.push({
          ...item,
          ...filter,
        })
      })
      this.loaders = filterScorm
    },
    fetchCourseReportByGroup(lessonIds) {
      this.isProcessing = true
      useJwt.getCalibratedClassLessonReport(this.roomId, lessonIds[0], {
        params: {
          loaderType: this.loaderType,
          classTestId: this.classTestId > 0 ? this.classTestId : null,
        },
      }).then(response => {
        this.loaders = response.data.data.map(item => ({
          id: item.user_id,
          fullname: item.student_name,
          cefrLevel: item.lang_level,
          cefrRate: item.cefr_rate || 0,
          cefrColor: getColorForLevel(item.lang_level),
          timeSpent: item.time_spent,
          totalFail: item.total_failed_problems || 0,
          totalPass: item.total_passed_problems || 0,
          completedAt: item.completed_at,
          points: item.points,
          status: item.status,
          history: item.history || [],
          maxScore: item.history?.[item.history?.length - 1]?.max_score || null,
        }))
        const sortBy = key => {
          let loaders = [...this.loaders]
          loaders = loaders.filter(
            loader => loader.completedAt !== '-' && loader.completedAt !== null,
          )
          if (key === 'completedAt') {
            loaders.sort((a, b) => +new Date(a[key]) - +new Date(b[key]))
          }
          loaders.sort((a, b) => b[key] - a[key])
          return loaders[0]?.id
        }

        if (!this.isAStudent) {
          this.$nextTick(() => {
            this.highestPointUser = sortBy('points')
            this.quickestUser = sortBy('completedAt')
          })
        }
        this.lesson = {
          totalProblems: Number(this.loaders[0]?.totalPass) + Number(this.loaders[0]?.totalFail),
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
    exportExcel() {
      const data = this.loaders.map(loader => ({
        FullName: this.titleCase(loader.fullname),
        'Lesson Name': this.lessonName,
        'Passed Problems': loader.totalPass ?? 0,
        'Failed Problems': loader.totalFail ?? 0,
        ...(loader.cefrRate !== undefined ? {
          'CEFR Rate': loader.cefrRate ?? '-',
          'CEFR Level': loader.cefrLevel ?? '-',
        } : {}),
        'Time Spent': loader.timeSpent ?? '-',
        'Completed At': loader.completedAt ?? '--',
        Points: loader.points,

      }))
      const fileName = `lesson-reports of ${this.lessonName}`
      const exportType = 'csv'

      exportFromJSON({ data, fileName, exportType })
    },
    getLessonTags() {
      useJwt.getLessonsTags(this.lessonIds[0])
        .then(response => {
          const tags = response.data.data
          const array = tags.split(',').map(item => item?.toLowerCase())
          if (array.includes('scorm')) {
            this.lessonType = 'scorm'
          } else {
            this.lessonType = 'normal'
          }
        }).catch(error => {
          console.log(error)
        })
    },
    getScormCompetitionScore(loader) {
      console.log(loader.maxScore)
      if (!loader.maxScore) {
        return '-'
      }
      // const lastScore = history[history.length - 1]
      // if (!lastScore || !lastScore.max_score) {
      //   return '-'
      // }
      return (+loader.points / loader.maxScore) * 100
    },
  },
}
</script>
