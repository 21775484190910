<template>
  <div>
    <span>{{ $t("send-notification-module.select-class") }}</span>
    <b-form-select
      v-model="selected"
      style="margin-top:6px;"
      :options="options"
      @input="(s)=>$emit('selected',s)"
    />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: { BFormSelect },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      classes: [],
      self: getUserData(),
      options: [
        {
          value: null, text: this.$i18n.tc('actions.all'),
        },
      ],
    }
  },
  computed: {
    classId() {
      return this.classRoomId ? this.classRoomId : this.$route.query.classId
    },
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    getClasses() {
      useJwt.getClasses({ params: { user_id: this.self.id } }).then(res => {
        const classes = res.data.data
        classes.forEach(e => {
          this.options.push({ value: e.id, text: e.name })
        })
        if (this.classId) {
          this.selected = this.classId
        }
      })
    },
  },
}
</script>

<style>

</style>
