<template>
  <b-row>
    <b-col
      md="12"
    >
      <div class="py-1 bg-white mb-1 rounded">
        <!-- Back to Courses -->
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="$router.push({name: `${self.usertype}-vocab-problem`, params: { classId }})"
        >
          <span> &#8701;{{ $t('student-lab-module.courses') }}</span>
        </b-button>
        |
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="$router.push({ name: self.usertype +'-classes' })"
        >
          {{ $t('student-lab-module.classroom-list') }}
        </b-button>
        |
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="$router.push({
            name: `${self.usertype}-class-calendar`,
            params: { id: $route.params.classId },
          })"
        >
          {{ $t('student-lab-module.calendar') }}
        </b-button>
      </div>
    </b-col>
    <b-col md="12">
      <b-overlay :show="isProcessing">
        <b-card v-if="result"
                class="text-center"
        >
          <b-card-body>
            <b-row>
              <b-col sm="12"
                     md="6"
                     class="mt-2 sm-mt-0 d-flex flex-column justify-content-end pl-0"
              >
                <h1 class="mb-1 mt-50">
                  Lesson Completed
                </h1>
                <h5 class="card-text">
                  <strong>{{ self.firstname }} {{ self.lastname }}</strong> has completed lesson
                </h5>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-around flex-wrap mt-4">
              <b-col class="text-center">
                <p class="card-text">
                  Total Problem
                </p>
                <span class="font-large-1 font-weight-bold">
                  {{ result.total }}
                </span>
              </b-col>
              <b-col class="text-center">
                <p class="card-text">
                  Time Spent
                </p>
                <span class="font-large-1 font-weight-bold">
                  {{ SECOND_TO_TIME(result.time_spent) }}
                </span>
              </b-col>
              <b-col class="text-center">
                <p class="card-text">
                  Pass Percentage
                </p>
                <span class="font-large-1 font-weight-bold">
                  {{ number_format(result.pass_percent, 2) }}
                </span>
              </b-col>
              <b-col class="text-center">
                <p class="card-text">
                  Passed Problems
                </p>
                <span class="font-large-1 font-weight-bold text-success">
                  {{ result.pass }}
                </span>
              </b-col>
              <b-col class="text-center">
                <p class="card-text">
                  Failed Problems
                </p>
                <span class="font-large-1 font-weight-bold text-danger">
                  {{ result.fail }}
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12"
                     class="mt-3"
              >
                <table class="table table-hover table-striped table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td class="bg-success text-white col-1">
                        Passed
                      </td>
                      <td>
                        {{ result.passedWord.map(i => i.word).join(',') }}
                      </td>
                    </tr>
                    <tr>
                      <td class="bg-danger text-white col-1">
                        Failed
                      </td>
                      <td>
                        {{ result.failedWord.map(i => i.word).join(',') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BOverlay, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BOverlay,
    BButton,
  },
  data() {
    return {
      classRoomInfo: null,
      result: null,
      isProcessing: false,
      self: this.AUTH_USER(),
    }
  },
  computed: {
    classId() {
      return this.$route.params.classId
    },
    eventId() {
      return this.$route.params.eventId
    },
  },
  mounted() {
    this.getClassRoomInfo()
  },
  methods: {
    getClassRoomInfo() {
      useApollo.getClassRoomInfo(this.classId).then(response => {
        this.classRoomInfo = response.data.room
        this.getVocabLessonReport()
      })
    },
    getVocabLessonReport() {
      this.isProcessing = true
      useJwt.getVocabLessonReport(this.eventId).then(response => {
        this.result = response.data.data.result
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>

<style scoped>
    .font-large-1 {
    font-size: 1.5rem;
    }
    .font-weight-bold {
    font-weight: bold;
    }
    .mt-50 {
    margin-top: 50px;
    }
</style>
