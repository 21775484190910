import useJwt from '@/auth/jwt/useJwt'
import { ref, getCurrentInstance } from 'vue'
import router from '@/router'

export default function useLessonDashboard() {
  const today = ref(null)
  const studentAssignment = ref([])
  const classRoomAssignment = ref([])
  const hasNoAssignment = ref(false)
  const statisticsData = ref(null)
  const followUpAssignment = ref([])
  const classTestAssignment = ref([])
  const isProcessing = ref(false)
  const studentInfo = ref()
  const instance = getCurrentInstance().proxy

  const selectedAssignmentType = JSON.parse(localStorage.getItem('assignmentType') || '{}')

  const assignmentType = ref({
    student: selectedAssignmentType?.student ?? true,
    classRoom: selectedAssignmentType?.classRoom ?? false,
    followUp: selectedAssignmentType?.followUp ?? false,
    classTest: selectedAssignmentType?.classTest ?? false,
  })
  const getDashboard = () => {
    isProcessing.value = true
    const studentId = router?.currentRoute?.params.id ?? ''
    const params = {}
    if (studentId) {
      params.student_id = studentId
    }
    useJwt.getStudentTableData('lesson-dashboard', { params }).then(response => {
      today.value = response.data.data.today
      const { events, student } = response.data.data
      const arrangedEvents = [
        ...events.filter(i => !i.is_locked),
        // ...events.filter(i => i.is_locked),
      ]
      studentInfo.value = student
      hasNoAssignment.value = !arrangedEvents.length && !response.data.data.class_tests.length
      studentAssignment.value = arrangedEvents.filter(i => i.assignment_type === 'private' && i.event_type !== 'follow_up')
      classRoomAssignment.value = arrangedEvents.filter(i => i.assignment_type === 'public' && i.event_type !== 'follow_up')
      followUpAssignment.value = arrangedEvents.filter(i => i.event_type === 'follow_up')
      const classTests = response.data.data.class_tests
      classTestAssignment.value = [
        ...classTests.filter(i => !i.is_locked && (!i.current_loader || !i.current_loader.completed_at)),
        // ...classTests.filter(i => i.is_locked),
      ]
      classTestAssignment.value = classTests
      // statisticsData.value = response.data.data.statistics
    }).finally(() => {
      isProcessing.value = false
      // const type = assignmentTypeValue.value
      // if (type === 'studentAssignment') {
      //   assignments.value = studentAssignment.value
      // } else if (type === 'classRoomAssignment') {
      //   assignments.value = classRoomAssignment.value
      // } else if (type === 'followUpAssignment') {
      //   assignments.value = followUpAssignment.value
      // } else if (type === 'classTestAssignment') {
      //   assignments.value = classTestAssignment.value
      // } else {
      //   assignments.value = [] // Fallback case if the type is not matched
      // }
    })
  }

  // const assignmentTypeValue = ref('studentAssignment')
  // const assignments = ref([])
  // const getAssignmentByType = type => {
  //   switch (type) {
  //     case 'studentAssignment':
  //       assignments.value = studentAssignment.value
  //       break
  //     case 'classRoomAssignment':
  //       assignments.value = classRoomAssignment.value
  //       break
  //     case 'followUpAssignment':
  //       assignments.value = followUpAssignment.value
  //       break
  //     case 'classTestAssignment':
  //       assignments.value = classTestAssignment.value
  //       break
  //     default:
  //       assignments.value = []
  //   }
  // }
  // const getDefaultAssignments = () => {
  //   getAssignmentByType(assignmentTypeValue.value)
  // }

  const storeSelectedAssignmentType = () => {
    console.log(assignmentType.value)
    localStorage.setItem('assignmentType', JSON.stringify(assignmentType.value))
  }

  const handleCheckboxChange = key => {
    const selectedCount = Object.values(assignmentType.value).filter(Boolean).length
    if (selectedCount === 0) {
      // Revert the change if it's the last selected checkbox
      assignmentType.value[key] = true
      instance.showErrorMessage('You must select at least one.')
    } else {
      storeSelectedAssignmentType()
    }
  }
  const getStudentStatisticData = () => {
    isProcessing.value = true
    const studentId = router?.currentRoute?.params.id ?? ''
    const params = {}
    if (studentId) {
      params.student_id = studentId
    }
    useJwt.getStudentTableData('student-statistic-data', { params }).then(response => {
      today.value = response.data.data.today
      statisticsData.value = response.data.data
    }).finally(() => {
      isProcessing.value = false
    })
  }
  return {
    getDashboard,
    isProcessing,
    today,
    studentAssignment,
    classRoomAssignment,
    followUpAssignment,
    hasNoAssignment,
    statisticsData,
    classTestAssignment,
    studentInfo,
    assignmentType,
    handleCheckboxChange,
    getStudentStatisticData,
    // assignmentTypeValue,
    // assignments,
    // getAssignmentByType,
    // getDefaultAssignments,
  }
}
