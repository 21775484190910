<template>
  <div>
    <h5>
      {{ $t('courses-module.skill-weightage') }}
    </h5>
    <b-row>
      <b-col
        v-for="skill of skillType"
        :key="skill.value"
      >
        <b-form-group :label="skill.text">
          <b-form-input
            v-model="value[skill.value]"
            :placeholder="skill.text"
            step="any"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import SKILL_TYPE from '@/const/skillTypes'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      skillType: SKILL_TYPE.filter(skill => skill.value),
    }
  },
}
</script>
