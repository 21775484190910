<template>
  <b-row>
    <b-col
      md="12"
    >
      <!-- classRoomId is used for back if full page view -->
      <template v-if="!classRoomId">
        <div
          v-if="$route.params.classId"
          class="mx-0 calendar-breadcrumb mb-1 rounded"
          style="padding-top: 5px; padding-bottom: 5px;"
        >
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="GO_BACK()"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
          <span>
            <span class="text-capitalize">{{ className+" " }}</span>
          </span>
        </div>
        <div
          v-else
          style="width: 420px;"
        >
          <v-select
            v-model="classId"
            :placeholder="$t('send-notification-module.select-class')"
            class="w-100"
            label="name"
            :reduce="item => Number(item.id)"
            :options="classrooms"
            :clearable="false"
            @input="getEventData"
          />
        </div>
      </template>

      <div v-if="canEditTemplate"
           class="my-1 d-flex"
      >
        <b-button
          variant="outline-success"
          class="mr-1 mb-1"
          @click="showLessonPlans = true"
        >
          {{ $t('calendar-module.my-lesson-plans') }}
        </b-button>
        <b-button
          variant="outline-primary"
          class="mr-1 mb-1"
          @click="showTemplateCreate = true"
        >
          {{ $t('calendar-module.manage-template') }}
        </b-button>

        <v-select
          v-model="selectedStudent"
          :placeholder="$t('send-notification-module.select-class')"
          class=""
          style="width: 300px"
          label="label"
          :reduce="item => item.value"
          :options="filterStudent"
          :clearable="false"
          @input="getFilteredEventData"
        />
        <p class="text-muted mb-0 ml-1"
           style="font-size: 13px; font-style: italic;"
        >
          <feather-icon icon="InfoIcon" />
          {{ $t('private-public-assignment-info') }}
        </p>
      </div>
    </b-col>
    <b-col md="12">
      <div class="d-md-flex flex-wrap col-md-12 calendar-filter mb-1 rounded pt-1">
        <div class="mr-1 sm-pb-1">
          {{ $t('calendar-module.filters') }}:
        </div>
        <div v-for="et,index of eventTypes.filter(i => availableFilters.includes(i.value) || !i.value)"
             :key="index"
             class="pb-2 text-nowrap mr-2 sm-pb-1 sm-display"
        >
          <button
            type="button"
            class="btn btn-icon rounded-circle"
            :class="et.value === selectedEventType ? et.variant.replace('-outline', '') : et.variant"
            @click="filterEventList(et.value)"
          />
          <span class="ml-1">{{ et.label }}</span>
        </div>
      </div>
    </b-col>

    <b-col md="12">
      <div class="app-calendar border">
        <div class="row no-gutters">
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <b-tabs
                  v-model="tabIndex"
                  pills
                >
                  <b-tab
                    :title="$t('calendar-module.calendar-view')"
                  >
                    <b-col>
                      <full-calendar
                        v-if="showFullCalendar"
                        ref="refCalendar"
                        :key="selectedEventType + selectedStudent"
                        :options="calendarOptions"
                        class="full-calendar"
                      >
                        <template #eventContent="arg">
                          <div :id="`popover-button-show-event-${arg.event.id}`"
                               class="fc-event-main-frame"
                          >
                            <div class="fc-event-title-container">
                              <div
                                class="fc-event-title fc-sticky"
                              >
                                <span>{{ arg.event.title }}</span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </full-calendar>
                    </b-col>
                  </b-tab>
                  <b-tab
                    :title="$t('calendar-module.list-view')"
                  >
                    <b-row v-if="!isAStudent">
                      <b-col
                        md="12"
                        class="ml-1"
                      >
                        <b-row>
                          <b-col>
                            <p
                              class="text-muted"
                              style="font-style: italic"
                            >
                              <feather-icon icon="InfoIcon" />
                              {{ $t('calendar-module.bulk-update-info') }}
                            </p>
                          </b-col>
                        </b-row>
                        <b-row
                          style="margin-right: 14px"
                        >
                          <b-col
                            md="5"
                            sm="12"
                          >
                            <b-form-group :label="$t('messages.seachLabel')">
                              <b-form-input
                                v-model="searchTerm"
                                :placeholder="$t('type-to-search')"
                                @input="debouncedFilterTable"
                                @change="filterTable"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="7"
                                 sm="12"
                          >
                            <DateFilter
                              cols="12"
                              :max-date="null"
                              @getFilteredDate="getEventFilteredDate"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="selectedEvents.length> 0 && !isAStudent"
                      class="ml-1"
                    >
                      <b-col
                        md="2"
                        sm="12"
                      >
                        <b-form-group>
                          <label class="mr-1">
                            <feather-icon v-b-tooltip.hover="$t('calendar-module.start-date-info')"
                                          icon="InfoIcon"
                            />
                            {{ $t('class-module.start-date') }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend>
                              <b-button
                                size="sm"
                                variant="danger"
                                :disabled="isProcessing"
                                @click="updateStartDate('minus')"
                              >
                                <feather-icon icon="MinusIcon" />
                              </b-button>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="update_start_date"
                              :placeholder="$t('class-module.start-date')"
                              disabled
                              size="sm"
                            />
                            <b-input-group-append>
                              <b-button
                                :disabled="isProcessing"
                                size="sm"
                                variant="success"
                                @click="updateStartDate('plus')"
                              >
                                <feather-icon icon="PlusIcon" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="2"
                      >
                        <b-form-group>
                          <label
                            class="mr-1"
                          >
                            <feather-icon v-b-tooltip.hover="$t('calendar-module.duration-info')"
                                          icon="InfoIcon"
                            />
                            {{ $t('student-report-module.summary-table.duration') }}</label>
                          <b-input-group size="sm">
                            <b-input-group-prepend>
                              <b-button
                                size="sm"
                                variant="danger"
                                :disabled="isProcessing"
                                @click="updateEndDate('minus')"
                              >
                                <feather-icon icon="MinusIcon" />
                              </b-button>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="update_duration"
                              :placeholder="$t('student-report-module.summary-table.duration')"
                              disabled
                              size="sm"
                            />
                            <b-input-group-append>
                              <b-button
                                :disabled="isProcessing"
                                size="sm"
                                variant="success"
                                @click="updateEndDate('plus')"
                              >
                                <feather-icon icon="PlusIcon" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <b-form-group>
                          <label class="mr-1">
                            <feather-icon v-b-tooltip.hover="$t('calendar-module.schedule-type-change')"
                                          icon="InfoIcon"
                            />
                            {{ $t('calendar-module.schedule-type') }}
                          </label>
                          <v-select
                            v-model="update_schedule_type"
                            placeholder="Select Classroom"
                            :reduce="item => item.value"
                            class="w-100"
                            :options="scheduleType"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        md="3"
                        class="d-flex"
                        :style="'margin-top: 2rem'"
                      >
                        <b-form-checkbox
                          v-model="multiPracticeOnSelectedEvents"
                          :indeterminate="indeterminateMultiPractice"
                          @change="indeterminateMultiPractice = false"
                        />
                        <span>
                          {{ $t('calendar-module.enable-multi-practice') }}
                          <feather-icon
                            v-b-tooltip.hover="getEnableMultiPracticeInfo"
                            icon="InfoIcon"
                          />
                        </span>
                      </b-col>

                      <b-col
                        md="2"
                      >
                        <b-button
                          variant="primary"
                          size="sm"
                          style="margin-top:24px"
                          @click="updateBulkEvent"
                        > {{ $t('labels.update') }}
                        </b-button>
                        <b-button
                          variant="danger"
                          size="sm"
                          class="ml-2"
                          style="margin-top:24px"
                          @click="showBulkDelete = true"
                        > {{ $t('actions.delete') }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-col>
                      <b-overlay :show="isProcessing">
                        <vue-good-table
                          ref="calendarListView"
                          :columns="displayedColumns"
                          :rows="selectedListData"
                          :select-options="{
                            enabled: true,
                            selectOnCheckboxOnly: true,
                          }"
                          :row-class="'event-name-with-select'"
                          style="height: 300px; overflow-y: auto;"
                          @on-selected-rows-change="handleSelectionChange"
                        >
                          <template
                            slot="table-row"
                            slot-scope="props"
                          >
                            <!-- <span
                            v-if="props.column.field === 'select'"
                          >
                            <b-form-checkbox
                              v-model="selectedEvents"
                              :value="props.row.id"
                            />
                          </span> -->
                            <span
                              v-if="props.column.field === 'name'"
                            >
                              <b-link v-if="!props.row.extendedProps.isLocked"
                                      :to="{
                                        name: `${self.usertype}-lesson`,
                                        query: { event_id: props.row.id },
                                        params: { classId, id: props.row.extendedProps.lessonId } }"
                              >
                                {{ props.row.title }}
                              </b-link>
                              <span v-else>
                                {{ props.row.title }}
                              </span>
                              <span
                                v-if="!isAStudent"
                                class="select-icon float-right"
                              >
                                <b-button
                                  variant="flat-primary"
                                  class="btn-icon rounded-circle"
                                  @click.stop="selectFollowingLesson('up', props.index)"
                                >
                                  <feather-icon icon="ArrowUpCircleIcon" />
                                </b-button>
                                <b-button
                                  variant="flat-primary"
                                  class="btn-icon rounded-circle"
                                  @click.stop="selectFollowingLesson('down', props.index)"
                                >
                                  <feather-icon icon="ArrowDownCircleIcon" />
                                </b-button>
                              </span>
                            </span>
                            <span
                              v-if="props.column.field === 'schedule_type'"
                            >
                              <span>{{ $t('calendar-module.'+props.row.extendedProps.schedule_type) }}</span>
                            </span>
                            <span
                              v-else-if="props.column.field === 'start_time'"
                            >
                              <span v-if="isNonSequenced(props.row.extendedProps.schedule_type)">
                                -
                              </span>
                              <span v-else>{{ getComputedStartTime(props.row) }}</span>
                            </span>
                            <span
                              v-else-if="props.column.field === 'end_time'"
                            >
                              <span v-if="isNonSequenced(props.row.extendedProps.schedule_type)">
                                -
                              </span>
                              <p
                                v-else
                                :class="getCheckValidityOfEndTime(props.row)"
                              >
                                {{ getComputedEndTime(props.row) }}
                              </p>
                            </span>
                            <span v-else-if="props.column.field === 'multi-practice'">
                              <b-form-checkbox
                                v-model="props.row.extendedProps.has_multi_practice"
                                switch
                                :value="1"
                                @change="value => toggleMultiPractice(value, props.row.extendedProps)"
                              />
                            </span>
                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </vue-good-table>
                      </b-overlay>
                    </b-col>
                  </b-tab>

                  <template #tabs-end>
                    <div
                      v-if="tabIndex"
                      class="student-search-box"
                    >
                      <b-form-group v-if="isAStudent">
                        <b-form-input
                          v-model="searchTerm"
                          :placeholder="$t('type-to-search')"
                          @input="debouncedFilterTable"
                          @change="filterTable"
                        />
                      </b-form-group>
                    </div>
                  </template>
                </b-tabs>
                <!-- Sidebar Overlay -->
                <div
                  class="body-content-overlay"
                  :class="{'show': isCalendarOverlaySidebarActive}"
                  @click="isCalendarOverlaySidebarActive = false"
                >
                  <calendar-event-handler
                    v-if="canEditTemplate"
                    :key="getEvent().id || getRandomKey()"
                    v-model="isEventHandlerSidebarActive"
                    :event="isolatedEvent ?? getEvent()"
                    :courses="courses"
                    :grades="grades"
                    :all-events-list="allEventsList"
                    :clear-event-data="clearEventData"
                    :room-id="computedClassRoomId"
                    :open-instruction-link="openInstructionLink"
                    :isolated-template="isolatedTemplate"
                    @remove-event="removeEvent"
                    @add-event="(e, fn)=> { eventAction(e, fn, 'add') }"
                    @update-event="(e, fn)=>{ eventAction(e, fn, 'update') }"
                    @refetch-events="refetchEvents"
                    @close="onCalendarEventHandlerClosed"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <manage-template
      :show="showTemplateCreate"
      :class-id="classId"
      @close="showTemplateCreate = false"
      @update-event="refetchEvents"
      @add-event="showAddEventModal"
    />
    <b-modal
      v-if="instructionEventToShow"
      :visible="true"
      :title="instructionEventToShow?.event_title"
      hide-footer
      @close="instructionEventToShow = null"
      @hide="instructionEventToShow = null"
    >
      <div
        v-if="instructionEventToShow.event_link"
        class="float-right"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="btn-icon"
          :disabled="isOpeningLink"
          @click="openInstructionLink(instructionEventToShow.event_link)"
        >
          <b-spinner
            v-if="isOpeningLink"
            small
          />
          <feather-icon
            v-else
            icon="ExternalLinkIcon"
          />
          <span class="ml-1">Click here to start lesson</span>
        </b-button>
      </div>
      <div>
        <p>
          {{ instructionEventToShow.event_description }}
        </p>
      </div>
    </b-modal>

    <lesson-planner-list
      :show="showLessonPlans"
      :courses="courses"
      @closed="showLessonPlans=false"
    />
    <DeleteModal
      :show="showBulkDelete"
      :on-delete="destroyBulkEvent"
      :is-processing-delete="isProcessingBulkDelete"
      title="Confirm Delete"
      sub-title="Are you sure, you want to delete?"
      @close="showBulkDelete = false"
    />
    <b-popover
      v-if="!!hoverEventInfo"
      :key="hoverEventInfo.eventId"
      :target="`popover-button-show-event-${hoverEventInfo.eventId}`"
      :show="true"
      custom-class="custom-popover"
    >
      <template #title>
        {{ hoverEventInfo.title }}
      </template>
      <LessonDetail :key="hoverEventInfo.lessonId"
                    :lesson-id="hoverEventInfo.lessonId"
                    view-type="popover"
                    :show="true"
      />
    </b-popover>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BButton, BModal, BSpinner, BTab, BTabs, BLink, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BOverlay, BPopover,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted, getCurrentInstance } from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import useApollo from '@/@core/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
} from '@/const/userType'
import LessonPlannerList from '@/views/teacher/class/lesson-planner/list.vue'
import useJwt from '@/auth/jwt/useJwt'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import DateFilter from '@/views/common/components/DateFilter.vue'
import i18n from '@/libs/i18n'
import _ from 'lodash'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import LessonDetail from '@/views/common/components/LessonDetailModel.vue'
import calendarStoreModule from './calendarStoreModule'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import ManageTemplate from './calendar-template/ManageTemplate.vue'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    ManageTemplate,
    DeleteModal,
    CalendarEventHandler,
    LessonPlannerList,
    BButton,
    BCol,
    BRow,
    BModal,
    BSpinner,
    vSelect,
    BTab,
    BTabs,
    BLink,
    VueGoodTable,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    DateFilter,
    BOverlay,
    BPopover,
    LessonDetail,
  },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      self: getUserData(),
      classId: null,
      showTemplateCreate: false,
      classrooms: [],
      courses: [],
      grades: [],
      showLessonPlans: false,
      isOpeningLink: false,
      isProcessing: false,
      selectedEventType: null,
      eventTypes: [
        {
          label: i18n.tc('calendar-module.sequenced'),
          value: 'sequenced',
          variant: 'btn-outline-primary',
          search: 'schedule_type',
        },
        {
          label: i18n.tc('calendar-module.non-sequenced'),
          value: 'non-sequenced',
          variant: 'btn-outline-yellow',
          search: 'schedule_type',
        },
        {
          label: i18n.tc('calendar-module.unlockable-sequenced'),
          value: 'unlockable-sequenced',
          variant: 'btn-outline-info',
          search: 'schedule_type',
        },
        {
          label: i18n.tc('calendar-module.class-test'),
          value: 'class_test',
          variant: 'btn-outline-danger',
          search: 'event_type',
        },
        {
          label: i18n.tc('calendar-module.instruction'),
          value: 'instruction',
          variant: 'btn-outline-success',
          search: 'event_type',
        },
        {
          label: i18n.tc('calendar-module.follow-up'),
          value: 'follow_up',
          variant: 'btn-outline-purple',
          search: 'event_type',
        },
        {
          label: i18n.tc('calendar-module.all'),
          value: '',
          variant: 'btn-outline-secondary',
          search: 'event_type',
        },
        {
          label: i18n.tc('calendar-module.conversational'),
          value: 'conversational',
          variant: 'btn-outline-secondary',
          search: 'event_type',
        },
        {
          label: i18n.tc('calendar-module.rea-assignment'),
          value: 'rea_event',
          variant: 'btn-outline-secondary',
          search: 'event_type',
        },
      ],
      rows: [],
      filteredRows: [],
      columns: [
        {
          label: i18n.tc('calendar-module.name'),
          field: 'name',
        },
        ...(getUserData().usertype !== 'student'
          ? [{
            label: i18n.tc('calendar-module.schedule-type'),
            field: 'schedule_type',
          }] : []
        ),
        {
          label: i18n.tc('class-module.start-time'),
          field: 'start_time',
        },
        {
          label: i18n.tc('class-module.end-time'),
          field: 'end_time',
        },
        ...(getUserData().usertype !== 'student'
          ? [{
            label: this.$i18n.tc('multi-practice'),
            field: 'multi-practice',
          }] : []
        ),
      ],
      selectedEvents: [],
      updateTableColumns: [
        {
          key: 'start_date',
          label: 'Start Date',
        },
        {
          key: 'end_date',
          label: 'End Date',
        },
      ],
      update_start_date: 0,
      update_end_date: 0,
      update_schedule_type: null,
      update_duration: 0,
      scheduleType: [
        {
          label: i18n.tc('calendar-module.select-schedule-type'),
          value: null,
        },
        {
          label: i18n.tc('calendar-module.sequenced'),
          value: 'sequenced',
        },
        {
          label: i18n.tc('calendar-module.non-sequenced'),
          value: 'non-sequenced',
        },
        {
          label: i18n.tc('calendar-module.unlockable-sequenced'),
          value: 'unlockable-sequenced',
        },
      ],
      isolatedTemplate: null,
      isolatedEvent: null,
      tabModel: 0,
      searchTerm: '',
      tabIndex: 0,
      multiPracticeOnSelectedEvents: false,
      indeterminateMultiPractice: false,
      showFullCalendar: true,
      showBulkDelete: false,
      isProcessingBulkDelete: false,
      filterStudent: [
        { value: '', label: 'Group Assignments' },
      ],
      selectedStudent: '',
    }
  },
  computed: {
    ...mapGetters({
      studentName: 'calendar/studentName',
      className: 'calendar/className',
    }),
    selectedListData() {
      return this.filteredRows.length ? this.filteredRows : this.allEventsList
    },
    canEditTemplate() {
      return getUserData().usertype !== 'student'
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    displayedColumns() {
      // Filter the columns based on the user role or condition
      return this.isAStudent
        ? this.columns.filter(column => column.field !== 'select')
        : this.columns
    },
    getEnableMultiPracticeInfo() {
      if (this.indeterminateMultiPractice) return i18n.tc('calendar-module.no-changes-info')
      if (this.multiPracticeOnSelectedEvents) return i18n.tc('calendar-module.multi-practice-enabled')
      return i18n.tc('calendar-module.multi-practice-disabled')
    },
    computedClassRoomId() {
      return this.classRoomId ? this.classRoomId : this.$route.params.classId
    },
  },

  watch: {
    $route(to, from) {
      if (to !== from) {
        this.initiate()
      }
    },
    tabIndex(value) {
      this.$store.dispatch('calendar/setActiveView', value)
      this.resetCalendar()
    },
    showLessonPlans() {
      if (!this.courses.length) {
        this.getCourses()
      }
    },
  },

  created() {
    this.initiate()
    //  this.getCourses()
    this.getGrades()
    this.$root.$off('update-event-refetch')
    this.$root.$on('update-event-refetch', this.refetchEvents)
    this.getClassRoomStudents()
  },

  methods: {
    resetCalendar() {
      this.$refs.refCalendar.getApi().destroy()
      this.showFullCalendar = false
      setTimeout(() => {
        this.showFullCalendar = true
      }, 0)
    },
    getRandomKey() {
      return moment(this.getEvent().start).format('YYYY-MM-DD')
    },
    filterTable() {
      this.filteredRows = this.allEventsList.filter(row => row.title.toLowerCase().includes(this.searchTerm))
    },
    debouncedFilterTable: _.debounce(function () {
      this.filterTable()
    }, 1000),
    getToday() {
      return (new Date()).toISOString().split('T')[0]
    },
    openInstructionLink(link) {
      if (link.startsWith(process.env.VUE_APP_SSO_PREFIX)) {
        this.openSSOLink(link)
      } else {
        window.open(link, '_blank')
      }
    },
    openSSOLink(link) {
      this.isOpeningLink = true
      const ssoProvider = link.split(process.env.VUE_APP_SSO_PREFIX)[1]
      useJwt.getSsoLink(ssoProvider).then(response => {
        window.open(response.data.data, '_blank')
        this.redirectingLink = null
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isOpeningLink = false
      })
    },
    getCourses() {
      this.$store.dispatch('calendar/getCourses').then(res => {
        this.courses = res.data.data.map(e => ({
          value: e.id, id: e.id, lesson_groups: e.lesson_groups, text: e.name,
        }))
        this.courses.unshift({ value: null, text: 'Please select a course' })
      })
    },
    getGrades() {
      this.$store.dispatch('calendar/getGrades').then(response => {
        this.grades = response.data.data.grades.map(({ id, name, courses }) => ({
          value: id,
          text: name,
          courses,
        }))
        this.grades.unshift({ value: null, text: this.$i18n.tc('calendar-module.select-a-grade') })
      })
    },
    getClassRoom() {
      useApollo
        .searchRooms('', this.AUTH_USER().id, 'all')
        .then(res => {
          this.classrooms = res.data.searchRooms
          this.classId = Number(this.classrooms[0]?.id)
          this.getEventData(this.classId)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    initiate() {
      if (this.computedClassRoomId) {
        this.classId = this.computedClassRoomId
        this.$store.dispatch('calendar/setClassId', this.classId)
      } else {
        this.getClassRoom()
      }
    },
    filterEventList(value) {
      this.selectedEventType = value
      const type = this.eventTypes.find(ev => ev.value === this.selectedEventType)
      console.log('filter', type.value)
      this.$store.dispatch('calendar/setSelectedEventType', type.value)
      this.refetchEvents()
    },
    updateStartDate(action) {
      if (action === 'plus') {
        this.update_start_date += 1
        this.update_end_date += 1
      }
      if (action === 'minus') {
        this.update_start_date -= 1
        this.update_end_date -= 1
      }
    },
    updateEndDate(action) {
      if (action === 'plus') {
        this.update_end_date += 1
        this.update_duration += 1
      }
      if (action === 'minus') {
        this.update_end_date -= 1
        this.update_duration -= 1
      }
    },
    handleSelectionChange({ selectedRows }) {
      if (selectedRows.length) {
        const arr = selectedRows.map(event => event.extendedProps.has_multi_practice)
        this.indeterminateMultiPractice = !arr.every(event => event === selectedRows[0].extendedProps?.has_multi_practice)
        if (!this.indeterminateMultiPractice) {
          this.multiPracticeOnSelectedEvents = arr.every(event => event)
        }
      }
      this.selectedEvents = selectedRows
    },
    clearSelect() {
      const tr = this.$refs.calendarListView.$el.querySelectorAll('tbody tr')
      tr.forEach(node => {
        const checkbox = node.querySelector('input[type=checkbox]')
        checkbox.checked = false
      })
    },
    selectFollowingLesson(type, index) {
      const selectedRow = type === 'up' ? this.selectedListData.slice(0, index + 1) : this.selectedListData.slice(index)
      this.handleSelectionChange({
        selectedRows: selectedRow,
      })
      const tr = this.$refs.calendarListView.$el.querySelectorAll('tbody tr')
      tr.forEach((node, i) => {
        const checkbox = node.querySelector('input[type=checkbox]')
        if (type === 'up') {
          checkbox.checked = i <= index
        } else {
          checkbox.checked = i >= index
        }
      })
    },
    getComputedStartTime(row) {
      if (this.selectedEvents.includes(row.id)) {
        const startTime = moment(row.start).add(this.update_start_date, 'days').format('MMM Do YYYY')
        return startTime
      }
      return moment(row.start).format('MMM Do YYYY')
    },
    getComputedEndTime(row) {
      if (this.selectedEvents.includes(row.id)) {
        const actualEndTime = moment(row.end).add('-1', 'days')
        const endTime = moment(actualEndTime).add(this.update_end_date, 'days').format('MMM Do YYYY')
        return endTime
      }
      return moment(row.end).add('-1', 'days').format('MMM Do YYYY')
    },
    getCheckValidityOfEndTime(row) {
      if (this.selectedEvents.includes(row.id)) {
        const actualEndTime = moment(row.end).add('-1', 'days')
        const endTime = moment(actualEndTime).add(this.update_end_date, 'days')
        const startTime = moment(row.start).add(this.update_start_date, 'days')
        if (endTime.isBefore(startTime)) { return 'text-danger' }
      }
      return ''
    },
    destroyBulkEvent() {
      this.isProcessingBulkDelete = true
      useJwt.bulkCalendarEventDelete({
        event_ids: this.selectedEvents.map(i => i.id),
      }).then(response => {
        this.showSuccessMessage(response)
        this.refetchEvents()
        this.clearSelect()
        this.showBulkDelete = false
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessingBulkDelete = false
      })
    },
    updateBulkEvent() {
      this.isProcessing = true
      const data = {
        update_start_date: this.update_start_date,
        update_end_date: this.update_end_date,
        selectedEvents: this.selectedEvents,
        schedule_type: this.update_schedule_type,
      }
      if (!this.indeterminateMultiPractice) data.enableMultiPractice = this.multiPracticeOnSelectedEvents
      useJwt.bulkCalendarEventUpdate(data)
        .then(res => {
          this.update_start_date = 0
          this.update_end_date = 0
          this.update_duration = 0
          this.schedule_type = null
          this.selectedEvents = []
          this.refetchEvents()
          this.showMessage(res.data.message)
          this.clearSelect()
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    formatString(string) {
      const words = string.split('-')

      // Capitalize the first letter of each word
      const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

      return formattedString
    },
    getEventFilteredDate(date) {
      const from = +new Date(date.from)
      const to = +new Date(date.to)
      this.selectedEvents = this.allEventsList
        .filter(event => +new Date(event.start) >= from && +new Date(event.start) <= to)
        .map(event => event.id)
    },
    showAddEventModal({ grades, template }) {
      this.grades = grades
      this.isolatedTemplate = template
      this.isolatedEvent = this.getEvent(template)

      this.$nextTick(() => {
        this.$bvModal.show('sidebar-add-new-event')
      })
    },
    eventAction(e, fn, action) {
      const params = {
        ...(this.isolatedTemplate ? {} : { class_id: this.classId }),
        ...e,
      }
      console.log(fn, params, action)
      if (action === 'add') {
        this.addEvent(params, fn)
      } else if (action === 'update') {
        this.updateEvent(params, fn)
      }
      this.getClassRoomStudents()
    },
    onCalendarEventHandlerClosed() {
      this.isolatedTemplate = null
    },
    toggleMultiPractice(value, row) {
      useJwt.updateLessonRule({
        classroom_id: this.computedClassRoomId,
        course_id: Number(row.courseId),
        lesson_id: Array.isArray(row.lessonId) ? row.lessonId[0] : row.lessonId,
        has_multi_practice: value,
      }).then(res => {
        this.showSuccessMessage(res)
      }).finally(() => {
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getClassRoomStudents() {
      useJwt.getStudentWithPrivateClass(this.computedClassRoomId)
        .then(response => {
          const newStudents = response.data?.data.map(student => ({
            ...student,
            value: Number(student.id),
            label: student.fullname,
          }))

          // Merge and deduplicate students by their `value` (id)
          const existingIds = new Set(this.filterStudent.map(student => student.value))
          const uniqueStudents = newStudents.filter(student => !existingIds.has(student.value))

          // Add only unique students
          this.filterStudent.push(...uniqueStudents)
        })
    },
    getFilteredEventData() {
      this.$store.dispatch('calendar/setStudentId', this.selectedStudent)
      this.refetchEvents()
    },
  },
  // eslint-disable-next-line vue/order-in-components
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    const root = getCurrentInstance().proxy.$root
    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const isNonSequenced = type => (type === 'non-sequenced' ? 1 : 0)

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      getEvent,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      allEventsList,
      calendarOptions,
      availableFilters,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      instructionEventToShow,
      hoverEventInfo,
    } = useCalendar()

    function getEventData(roomId) {
      root.$store.dispatch('calendar/setClassId', roomId)
      refetchEvents()
    }

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      getEvent,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      getEventData,
      allEventsList,
      availableFilters,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      instructionEventToShow,
      isNonSequenced,
      hoverEventInfo,
    }
  },
}
</script>

<style lang="scss">
    @import "@core/scss/vue/apps/calendar.scss";
    @import '~@core/scss/base/bootstrap-extended/include';
    @import '~@core/scss/base/components/variables-dark';

    .btn-outline-purple {
        border: 1px solid purple !important;
        background-color: transparent;
        color: purple;
    }
    .fc-event-title {
      display: inline-block; /* Ensures the div behaves properly for hover */
      cursor: pointer; /* Optional: makes it clear it's interactive */
    }
    .event-tooltip {
      background: #fff;
      position: fixed;
      border: 1px solid #ccc;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      pointer-events: none; /* Prevent interaction with the tooltip */
      transform: translateX(-50%); /* Center the tooltip horizontally */
      transition: opacity 0.2s ease-in-out;
    }

    .custom-popover {
      max-width: 500px; /* Set the desired width */
      width: 500px; /* Optional: Enforce fixed width */
    }

    .btn-outline-yellow {
        border: 1px solid #FFD300 !important;
        background-color: transparent;
    }

    .btn-yellow {
        border: 1px solid #FFD300 !important;
        background-color: #FFD300;
    }
    #datepicker-placeholder-from__dialog_{
      z-index: 1111111111 !important;
    }
    .right-0 {
      right: 0;
    }
    .select-icon {
      display: none;
    }
    .vgt-table tr:hover {
      .select-icon {
        display: inline;
      }
    }
    .student-search-box {
      margin-right: 2.5rem;
      width: 25%;
      position: absolute;
      right: 0;
    }
    @media (min-width: 375px ) and (max-width: 667px) {
      .student-search-box {
        margin-top: 1rem;
        margin-right:0px;
        width: 100%;
        position: relative;
      }

      .sm-pb-1{
        padding-bottom: 1rem !important
      }
      .sm-display {
        display: inline-table
      }
    }
    .fc-daygrid-block-event .fc-event-title {
      color: #fff !important;
    }
    .calendar-breadcrumb, .calendar-filter{
      background-color: #fff;
    }
    .dark-layout {
      .calendar-breadcrumb, .calendar-filter {
        background-color: $theme-dark-card-bg !important;
        background-color: $theme-dark-box-shadow;
      }
    }
    .semi-light-layout,
    .semi-light-reverse-layout,
    .green-layout,
    .brown-layout {
      .btn-flat-secondary {
        color: #82868b !important;
      }
      span {
        color: #82868b !important;
      }
    }
</style>
