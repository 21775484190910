<template>
  <div>
    <b-card-body
      v-if="noEvent"
      class="text-center mt-2"
    >
      <b-card-text>
        {{ $t('messages.no-event') }}
      </b-card-text>
    </b-card-body>

    <b-card-body v-else
                 class="scrollable-card"
    >
      <!-- metting header -->
      <div v-if="latestData"
           class="meetup-header d-flex align-items-center"
      >
        <div
          v-if="latestData"
          class="meetup-day"
        >
          <h6 class="mb-0 capatilize">
            {{ latestData.day }}
          </h6>
          <h3 class="mb-0">
            {{ latestData.date }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            <a href="#"
               class="event-list-link"
               @click.prevent="openLessonLink( latestData)"
            >
              {{ latestData.lessonName }}
            </a>
          </b-card-title>
          <b-card-text class="mb-0">
            <small :set="day = getDaysDifference(latestData.start_time, latestData.end_time)">
              <feather-icon icon="ClockIcon" />
              {{ FORMAT_DATE(latestData.start_time, 'MMM Do YYYY') }} - {{ FORMAT_DATE(latestData.end_time, 'MMM Do YYYY') }}
              <span class="badge badge-light-primary ml-1">{{ day }} {{ day > 1 ? $t('labels.days') : $t('labels.day') }}</span>
              <span v-if="isOngoing"
                    class="badge badge-light-success ml-1"
                    :set="remainingDays =getDaysDifference(today, latestData.end_time)"
              >
                {{ remainingDays }} {{ remainingDays > 1 ? $t('days-left') : $t('day-left') }}
              </span>
            </small>
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media
        v-for="(media, index) in events"
        :key="media.avatar + index"
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              :icon="'CalendarIcon'"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <a href="#"
             class="event-list-link"
             @click.prevent="openLessonLink( media.lesson)"
          >
            <h6 class="mb-0">
              {{ media.lesson && media.lesson.name }}
            </h6>
          </a>
          <small :set="day = getDaysDifference(media.start_time, media.end_time)">
            <feather-icon icon="ClockIcon" />  {{ FORMAT_DATE(media.start_time, 'MMM Do YYYY') }} - {{ FORMAT_DATE(media.end_time, 'MMM Do YYYY') }} <span class="badge badge-light-primary ml-1">{{ day }} {{ day > 1 ? $t('labels.days') : $t('labels.day') }}</span>
            <span v-if="isOngoing"
                  class="badge badge-light-success ml-1"
                  :set="remainingDays =getDaysDifference(today, media.end_time)"
            >
              {{ remainingDays }} {{ remainingDays > 1 ? $t('days-left') : $t('day-left') }}
            </span>
          </small>
        </b-media-body>
      </b-media>

    </b-card-body>
  </div>
</template>
<script>
import {
  BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    eventLists: {
      type: Array,
      default: () => [],
    },
    classId: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'ongoing',
    },
    clickableLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      today: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
    }
  },
  computed: {
    noEvent() {
      return !this.eventLists.length
    },
    isOngoing() {
      return this.type === 'ongoing'
    },
    events() {
      const array = [...this.eventLists]
      if (this.type === 'ongoing') {
        array.shift()
      }
      return array
    },
    latestData() {
      if (this.type !== 'ongoing') {
        return null
      }
      const nearestEvent = this.eventLists[0]
      return {
        day: moment(nearestEvent?.start_time).format('ddd'),
        date: moment(nearestEvent?.start_time).format('D'),
        className: nearestEvent?.class?.name,
        lessonName: nearestEvent?.lesson?.name,
        start_time: nearestEvent?.start_time,
        end_time: nearestEvent?.end_time,
        id: nearestEvent?.lesson?.id,
      }
    },
  },
  methods: {
    getDaysDifference(from, to) {
      return this.DAYS_DIFF_FROM_TO(from, to)
    },
    // eslint-disable-next-line consistent-return
    openLessonLink(item) {
      if (!this.clickableLink) {
        return false
      }
      const url = `/${this.AUTH_USER().usertype}/class/${this.classId}/lesson/${item.id}`
      this.$router.push(url)
      // window.open(`/${this.AUTH_USER().usertype}/class/${this.classId}/lesson/${item.id}`)
    },
  },
}
</script>
<style scoped>
  .event-list-link:hover {
    text-decoration: underline;
  }
    .scrollable-card {
    max-height: 345px;
    overflow-y: auto;
  }
</style>
