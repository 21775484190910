<template>
  <div>
    <b-row>
      <b-col v-if="$route.query.from"
             md="12"
      >
        <div class="pb-1">
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="GO_BACK()"
          >
            <feather-icon
              size="20"
              icon="ArrowLeftIcon"
            />
          </b-button>
          <span>
            <span class="text-capitalize">{{ $t('actions.back') }}</span>
          </span>
        </div>
      </b-col>

      <Fragment v-if="!activeTable">
        <b-col>
          <div class="masonry-wrapper">
            <masonry
              :cols="{default: 2, 1024: 2, 768: 1}"
              :gutter="{default: '30px', 1024: '20px', 768: '10px'}"
            >
              <welcome-card
                v-if="!hiddenFeatures.greeting"
                :data="welcomeData"
                :from-teacher="fromTeacher"
              />
              <!-- <statistic-card
                  v-if="!hiddenFeatures.stats"
                  :card-options="{
                    title: 'statistics',
                  }"
                  :data="stats.statisticData"
                  @tableView="activeTable = $event"
                /> -->
              <activity-card
                v-if="!hiddenFeatures.activity"
                :data="activityData.slice(0, 4)"
              />
              <event-card
                v-if="!hiddenFeatures.event"
                :data="eventData"
              />
              <b-card
                v-if="!hiddenFeatures.timeOn"
              >
                <month-blocks
                  :attendance="attendance"
                  :year="today.getFullYear()"
                  :user-id="studentId"
                  :from-dashboard="true"
                  :month="today.getMonth() + 1"
                />
              </b-card>
              <b-card
                v-if="!hiddenFeatures.hoursTracked"
              >
                <hours-tracked :user-id="Number(studentId)" />
              </b-card>
              <rubric-report
                v-if="!hiddenFeatures.rubric"
                :user-id="Number(studentId)"
              />
            </masonry>
          </div>
        </b-col>
      </Fragment>
      <b-col v-else>
        <b-breadcrumb>
          <b-breadcrumb-item @click="activeTable = null">
            Dashboard
          </b-breadcrumb-item>
          <b-breadcrumb-item>{{ activeTable }}</b-breadcrumb-item>
        </b-breadcrumb>
        <dashboard-table :active-table="activeTable" />
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BRow, BCol, BBreadcrumb, BBreadcrumbItem, BCard, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { Fragment } from 'vue-fragment'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT, USER_TYPE_TEACHER } from '@/const/userType'
import useJwt from '@/auth/jwt/useJwt'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import i18n from '@/libs/i18n'
import MonthBlocks from '@/views/student/attendance/components/MonthBlocks.vue'
import WelcomeCard from '../WelcomeCard.vue'
// import StatisticCard from '../StatisticCard.vue'
import EventCard from '../EventCard.vue'
import ActivityCard from '../ActivityCard.vue'
import DashboardTable from '../DashboardTable.vue'
import HoursTracked from '../components/HoursTracked.vue'
// import CongratulationCard from './CongratulationCard.vue'
import RubricReport from '../components/RubricReport.vue'

export default {
  components: {
    // StatisticCardWithAreaChart,
    WelcomeCard,
    // StatisticCard,
    EventCard,
    ActivityCard,
    // CongratulationCard,
    // RubricCard,
    RubricReport,
    BRow,
    BCol,
    DashboardTable,
    BBreadcrumb,
    BBreadcrumbItem,
    MonthBlocks,
    BCard,
    HoursTracked,
    BButton,
    Fragment,
  },
  data() {
    return {
      isAStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isATeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
      activeTable: null,
      studentId: this.$route.params.id ?? getUserData().id,
      stats: {
        // awardStatsData: [],
        statisticData: [],
      },
      attendance: [],
      today: new Date(),
      activityData: [],
      classSeries: [
        {
          data: [0, 1, 2, 2],
          name: 'Total no of Class',
        },
      ],
      eventData: {},
      welcomeData: {},
      rubric: {
        name: 'Rubric 1',
        rubrics: [
          {
            id: 0, name: 'Rubric a', value: 10, maxValue: 200,
          },
          {
            id: 1, name: 'Rubric b', value: 50, maxValue: 100,
          },
          {
            id: 2, name: 'Rubric c', value: 100, maxValue: 200,
          },
          {
            data: [0, 1, 2, 2],
            name: 'Total no of Class',
          },
        ],
        fromTeacher: false,
        classes: [
          'Teacher 1',
          'Teacher 2',
          'Teacher 3',
        ],
      },
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    hiddenFeatures() {
      return {
        greeting: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_greeting')?.value === '1',
        stats: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_stats')?.value === '1',
        activity: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_activity')?.value === '1',
        event: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_calendar')?.value === '1',
        timeOn: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_time_on')?.value === '1',
        hoursTracked: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_hour_tracked')?.value === '1',
        rubric: this.getDistrictSettings?.find(metaValue => metaValue.key === 'student_dashboard_rubrics')?.value === '1',
      }
    },
    showLangLevel() {
      const distLangLevelSetting = this.getDistrictSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
      // check school level setting
      const schoolLangLevelSetting = this.getSchoolSettings?.find(metaValue => metaValue.key === 'lang_level') ?? '0'
      if (distLangLevelSetting?.value !== '0' && schoolLangLevelSetting?.value !== '0') {
        return false
      }
      return true
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.id': {
      handler() {
        this.loadData()
      },
      immediate: true,
    },
  },
  created() {
    this.getAttendance()
    // if (!this.isAStudent) this.getAwardStats()
  },
  methods: {
    getAttendance() {
      const params = {
        user_id: this.studentId,
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      }
      useJwt.getStudentAttendance({
        params,
      }).then(response => {
        const attendance = response.data.data.map(day => moment(day).format('YYYY-MM-DD'))
        this.attendance = [...new Set(attendance)]
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    pushToStats({
      statsData = 'statisticData', title, subtitle, color, icon, fill, type = '',
    }) {
      this.stats[statsData].push({
        type,
        title,
        subtitle,
        color,
        icon,
        fill,
        customClass: 'mb-2 mb-xl-0',
      })
    },
    loadData() {
      const userId = this.$route.params.id
      const params = {}
      if (userId) {
        params.id = userId
        this.fromTeacher = true
      } else {
        this.fromTeacher = false
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.welcomeData = {
          name: userData?.fullname ? userData.fullname : '',
          time: this.generateGreetings(),
          text: i18n.tc('messages.welcome-dashboard'),
        }
      }
      useJwt.getStudentDashboardData({ params }).then(res => {
        const response = res.data?.data
        // stats data
        if (response) {
          this.pushToStats({
            title: response.total_lesson, subtitle: response.total_lesson > 1 ? i18n.tc('labels.lessons-done') : i18n.tc('labels.lesson-done'), color: 'light-info', icon: 'BookIcon',
          })
          if (this.showLangLevel) {
            this.pushToStats({
              title: response.lang_level, subtitle: i18n.tc('labels.current-lang-level'), color: 'light-danger', icon: 'BarChartIcon',
            })
          }
          this.pushToStats({
            title: response.score.toFixed(2), subtitle: `${i18n.tc('labels.score-achieved')}`, color: 'light-secondary', icon: 'CheckIcon',
          })

          this.pushToStats({
            title: response.attendance_of_month, subtitle: i18n.tc('labels.study_days_of_month'), color: 'light-success', icon: 'UserIcon', fill: '#28c76f',
          })
        }

        // student_activity data
        if (response?.student_activity) {
          const activity = response.student_activity?.diary
          this.activityData = []
          Object.keys(activity).forEach(k => {
            this.activityData = [...this.activityData, ...activity[k]]
          })
        }
        if (userId) {
          this.welcomeData = { name: response.user_name }
        }
        this.eventData = response.event

        // event data
        // if (response?.event?.length) {
        //   const nearestEvent = response.event[0]
        //   this.eventData.latestData = {
        //     day: moment(nearestEvent).format('ddd'),
        //     date: moment(nearestEvent).format('D'),
        //     className: nearestEvent.class.name,
        //     lessonName: nearestEvent.lesson.name,
        //   }
        //   this.eventData.nextEvent = response.event.slice(1, 3).map(e => ({
        //     avatar: 'CalendarIcon',
        //     title: e.class.name,
        //     subtitle: e.lesson.name,
        //   }))
        // }
      })
    },
    getAwardStats() {
      this.isProcessing = true
      // Push to Award Stats
      const params = { uid: this.$route.params.id }
      useJwt.getStudentRewardCount({
        params,
      }).then(response => {
        const res = response.data?.data?.award_stats

        // Badges
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'badge',
          title: res.badge,
          subtitle: res.badge > 1 ? i18n.tc('labels.badges') : i18n.tc('labels.badge'),
          icon: 'AwardIcon',
          fill: 'transparent',
          color: 'light-warning',
        })

        // Vibes
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'vibe',
          title: res.vibe,
          subtitle: res.vibe > 1 ? i18n.tc('labels.vibes') : i18n.tc('labels.vibe'),
          icon: 'TwitchIcon',
          fill: 'transparent',
          color: 'light-danger',
        })

        // Points
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'points',
          title: res.points,
          subtitle: i18n.tc('labels.points'),
          icon: 'CircleIcon',
          fill: '#ff9f43',
          color: 'light-secondary',
        })

        // Coins
        this.pushToStats({
          statsData: 'awardStatsData',
          type: 'coins',
          title: res.coins,
          subtitle: i18n.tc('labels.coins'),
          icon: 'DollarSignIcon',
          fill: 'transparent',
          color: 'light-success',
        })
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
