<template>
  <BOverlay :show="state.isProcessing">
    <div v-if="$route.query.classId"
         class="pb-1"
    >
      <b-button variant="flat-secondary"
                class="btn-icon"
                @click="$router.push({ name: `${AUTH_USER().usertype}-view-students`, params: { id: state.classRoomId } })"
      >
        <feather-icon size="20"
                      icon="ArrowLeftIcon"
        />
        <span class="ml-1">{{ $t('actions.back') }}</span>
      </b-button>
    </div>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb>
          <b-breadcrumb-item v-for="bread, index of breadcrumb"
                             :key="index"
                             :disabled="bread.disabled"
                             @click="handleBreadCrumbClick(index)"
          >
            {{ $t(bread.text) }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="pt-1 align-items-baseline m-0 rounded report-filter">
      <!-- <b-col v-if="state.currentReportLevel === 'school'"
             cols="2"
      >
        <b-form-checkbox v-model="state.showAllClassrooms"
                         switch
                         @change="!Object.keys(state.allClassroomReport).length ? getHigherLevelReport('classroom', getSchoolId, null) : ''"
        >
          {{ $t('view-all-classroom') }}
        </b-form-checkbox>
      </b-col> -->
      <b-col v-if="canShowFilter"
             md="2"
             sm="12"
             class="align-items-baseline justify-content-end"
      >
        <b-form-group :label="$t('animateditem-module.filter')">
          <div class="d-flex">
            <div class="w-100">
              <b-form-select
                v-model="state.typeFilter"
                :options="typeFilter"
                @change="v => onFilterChange(v)"
              />
            </div>
            <div class="ml-2">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="$t('attendance-module.refresh')"
                variant="outline-primary"
                class="btn-icon"
                @click="refreshFilter(state.typeFilter)"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="state.currentReportLevel === 'school' ? 6 : 8"
             sm="12"
             class="d-md-flex align-items-baseline justify-content-end mb-1"
             :class="!canShowFilter ? 'offset-md-2': ''"
             style="gap:20px;"
      >
        <div>
          <b-form-group :label="$t('student-report-module.summary-table.from')">
            <b-form-datepicker v-model="filter.start_date"
                               :placeholder="$t('higher-level-report-module.no-date-selected')"
                               :max="maxDate"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-group :label="$t('student-report-module.summary-table.to')">
            <b-form-datepicker v-model="filter.end_date"
                               :placeholder="$t('higher-level-report-module.no-date-selected')"
                               :min="filter.start_date"
                               :max="maxDate"
            />
          </b-form-group>
        </div>

        <div>
          <b-dropdown :text="$t('higher-level-report-module.time-range')"
                      variant="outline-primary"
          >
            <b-dropdown-item href="#"
                             @click="resetFilter('week')"
            >
              {{ $t('higher-level-report-module.this-week') }}
            </b-dropdown-item>
            <b-dropdown-item href="#"
                             @click="resetFilter('month')"
            >
              {{ $t('higher-level-report-module.this-month') }}
            </b-dropdown-item>
            <b-dropdown-item href="#"
                             @click="resetFilter()"
            >
              {{ $t('higher-level-report-module.all-time') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </b-col>
      <b-col md="2"
             sm="12"
             class="mb-1"
      >
        <b-button variant="primary"
                  class="p-1"
                  @click="exportExcel()"
        >
          {{ $t('student-report-module.report-pages.export-excel') }}
          <feather-icon icon="FileTextIcon" />
        </b-button>
      </b-col>
    </b-row>
    <component :is="isATeacherLevel ? components.BTabs : 'div'"
               class="mt-2"
               pills
    >
      <b-tab v-if="Object.entries(state.rawClassroomReport).length > 0"
             :title="$t('labels.classroom')"
             lazy
      >
        <b-card body-class="p-0"
                class="mt-2"
        >
          <TableResult :table-data="state.rawClassroomReport"
                       level="classroom"
                       :class-room-id="state.classRoomId"
                       @getNextLevelReport="getNextLevelReport"
          />
        </b-card>
      </b-tab>

      <component :is="Object.entries(state.rawClassroomReport).length > 0 && isATeacherLevel ? components.BTab : 'div'">
        <b-card body-class="p-0"
                class="mt-2"
        >
          <TableResult :table-data="state.showAllClassrooms ? state.allClassroomReport : state.rawReport"
                       :level="state.currentReportLevel"
                       :class-room-id="state.classRoomId"
                       :is-student-class-view="isStudentClassView"
                       :specific-student="+specificStudent"
                       @getNextLevelReport="getNextLevelReport"
                       @getTeacherLevelReport="userId => {
                         state.filterValue = 0
                         getHigherLevelReport('student', userId, 'unset', state.filterValue)
                       }"
                       @getStudentLevelReport="userId => getStudentLevelReport(userId)"
          />
        </b-card>
      </component>

    </component>

  </BOverlay>
</template>
<script setup>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import {
  ref, computed, getCurrentInstance, watch, reactive,
} from 'vue'
import {
  BCard, BBreadcrumb, BBreadcrumbItem, BOverlay, BTabs, BTab, BButton, BRow, BCol, BFormGroup, BFormDatepicker, BDropdown, BDropdownItem, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import { getChildUserOf } from '@/const/userType'
import i18n from '@/libs/i18n'
import moment from 'moment'
import exportFromJSON from 'export-from-json'
import TableResult from './components/TableResult.vue'

const components = {
  BTabs, BTab, BFormCheckbox, BFormSelect,
}
// function capitalizeFirstLetter(str) { return str.charAt(0).toUpperCase() + str.slice(1) }
const auth = getUserData()
const root = getCurrentInstance().proxy.$root
const canShowFilter = ['district', 'school', 'teacher'].includes(auth.usertype)

// const getInitialType = () => {
//   switch (auth.usertype) {
//     case 'school':
//       return 'teacher'
//     case 'district':
//       return 'school'
//     default:
//       return null
//   }
// }
const props = defineProps({
  classRoomId: {
    type: Number,
    default: null,
  },
})

const state = reactive({
  rawReport: {},
  rawClassroomReport: {},
  allClassroomReport: {},
  currentReportLevel: auth.usertype,
  parentId: auth.id,
  isProcessing: {},
  classRoomId: props.classRoomId ? props.classRoomId : root.$route.query.classId,
  showAllClassrooms: false,
  typeFilter: null,
  filterValue: 0, // to check if filter is applied or not
})

const maxDate = ref(moment().format('YYYY-MM-DD'))

const breadcrumb = ref([
  {
    text: auth.usertype,
    value: auth.usertype,
    parentId: auth.id,
  },
])

// const typeFilter = ref([
//   { value: null, text: 'None' },
//   ...(auth.usertype !== 'school' ? [
//     { value: 'school', text: i18n.tc('school') },
//   ] : []),
//   { value: 'teacher', text: i18n.tc('actions.teacher') },
//   { value: 'classroom', text: i18n.tc('class-room') },
//   { value: 'student', text: i18n.tc('actions.student') },
// ])
const typeFilter = ref([
  { value: null, text: 'None' },
])

if (auth.usertype === 'school') {
  typeFilter.value.push(
    { value: 'teacher', text: i18n.tc('actions.teacher') },
    { value: 'classroom', text: i18n.tc('class-room') },
    { value: 'student', text: i18n.tc('actions.student') },
  )
} else if (auth.usertype === 'teacher') {
  typeFilter.value.push(
    { value: 'classroom', text: i18n.tc('class-room') },
    { value: 'student', text: i18n.tc('actions.student') },
  )
} else {
  typeFilter.value.push(
    { value: 'school', text: i18n.tc('school') },
    { value: 'teacher', text: i18n.tc('actions.teacher') },
    { value: 'classroom', text: i18n.tc('class-room') },
    { value: 'student', text: i18n.tc('actions.student') },
  )
}
// For Filter
const filter = ref({
  start_date: null,
  end_date: null,
})

const resetFilter = (type = 'all') => {
  if (type === 'month') {
    filter.value = {
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
    }
    return
  } if (type === 'week') {
    filter.value = {
      start_date: moment().startOf('week').format('YYYY-MM-DD'),
      end_date: moment().endOf('week').format('YYYY-MM-DD'),
    }
    return
  }
  filter.value = {
    start_date: null,
    end_date: null,
  }
}

const getSchoolId = computed(() => breadcrumb.value?.find(item => item.value === 'school')?.parentId)

const getNextLevelBasedOnReportFilter = type => {
  if (state.typeFilter === 'classroom') {
    return 'student'
  }
  return type
  // // eslint-disable-next-line default-case
  // switch (type) {
  //   case 'classroom':
  //     return 'student'
  //   default:
  //     return type
  // }
}
const specificStudent = ref(null)

const isStudentClassView = computed(() => root.$route.fullPath.endsWith('classroom') || !!specificStudent.value)

function getHigherLevelReport(type = state.currentReportLevel, parentId = state.parentId, classroomId = state.classRoomId) {
  state.isProcessing = true

  let reportLevel = getChildUserOf(type)
  const originalClassId = classroomId
  if (state.filterValue) {
    reportLevel = type
    // eslint-disable-next-line no-param-reassign
    parentId = auth.id
    if (state.typeFilter !== 'classroom' || state.currentReportLevel !== 'classroom') {
      state.classRoomId = null
    }
    state.currentReportLevel = state.typeFilter === 'classroom' ? 'district' : reportLevel
  } else if (state.typeFilter) {
    reportLevel = getNextLevelBasedOnReportFilter(type)
    if (reportLevel === 'student' && !isStudentClassView.value) {
      // eslint-disable-next-line no-param-reassign
      classroomId = parentId
    }
    if (state.typeFilter === 'classroom') {
      // eslint-disable-next-line no-param-reassign
      parentId = auth.id
    }
  }
  if (originalClassId === 'unset') {
    // eslint-disable-next-line no-param-reassign
    classroomId = null
  }
  // console.log('here', parentId, reportLevel, classroomId)
  // console.log('specificStudent', specificStudent)
  return useJwt.getHigherLevelReport({
    params: {
      start_date: filter.value.start_date,
      end_date: filter.value.end_date,
      reportLevel: !isStudentClassView.value ? reportLevel : 'classroom',
      parentId,
      classroomId,
      school_id: breadcrumb.value?.find(item => item.value === 'school')?.parentId || null,
      filter: state.filterValue,
      student_id: specificStudent.value,
    },
  }).then(response => {
    state.showAllClassrooms = state.typeFilter === 'classroom'
    // updateCurrentLevel()
    if (type === 'classroom') {
      if (state.showAllClassrooms) state.allClassroomReport = response.data.data.data
      else state.rawClassroomReport = response.data.data.data
    } else {
      state.rawReport = response.data.data.data
    }
    if (!state.typeFilter && response.data.data.classroom && !breadcrumb.value?.find(item => item.text === response.data.data.classroom.name)) {
      breadcrumb.value.push({
        text: response.data.data.classroom.name,
        value: 'teacher',
        disabled: true,
        parentId: response.data.data.classroom.id,
      })
    }
  }).finally(() => {
    state.isProcessing = false
  })
}

function getNextLevelReport(parent, level) {
  let parentId = parent
  let classRoomId = null
  let currentReportLevel = level
  state.filterValue = 0
  if (level === 'school' && state.showAllClassrooms) {
    classRoomId = parent
    currentReportLevel = 'classroom'
    parentId = state.parentId
  }

  if (level === 'classroom') {
    classRoomId = parent
    currentReportLevel = 'teacher'
    parentId = state.parentId
  } else {
    currentReportLevel = getChildUserOf(level)
  }
  // console.log(parentId, currentReportLevel)

  getHigherLevelReport(currentReportLevel, parentId, classRoomId).then(() => {
    state.parentId = parentId
    state.classRoomId = classRoomId
    state.currentReportLevel = currentReportLevel
    if (state.typeFilter === 'classroom') {
      state.classRoomId = parentId
      state.currentReportLevel = 'teacher'
    }
    if (!state.typeFilter && level !== 'classroom' && !state.showAllClassrooms) {
      breadcrumb.value.push({
        text: state.currentReportLevel,
        value: state.currentReportLevel,
        parentId: parent,
      })
    }
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.showAllClassrooms = false
  })
}

const isATeacherLevel = computed(() => state.currentReportLevel === 'teacher' && !state.classRoomId)
function handleBreadCrumbClick(index) {
  state.showAllClassrooms = false
  const parent = { ...breadcrumb.value[index] }
  state.parentId = parent.parentId
  state.currentReportLevel = parent.value
  breadcrumb.value = breadcrumb.value.filter((item, i) => i <= index)
  state.classRoomId = (props.classRoomId ? props.classRoomId : root.$route.query.classId) || null

  if (!isATeacherLevel.value) {
    getHigherLevelReport().catch(error => {
      root.showErrorMessage(error)
    })
  }
}
function getSum(array, user) {
  return array.filter(item => item.user_id === user.id).reduce((p, c) => p + Number(c.score), 0)
}
function exportExcel() {
  // const tableData = state.showAllClassrooms ? state.allClassroomReport : state.rawReport
  const tableData = isATeacherLevel.value ? state.rawClassroomReport : state.rawReport
  const users = []
  Object.entries(tableData).forEach(([, items]) => {
    items.forEach(record => {
      if (!users.find(item => item.id === record.user_id)) {
        users.push({
          id: record.user_id,
          fullName: record.username,
        })
      }
    })
  })
  const data = users.map(user => ({
    Users: user.fullName,
    'Lesson Completion': root.number_format(getSum(tableData.lesson_score, user), 2),
    'Pass Score': root.number_format(getSum(tableData.pass_score, user), 2),
    'Language Skills': root.number_format(getSum(tableData.language_score, user), 2),
    Attendance: root.number_format(getSum(tableData.attendance_score, user), 2),
    Notes: root.number_format(getSum(tableData.notes_score, user), 2),
  }))
  const cols = {
    'Lesson Completion': 'lesson_score',
    'Pass Score': 'pass_score',
    'Language Skills': 'language_score',
    Attendance: 'attendance_score',
    Notes: 'notes_score',
  }
  const average = { Users: 'Average' }
  Object.entries(cols).forEach(([key, value]) => {
    average[key] = root.number_format(tableData[value].reduce((sum, item) => sum + Number(item.score), 0) / tableData[value].length, 2)
  })
  data.push(average)
  const fileName = 'higher-level-report'
  const exportType = 'csv'

  exportFromJSON({ data, fileName, exportType })
}

function getStudentLevelReport(userId) {
  specificStudent.value = userId
  state.filterValue = 0
  getHigherLevelReport(state.currentReportLevel, state.parentId, null)
}

const onFilterChange = v => {
  specificStudent.value = null
  state.parentId = auth.id
  if (v) {
    state.filterValue = 1
    getHigherLevelReport(state.typeFilter, getSchoolId, null)
  } else {
    state.filterValue = 0
    getHigherLevelReport()
  }
}
const refreshFilter = v => {
  specificStudent.value = null
  state.parentId = auth.id
  if (v) {
    state.filterValue = 1
    getHigherLevelReport(state.typeFilter, getSchoolId, null)
  } else {
    state.filterValue = 0
    getHigherLevelReport('district')
  }
}

getHigherLevelReport().catch(error => {
  root.showErrorMessage(error)
})

watch(isATeacherLevel, value => {
  if (value && !state.typeFilter) {
    getHigherLevelReport('classroom').catch(error => {
      root.showErrorMessage(error)
    })
  }
}, {
  immediate: true,
})

watch(filter, () => {
  if (isATeacherLevel.value) {
    getHigherLevelReport('classroom')
  } else {
    getHigherLevelReport()
  }
  // getHigherLevelReport()
}, { deep: true })
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
  .report-filter {
    background-color: #ffffff;
  }

  .dark-layout {
    .report-filter {
      background-color: $theme-dark-card-bg !important;
      background-color: $theme-dark-box-shadow;
    }
  }
</style>
