<template>
  <body :class="variant">
    <div class="container-xl p-0">
      <header class="text-center">
        <div class="row">
          <div class="col-md-12">
            <h2>{{ lesson.page.title }}
              <div class="float-right">
                <slot name="ebook-selector" />
              </div>
            </h2>
          </div>
          <!-- /.col-md-12 -->
        </div>
        <!-- /.row -->
      </header>
      <!-- /.header -->
      <div class="row no-gutters">
        <div class="col-lg-6 col-print-6">
          <div
            class="bg-image p-1"
            :style="`background-image: url(${
              lesson.vocab_image
                ? lesson.vocab_image
                : 'https://via.placeholder.com/400'
            });`"
          >
            <div class="list-style-wrapper">
              <div class="clearfix" />

              <div class="row">
                <div
                  v-for="(vocab, index) in lesson.vocabulary"
                  :key="index"
                  class="col-md-6"
                  :style="`margin-top:${index >= 2 ? '60px' : '0'}`"
                >
                  <div class="list-style">
                    <div class="list-style-top">
                      <p class="mb-0">
                        {{ vocab.word_in_eng }}
                      </p>
                      <p>
                        {{ vocab.word_in_jap }}
                      </p>
                    </div>
                    <div class="list-style-bottom">
                      <p>{{ vocab.synonym_1 }} <br>{{ vocab.synonym_2 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="legend">
            <p>LEGEND</p>
            <ul class="list-inline">
              <li class="list-inline-item">
                <span class="legend-word" />Word
              </li>
              <li class="list-inline-item">
                <span class="legend-synonym" />Synonym
              </li>
            </ul>
          </div>

        </div>
        <!-- /.col-lg-6 -->

        <div class="col-lg-6 bg-light col-print-6 ebook-text">
          <div class="content-left pt-0">
            <!-- eslint-disable vue/no-v-html -->
            <p
              @click="handleClick"
              v-html="wordnetWords"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <!-- /.content-left -->

        </div>
        <!-- /.col-lg-6 -->
      </div>
      <div class="row no-gutters">
        <div class="col-lg-6 col-print-6">
          <div
            class="bg-image p-3 question-img"
            :style="`background-image: url(${
              lesson.question_bg_image
                ? lesson.question_bg_image
                : 'https://via.placeholder.com/400'
            });`"
          >
            <img
              class="img-fluid border-radius-img"
              :src="
                lesson.question_radius_image
                  ? lesson.question_radius_image
                  : 'https://via.placeholder.com/400'
              "
              alt=""
            >
            <p />
            <!-- eslint-disable vue/no-v-html -->
            <p class="transparent-paragraph"
               v-html="lesson.bottom_question"
            />
            <!-- eslint-enable vue/no-v-html -->
            <p />
          </div>
        </div>
        <div class="col-lg-6 col-print-6 ">
          <div class="question-box mt-0">
            <p><strong>REVIEW QUESTIONS:</strong></p>
            <!-- eslint-disable vue/no-v-html -->
            <p v-html="lesson.questions" />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <!-- /.question-box -->
          <div class="question-ans mb-0 mt-1">
            <!-- eslint-disable vue/no-v-html -->
            <p v-html="lesson.extra_questions" />
            <!-- eslint-enable vue/no-v-html  -->
            <hr>
            <hr>
            <hr>
            <hr>
            <hr>
            <hr>
          </div>
          <!-- /.question-ans -->
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    pageId: {
      type: [String, Number],
      required: true,
    },
    variant: {
      type: String,
      default: 'variant-2',
    },
  },
  data() {
    return {
      words: [],
    }
  },
  computed: {
    wordnetWords() {
      if (this.words.length > 0) {
        this.words.forEach(word => {
          const link = `<a  class="wordnet_popup" data-word="${word}">${word}</a>`
          this.lesson.main_text = this.lesson.main_text.replace(word, link)
        })
      }
      const rightAnswer = '<span class="right-answer possible-answer">'
      const wrongAnswer = '<span class="wrong-answer possible-answer">'
      const style = '<style>.possible-answer:hover{background-color:red; cursor:pointer;}</style>'
      const newText = this.lesson.main_text
        .replace('[**', rightAnswer)
        .replace('**]', '</span>')
        .replace('[***', wrongAnswer)
        .replace('***]', '</span>') + style
      return newText
    },
  },
  watch: {
    // getWords() {
    //   useJwt.getWordNetsForPage(this.lesson.page_id).then(res => {
    //     Object.values(res.data.data).forEach(word => {
    //       this.words.push(word.word)
    //     })
    //   })
    // },
  },
  mounted() {
    this.getWordNets()
  },
  methods: {
    handleClick(e) {
      if (e.target.matches('.wordnet_popup, .wordnet_popup *')) {
        const word = e.target.getAttribute('data-word')
        this.$emit('open-translation-modal', word)
      }
    },

    getWordNets() {
      useJwt.getWordNetsForPage(this.pageId).then(res => {
        Object.values(res.data.data).forEach(word => {
          this.words.push(word.word)
        })
      })
    },
  },
}
</script>

<style lang="css" scoped>
/* Bootstrap and Google Font */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;900&display=swap");
/* Global CSS */
body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
/* Header */
header {
  padding: 10px 0px;
}
header h2 {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 3px;
}
/* BG Image */
.bg-image {
  background-color: #ffffff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.list-style-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
.list-style-wrapper p {
  font-weight: 600;
}
.list-style {
  text-align: center;
  width: 100%;
  float: left;
}
.list-style-top {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #29658f;
  padding: 10px;
}
.list-style-bottom {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #e8937d;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1px;
}
.list-style-top p {
  font-size: 16px;
  line-height: normal;
  margin-bottom: 0px;
}
.list-style-bottom p {
  color: #000000;
}
.list-style-2 {
  float: right;
}
.list-style-box-2 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.legend {
  margin-top: 15px;
  text-align: center;
}
.legend p {
  margin-bottom: 5px;
  font-weight: 600;
}
.legend .list-inline li.list-inline-item {
  font-weight: 700;
}
.legend .list-inline li:first-child span {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #29658f;
  display: inline-block;
  margin-right: 5px;
}
.legend .list-inline li:last-child span {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #e8937d;
  display: inline-block;
  margin-right: 5px;
}
.border-radius-img {
  border: 5px solid #29658f;
  border-radius: 100px;
}
.question-img p {
  font-style: italic;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 8px;
  color: #ffffff;
}
footer {
  background: #29658f;
  padding: 20px;
}
.content-left {
  padding: 25px;
}
.content-left p {
  font-size: 18px;
  /* font-weight: 600; */
  margin-bottom: 35px;
}
.content-left span {
  color: #3ab66e;
}
.question-box {
  background: #29658f;
  padding: 15px 15px 15px 20px;
  margin: 15px 20px;
}
.question-box p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.question-ans {
  border-radius: 45px;
  margin: 45px 25px;
  padding: 35px;
}
.question-ans p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
  font-weight: 600;
}
.question-ans hr {
  border: 1px solid #ffffff;
  margin: 30px 0px;
}
.question-ans hr:last-child {
  margin-bottom: 0;
}
.right-answer:hover {
  background-color: red !important;
}
@page {
  margin: 0;
}
@media print {
  .container {
    padding: 0;
    max-width: 100%;
  }
  .container {
    padding: 0;
    max-width: 100%;
  }
}

@media print {
  .col-print-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media print {
  .col-print-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

@media print {
  .col-print-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media print {
  .col-print-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media print {
  .col-print-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

@media print {
  .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media print {
  .col-print-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
}

@media print {
  .col-print-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media print {
  .col-print-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media print {
  .col-print-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

@media print {
  .col-print-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
}

@media print {
  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Responsive Media Queries  */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
/* Extra large devices (Extra large desktops, 1366px and up) */
@media (min-width: 1366px) {
}
/* Extra large devices (Extra large desktops, 1440px and up) */
@media (min-width: 1440px) {
}
/* Extra large devices (Extra large desktops, 1600px and up) */
@media (min-width: 1600px) {
}
/* Extra large devices (Extra large desktops, 1680 and up) */
@media (min-width: 1680px) {
}
/* Extra large devices (Extra large desktops, 1920 and up) */
@media (min-width: 1920px) {
}

/* Extra large devices (large desktops, 1200px and Down) */
@media (max-width: 1199.98px) {
  .list-style {
    width: 200px;
  }
}

/* Large devices (desktops, 992px and Down) */
@media (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and Down) */
@media (max-width: 767.98px) {
  .content-left p {
    font-size: 18px;
    line-height: 26px;
  }
}

/* Small devices (landscape phones, 576px and Down) */
@media (max-width: 575.98px) {
  .list-style {
    float: none;
    width: auto;
    max-width: 100%;
    margin-bottom: 25px;
  }
  .list-style-box-2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border-radius-img {
    border-radius: 30px;
  }
  .question-ans {
    border-radius: 35px;
    padding: 30px;
  }
}

/* Max Width */
/* Small devices (landscape phones, 480px and down) */
@media (max-width: 480px) {
}

/* Small devices (landscape phones, 414px and down) */
@media (max-width: 414px) {
}

/* Small devices (landscape phones, 384px and down) */
@media (max-width: 384px) {
}

/* Small devices (landscape phones, 360px and down) */
@media (max-width: 360px) {
}

/* Small devices (landscape phones, 360px and down) */
@media (max-width: 320px) {
}
</style>

<style lang="css">
.lesson-page ul li,
.lesson-page ol li {
  list-style: inherit !important;
}
</style>
