<template>
  <b-row>
    <b-col id="lesson-list"
           md="3 mt-2 lesson-list"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-1 btn-block"
        @click="toggleCreateModal('create-course', 'createCourse')"
      >
        {{ $t("actions.create-course") }}
      </b-button>

      <b-row>
        <b-col md="6">
          <b-col
            md="12"
            sm="12"
            class="p-0"
          >
            <b-form-group>
              <!-- <b-form-select
                v-model="filterGrade"
                :placeholder="$t('labels.grade-filter')"
                :options="grades"
                type="text"
              /> -->

              <v-select
                v-model="filterGrade"
                :reduce="text => text.value"
                :placeholder="$t('courses-module.please-select-grades')"
                :options="filterGrades"
                label="text"
                multiple
                @input="getSubjectsByGrade"
              />
            </b-form-group>
          </b-col>
        </b-col>
        <b-col md="6">
          <b-col
            md="12"
            sm="12"
            class="p-0"
          >
            <b-form-group>
              <!-- <b-form-select
                v-model="filterSubject"
                :placeholder="$t('labels.grade-filter')"
                :options="filterSubjects"
                type="text"
              /> -->
              <v-select
                v-model="filterSubject"
                :reduce="text => text.value"
                :placeholder="$t('courses-module.please-select-subjects')"
                :options="filterSubjects"
                label="text"
                multiple
                @input="getCourses"
              />
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>
      <!-- collapse -->
      <draggable
        v-if="courses.length"
        v-model="courses"
        :options="{animation:300,}"
        handle=".handle"
        @change="updateOrder('course')"
      >
        <b-card
          v-for="(cou, index) in courses"
          :key="index"
          class="mb-1 p-0"
          @click="course_id=cou.id"
        >
          <b-card-body class="p-0">
            <div
              :ref="'course-collapse-'+cou.id"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-toggle="'collapse-' + index"
              class="lead collapse-title"
            >
              <div
                class="d-flex"
                @click="selectSubjectId(cou.subject_id)"
              >

                <div class="mr-auto p-0 d-flex align-items-center">
                  <feather-icon
                    icon="AlignJustifyIcon"
                    size="18"
                    class="text-body align-middle m-0 p-0 handle mr-2"
                    @dragstart="(e)=>e.stopPropagation()"
                    @click="(e)=>e.stopPropagation()"
                  />
                  {{ cou.name }}
                </div>
                <div
                  v-if="canEdit(cou.created_by)"
                  class="p-0"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    class="p-0"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle m-0 p-0"
                      />
                    </template>
                    <b-form-checkbox
                      :checked="cou.is_public === 1"
                      class="custom-control-primary p-1"
                      name="check-button"
                      switch
                      @change="togglePublic(cou.id, cou.is_public, 'course')"
                      @click="(e)=>e.stopPropagation()"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                      <span class="position-absolute">{{ $t('courses-module.publish') }}</span>
                    </b-form-checkbox>
                    <b-dropdown-item
                      @click="
                        toggleCreateModal(
                          'create-lesson-group',
                          'createLessonGroup',
                          cou.id
                        )
                      "
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Add-New') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        toggleUpdateModal({
                          id: cou.id,
                          itemValue: cou.name,
                          mode: 'update-course',
                          funct: 'updateCourse',
                          subjectId: cou.subject_id,
                          gradeId: cou.subject.grade_id,
                          description: cou.description,
                          weightage: cou.weightage,
                          image: cou.image,
                          access_type: cou.access_type,
                          skillWeightage: cou.skill_weightage,
                          languageLevelIndicator: cou.lang_level_indicator,
                          locale: cou.locale
                        })
                      "
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('Edit') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        toggleDeleteModal(
                          cou.id,
                          'delete-course',
                          'deleteCourse'
                        )
                      "
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('actions.delete') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </b-card-body>

          <b-collapse
            :id="`collapse-` + cou.id"
            :ref="`collapse-` + cou.id"
            :visible="cou.id === course_id"
            class="mt-2 pr-1"
          >
            <b-list-group>
              <draggable
                v-model="cou.lesson_groups"
                :options="{animation:300}"
                handle=".lesson-group-handle"
                @change="updateOrder('course-group')"
              >
                <template v-for="(lesson_group, lesson_index) in cou.lesson_groups">
                  <b-list-group-item
                    :key="lesson_index"
                    class="pr-0"
                    :class="{ active: lesson_group.id === selected_id }"
                    @click="checkLessonGroupPermission(lesson_group.id) && getLesson(lesson_group.id)"
                  >
                    <div
                      class="d-flex"
                    >
                      <div class="mr-auto p-0 d-flex align-items-center">
                        <feather-icon
                          icon="AlignJustifyIcon"
                          size="16"
                          class="text-body align-middle m-0 p-0 lesson-group-handle mr-2"
                        />
                        {{ lesson_group.name }}
                      </div>
                      <div class="p-0">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          class="p-0"
                        >
                          <template #button-content>
                            <feather-icon
                              :icon="checkLessonGroupPermission(lesson_group.id) ? 'MoreVerticalIcon' : 'LockIcon'"
                              size="16"
                              class="text-body align-middle m-0 p-0"
                            />
                          </template>
                          <template v-if="canEdit(lesson_group.created_by)">
                            <b-form-checkbox
                              :checked="lesson_group.is_public === 1"
                              class="custom-control-primary p-1"
                              name="check-button"
                              switch
                              @change="togglePublic(lesson_group.id, lesson_group.is_public, 'lesson-group')"
                            >
                              <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                              </span>
                              <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                              </span>
                              <span class="position-absolute">{{ $t('courses-module.publish') }}</span>
                            </b-form-checkbox>
                            <b-dropdown-item
                              v-if="isSuperUser"
                              @click="
                                editPermissions({module:'lessonGroups','method':'show','id':lesson_group.id,'varient': 'primary',action: 'allow'})
                              "
                            >
                              <feather-icon
                                icon="SlidersIcon"
                                class="mr-50"
                              />
                              <span>{{ $t('courses-module.permission') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="checkLessonGroupPermission(lesson_group.id)"
                              @click="
                                toggleUpdateModal({
                                  id: lesson_group.id,
                                  itemValue: lesson_group.name,
                                  mode: 'update-lesson-group',
                                  funct: 'updateLessonGroup',
                                  subjectId: lesson_group.subject_id,
                                  courseId: cou.id,
                                })
                              "
                            >
                              <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                              />
                              <span>{{ $t('courses-module.edit') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-if="isSuperUser"
                              @click="
                                toggleDeleteModal(
                                  lesson_group.id,
                                  'delete-group-lesson',
                                  'deleteLessonGroup'
                                )
                              "
                            >
                              <feather-icon
                                icon="TrashIcon"
                                class="mr-50"
                              />
                              <span>{{ $t('courses-module.delete') }}</span>
                            </b-dropdown-item>
                          </template>
                        </b-dropdown>
                      </div>
                    </div>
                  </b-list-group-item>
                </template>
              </draggable>
            </b-list-group>
          </b-collapse>
        </b-card>
      </draggable>
      <b-row
        v-else-if="!courses.length && filterSubject.length>0"
        class="px-2"
      >
        <b-card class="w-100">
          <span> {{ $t("messages.no-data-available") }}</span>
        </b-card>
      </b-row>

      <b-skeleton-wrapper v-else>
        <b-card
          v-for="i in 5"
          :key="i"
        >
          <b-skeleton
            width="95%"
            height="50px"
          />
        </b-card>
      </b-skeleton-wrapper>
      <!--/ collapse -->
    </b-col>
    <!-- list of course table -->
    <b-col
      md="9"
      class="mt-2"
    >
      <lesson-table
        :lesson-group-id="selected_id"
        :subject-id="selected_subject_id"
        :courses="courses"
        :course-id="course_id"
        @editPermissions="editPermissions"
        @removedLesson="removedLesson"
      />

    </b-col>

    <!-- lesson permissions -->
    <permission-modal
      :id="permissionModalData ? permissionModalData.id:''"
      :module="permissionModalData ? permissionModalData.module: ''"
      :method="permissionModalData ? permissionModalData.method:''"
      :show="permissionModalData!==null"
      :action-type="permissionModalData ? permissionModalData.action: 'block'"
      :modal-varient="permissionModalData ? permissionModalData.varient: ''"
      @close="permissionModalData=null"
    />

    <!-- create  -->
    <b-modal
      id="modal-create"
      ref="modal-create"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.' + modalMode)"
      @close="resetForm"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer ref="createItemForm">
          <b-form
            class="auth-login-form"
            @submit.prevent="createItem(modalFunction)"
          >
            <b-row>
              <!-- name -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.' + modalMode)"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="newItem.name"
                      name="item_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- name -->

              <!-- Grade -->
              <b-col
                v-if="modalFunction === 'createCourse'"
                md="12"
              >
                <b-form-group
                  :label="$t('standards.name.grade')"
                  label-for="grade"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="grade"
                    vid="grade"
                    rules="required"
                  >
                    <b-form-select
                      id="grade"
                      v-model="grade"
                      :options="grades"
                      :state="errors.length > 0 ? false : null"
                      name="grade"
                      @change="(id)=>getStandardSubjectsOfGrade(id)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Grade -->

              <!-- Subjects -->
              <b-col
                v-if="modalFunction === 'createCourse'"
                md="12"
              >
                <b-form-group
                  :label="$t('standards.name.subject')"
                  label-for="subject"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="subject"
                    vid="subject"
                    rules="required"
                  >
                    <b-spinner
                      v-if="isSubjectProcessing"
                    />
                    <b-form-select
                      v-else
                      id="subject"
                      v-model="subject"
                      :options="subjects"
                      :state="errors.length > 0 ? false : null"
                      :disabled="!grade"
                      name="subject"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Subjects -->
              </b-col>

              <!-- Description -->
              <b-col
                v-if="modalFunction === 'createCourse'"
                md="12"
              >
                <b-form-group label="Description">
                  <b-form-textarea
                    v-model="newItem.description"
                    placeholder="Enter a description"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="modalFunction === 'createCourse'"
                md="12"
              >
                <b-form-group label="Preferred Language">
                  <b-form-select
                    v-model="newItem.locale"
                    :options="localeOptions"
                    text-field="name"
                    value-field="locale"
                    placeholder="Enter a description"
                  />
                </b-form-group>
              </b-col>

              <!-- Image -->
              <b-col
                v-if="modalFunction === 'createCourse'"
                md="12"
              >
                <b-form-group :label="$t('labels.create-lesson-access-type')">
                  <b-form-select
                    v-model="newItem.access_type"
                    :options="access_types"
                    name="access-type"
                  />
                </b-form-group>
                <b-form-group label="Image">
                  <b-form-file
                    v-model="newItem.image"
                    :browse-text="$t('browseButton')"
                    :placeholder="$t('issue-report-module.choose-a-file')"
                    drop-placeholder="Drop image here..."
                    accept="image/*"
                    @input="onImageChange"
                  />
                </b-form-group>

                <!-- Image Preview -->
                <div
                  v-if="newItem.image"
                  class="img-preview text-center mt-1"
                >
                  <h6 class="text-left">
                    Image Preview
                  </h6>
                  <img
                    v-if="typeof(newItem.image) === 'string'"
                    :src="newItem.image"
                  >
                  <img
                    v-else
                    :src="setImagePreview"
                    alt=""
                  >
                </div>
              </b-col>
              <!-- Image -->
            </b-row>
            <course-weighage
              v-if="modalFunction === 'createCourse'"
              v-model="skillWeightage"
            />
            <LangLevelIndicator v-if="modalFunction === 'createCourse'"
                                v-model="languageLevelIndicator"
            />
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="createItem(modalFunction)"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions." + modalMode) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- create  -->

    <!-- update  -->
    <b-modal
      id="modal-update"
      ref="modal-update"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.' + modalMode)"
      @close="resetForm"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer ref="updateItemForm">
          <b-form
            class="auth-login-form"
            @submit.prevent="updateItem(modalFunction)"
          >
            <button
              type="submit"
              class="d-none"
            />
            <b-row>
              <!-- name -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.' + modalMode)"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="newItem.name"
                      name="item_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- name -->

              <!-- Grade -->
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group
                  :label="$t('standards.name.grade')"
                  label-for="grade"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="grade"
                    vid="grade"
                    rules="required"
                  >

                    <b-form-select
                      id="grade"
                      v-model="grade"
                      :options="modalFunction === 'updateCourse' ? grades: domains"
                      :state="errors.length > 0 ? false : null"
                      name="grade"
                      @change="(id)=>getStandardSubjectsOfGrade(id)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Grade -->

              <!-- Subjects -->
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group
                  :label="$t('standards.name.subject')"
                  label-for="subject"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="subject"
                    vid="subject"
                    rules="required"
                  >
                    <b-spinner
                      v-if="isSubjectProcessing"
                    />
                    <b-form-select
                      v-else
                      id="subject"
                      v-model="subject"
                      :options="modalFunction === 'updateCourse' ? subjects: domains"
                      :state="errors.length > 0 ? false : null"
                      :disabled="!grade"
                      name="subject"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group :label="$t('labels.create-lesson-access-type')">
                  <b-form-select
                    v-model="newItem.access_type"
                    :options="access_types"
                    name="access-type"
                  />
                </b-form-group>
              </b-col>
              <!-- Subjects -->
              <!-- Description -->
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group label="Description">
                  <b-form-textarea
                    v-model="newItem.description"
                    placeholder="Enter a description"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group label="Preferred Language">
                  <b-form-select
                    v-model="newItem.locale"
                    :options="localeOptions"
                    text-field="name"
                    value-field="locale"
                    placeholder="Enter a description"
                  />
                </b-form-group>
              </b-col>
              <!-- Description -->

              <!-- Image -->
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group label="Image">
                  <b-form-file
                    v-model="newItem.image"
                    :browse-text="$t('browseButton')"
                    :placeholder="$t('issue-report-module.choose-a-file')"
                    drop-placeholder="Drop image here..."
                    accept="image/*"
                    @input="onImageChange"
                  />
                </b-form-group>

                <!-- Image Preview -->
                <div
                  v-if="newItem.image"
                  class="img-preview text-center mt-1"
                >
                  <h6 class="text-left">
                    Image Preview
                  </h6>
                  <img
                    v-if="typeof(newItem.image) === 'string'"
                    :src="newItem.image"
                  >
                  <img
                    v-else
                    :src="setImagePreview"
                    alt=""
                  >
                </div>
              </b-col>
              <!-- Image -->
            </b-row>
            <course-weighage
              v-if="modalFunction === 'updateCourse'"
              v-model="skillWeightage"
            />
            <LangLevelIndicator v-if="modalFunction === 'updateCourse'"
                                v-model="languageLevelIndicator"
            />
            <!-- Tags -->
            <b-row>
              <b-col
                v-if="modalFunction === 'updateCourse'"
                md="12"
              >
                <b-form-group
                  :label="`Tags`"
                  label-for="item-tag"
                >
                  <b-form-tags
                    id="item_tag"
                    v-model="tags"
                    name="item_tags"
                    placeholder="Tags"
                    separator=" ,;"
                    @input="updateTags()"
                  />
                </b-form-group>
                <small>Seperate Tags by comma ",". eg: Tag1, Tag2</small>
              </b-col>
            </b-row>
            <!-- Tags -->
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="updateItem(modalFunction)"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions." + modalMode) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- update  -->

    <!-- delete item -->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.' + modalDeleteMode)"
      @ok="deleteItem"
    >
      <b-card-text>
        {{ $t("questions." + modalDeleteMode) }}
      </b-card-text>
      <template #modal-footer="{ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete item -->
  </b-row>
</template>
<script>
import {
  BRow,
  BButton,
  BCol,
  VBToggle,
  BForm,
  BSpinner,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCardText,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BCollapse,
  BCard,
  BCardBody,
  BFormCheckbox,
  BSkeletonWrapper,
  BSkeleton,
  BFormTextarea,
  BFormFile,
  BFormTags,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import draggable from 'vuedraggable'
import locales from '@/const/locales'
import PermissionModal from '@views/super/permissions/components/PermissionModal.vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_SUPER } from '@/const/userType'
// import SKILL_TYPE from '@/const/skillTypes'
import { cefrLangLevel } from '@/const/langLevel'
import LessonTable from './LessonTable.vue'
import CourseWeighage from './components/Weighage.vue'
import LangLevelIndicator from './components/LangLevelIndicator.vue'

export default {
  components: {
    LessonTable,
    BButton,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCardText,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BCard,
    BCardBody,
    draggable,
    BFormCheckbox,
    BSkeletonWrapper,
    BSkeleton,
    BFormTextarea,
    BFormFile,
    PermissionModal,
    vSelect,
    CourseWeighage,
    LangLevelIndicator,
    BFormTags,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === `/${this.self.usertype}/problem` && to.query?.lesson_id) {
      localStorage.setItem('prevPage', JSON.stringify({ course_id: this.course_id, lesson_group_id: this.selected_id, selected_subject_id: this.selected_subject_id }))
    }
    next()
  },
  data() {
    return {
      isProcessing: false,
      newItem: {},
      deleteItemIndex: null,
      searchTerm: '',
      lesson_group_id: null,
      course_id: null,
      courses: [],
      modalMode: null,
      modalFunction: null,
      modalDeleteMode: null,
      itemValue: null,
      selected_id: null,
      selected_subject_id: null,
      subject: null,
      grade: null,
      course: null,
      weightage: null,
      domains: [{ value: null, text: 'Please select a domain' }],
      grades: [{ value: null, text: 'Please select a grade' }],
      subjects: [],
      isSubjectProcessing: false,
      permissionModalData: null,
      allowdedCourses: [],
      allCoursesAllowded: false,
      filterGrade: [],
      filterGrades: [],
      filterSubject: [],
      filterSubjects: [],
      self: getUserData(),
      skillWeightage: {},
      languageLevelIndicator: cefrLangLevel,
      access_types: [
        { value: null, text: 'Please select an Access Type' },
        { value: 'trial', text: 'Trial' },
        { value: 'full_access', text: 'Paid' },
      ],
      localeOptions: locales,
      tags: '',
    }
  },
  computed: {
    isSuperUser() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    setImagePreview() {
      if (this.newItem.image) return URL.createObjectURL(this.newItem.image)
      return null
    },
    lessonGroupId() {
      return this.$route.query.lesson_group_id
    },
  },

  mounted() {
    this.getCourses()
  },
  created() {
    this.lesson_group_id = this.$route.params.id
    this.getAuthenticatedLessonGroup()
    useJwt.getStandardGrades({ type: 'lesson', library: this.$route.query.library })
      .then(res => {
        const filteredResponse = res.data.data.map(grade => ({
          value: grade.id, text: grade.name,
        }))
        this.grades = [...this.grades, ...filteredResponse]
        this.filterGrades = filteredResponse
      })
    useJwt.getDomain()
      .then(res => {
        this.domains = [...this.domains, ...res.data.data.data.map(domain => ({
          value: domain.id, text: domain.name,
        }))]
      })
  },
  methods: {
    async getAuthenticatedLessonGroup() {
      const permission = await useJwt.getCoursesPermissions()
      this.allowdedCourses = permission.data.data.permissions
      this.allCoursesAllowded = permission.data.data.allow
    },
    checkLessonGroupPermission(id) {
      if (this.allCoursesAllowded) return true
      const check = this.allowdedCourses.find(arr => Number(arr.permission.gid) === id)
      return check ? check.allow : false
    },
    getSubjectsByGrade() {
      useJwt.getStandardSubjectsOfGrade(this.filterGrade, true).then(res => {
        this.filterSubjects = []
        this.filterSubject = []
        res.data.data.map(sub => this.filterSubjects.push({ value: sub.id, text: sub.name }))
      })
    },

    removedLesson() {
      this.selected_id = null
    },
    editPermissions(data) {
      this.permissionModalData = data
    },
    selectSubjectId(id) {
      this.selected_subject_id = id
    },
    getCourses() {
      useJwt.getCourses(this.filterSubject, {
        params: {
          library: this.$route.query.library || this.$route.params.library,
        },
      }).then(response => {
        this.courses = response.data.data
      }).then(() => {
        if (localStorage.getItem('prevPage')) {
          const selectedCache = JSON.parse(localStorage.getItem('prevPage'))
          this.course_id = selectedCache.course_id
          this.selected_id = selectedCache.lesson_group_id
          this.selected_subject_id = selectedCache.selected_subject_id
          this.getLesson(selectedCache.lesson_group_id)
          localStorage.removeItem('prevPage')
        }

        if (this.lessonGroupId) {
          const courseOfLessonGroup = this.courses.find(course => course.lesson_groups.find(lessonGroup => lessonGroup.id === Number(this.lessonGroupId)))
          this.course_id = courseOfLessonGroup.id
          const courseElement = document.getElementById(`collapse-${courseOfLessonGroup.id}`)
          const scrollbar = document.getElementById('lesson-list')
          setTimeout(() => {
            const courseElementTop = courseElement.getBoundingClientRect().top
            const courseElementHeight = courseElement.getBoundingClientRect().height
            scrollbar.scroll({
              top: courseElementTop - courseElementHeight,
              behavior: 'smooth',
            })
            this.selected_id = Number(this.lessonGroupId)
            if (this.checkLessonGroupPermission(this.selected_id)) this.getLesson(this.selected_id)
          }, 1000)
        }
      })
    },

    getStandardSubjectsOfGrade(id, subjectId) {
      this.isSubjectProcessing = true
      this.subject = subjectId || null
      useJwt.getStandardSubjectsOfGrade(id)
        .then(res => {
          this.subjects = res.data?.data?.map(sub => ({
            value: sub.id, text: sub.name,
          }))
          if (res.data?.data?.length) {
            this.subjects.unshift({ value: null, text: 'Please select a subject' })
          } else this.subjects.unshift({ value: null, text: 'Selected grade has no subject' })
        }).finally(() => {
          this.isSubjectProcessing = false
        })
    },

    resetForm() {
      this.grade = null
      this.subject = null
      this.subjects = null
      this.weightage = null
      this.newItem.name = null
      this.newItem.locale = 'en'
    },

    getLesson(id) {
      this.selected_id = id
      this.$root.$emit('getLesson', id)
    },
    canEdit(ownerId) {
      return this.isSuperUser || Number(this.self.id) === Number(ownerId)
    },
    listItem() {
    },

    togglePublic(id, isPublic, mode) {
      const args = { id, is_public: !isPublic ? 1 : 0, model: mode }
      useJwt.changeVisibility(args)
        .then(res => {
          this.showSuccessMessage(res)
        })
        .finally(() => {
          this.getCourses()
        })
    },

    toggleCreateModal(mode, funct, courseId) {
      this.course_id = courseId
      this.modalFunction = funct
      this.modalMode = mode
      this.weightage = null
      this.newItem = {}
      this.skillWeightage = {}
      this.$refs['modal-create'].toggle()
    },

    toggleUpdateModal({
      id, itemValue, mode, funct, subjectId, courseId, gradeId, description, image, skillWeightage, languageLevelIndicator, access_type, locale,
    }) {
      this.newItem = {
        id, name: itemValue, description, image, access_type, locale,
      }
      this.modalFunction = funct
      this.modalMode = mode
      this.$refs['modal-update'].toggle()
      this.subject = subjectId
      this.course = courseId || null
      this.grade = gradeId || null

      const weighage = {}
      if (skillWeightage && Array.isArray(skillWeightage)) {
        skillWeightage.forEach(skill => {
          weighage[skill.skill_type] = skill.value
        })
      }
      if (languageLevelIndicator) {
        this.languageLevelIndicator = languageLevelIndicator
      } else {
        this.languageLevelIndicator = cefrLangLevel
      }

      this.skillWeightage = weighage

      if (gradeId) {
        this.getStandardSubjectsOfGrade(gradeId, subjectId)
      }

      if (this.modalFunction === 'updateCourse') {
        this.getCoursesTag(this.newItem.id)
      }
    },

    toggleDeleteModal(index, mode, funct) {
      this.deleteItemIndex = index
      this.modalDeleteMode = mode
      this.modalFunction = funct
      this.$refs['modal-delete'].toggle()
    },

    createItem(mode) {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const form = new FormData()
          Object.entries(this.newItem).forEach(entry => {
            form.append(entry[0], entry[1])
          })
          if (this.selected_id) {
            form.append('lesson_group_id', this.selected_id)
          }
          if (this.course_id) {
            form.append('course_id', this.course_id)
          }
          if (this.weightage) {
            form.append('weightage', this.weightage)
          }
          if (this.modalFunction === 'createCourse') form.append('subject_id', this.subject)

          if (Object.keys(this.skillWeightage).length > 0) {
            form.append('skill_weighage', JSON.stringify(this.skillWeightage))
          }
          form.append('lang_level_indicator', JSON.stringify(this.languageLevelIndicator))

          form.append('library_id', this.$route.query.library)
          useJwt[mode](form)
            .then(response => {
              this.$refs['modal-create'].hide()
              this.newItem = {}
              this.getCourses()
              this.getLesson(this.selected_id)
              this.showSuccessMessage(response)
              this.getAuthenticatedLessonGroup()
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.createItemForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },

    deleteItem() {
      this.isProcessing = true
      useJwt[this.modalFunction](this.deleteItemIndex)
        .then(response => {
          this.$refs['modal-delete'].hide()
          this.getCourses()
          this.userBeingDeletedIndex = null
          this.getLesson(this.selected_id)
          this.showSuccessMessage(response)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    updateItem() {
      this.$refs.updateItemForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const form = new FormData()
          Object.entries(this.newItem).forEach(entry => {
            if (entry[0] === 'image') {
              if (entry[1]) {
                form.append(entry[0], entry[1])
              }
            } else {
              form.append(entry[0], entry[1])
            }
          })
          form.append('course_id', this.course)
          form.append('weightage', this.weightage)
          if (this.languageLevelIndicator) {
            form.append('lang_level_indicator', JSON.stringify(this.languageLevelIndicator))
          }

          if (this.modalFunction === 'updateCourse') form.append('subject_id', this.subject)
          form.append('_method', 'put')
          if (Object.keys(this.skillWeightage).length > 0) {
            form.append('skill_weighage', JSON.stringify(this.skillWeightage))
          }

          useJwt[this.modalFunction](this.newItem.id, form)
            .then(response => {
              this.$refs['modal-update'].hide()
              this.newItem = {}
              this.getCourses()
              this.getLesson(this.selected_id)
              this.showSuccessMessage(response)
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.updateItemForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    updateOrder(type) {
      let input = []
      switch (type) {
        case 'course':
          input = this.courses.map((e, i) => ({ id: e.id, order_index: i + 1 }))
          break
        case 'course-group':
          input = this.courses.find(e => e.id === this.course_id).lesson_groups.map((e, i) => ({ id: e.id, order_index: i + 1 }))
          break
        default:
      }
      useJwt.updateOrder({
        model: type,
        input,
      })
    },
    onImageChange(e) {
      const [file] = e.target.files
      this.newItem.image = file
    },
    updateTags() {
      const tagAsString = this.tags.join(', ')
      useJwt.updateCourseTags(this.newItem.id, { tags: tagAsString })
        .catch(error => {
          console.log(error)
        })
    },
    getCoursesTag(courseId) {
      console.log(courseId)
      useJwt.getCoursesTags(courseId)
        .then(response => {
          this.tags = response.data.data.split(',').map(item => item.toLowerCase())
        }).catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-good-table.scss";

.active {
  background-color: rgba(22,29,49,0.4);
}

.btn-link {
  padding: 0px;
  margin: 0px;
}

.card-body {
  padding-right: 8px;
  user-select: none;
}
.handle,.lesson-group-handle{
  cursor: grab;
}
.lesson-list {
  overflow-y: scroll;
  max-height: 80vh;
}
.img-preview {
  width: 100%;
}
.img-preview img {
  max-width: 100%;
  object-fit: cover;
}
</style>
