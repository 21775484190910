<template>
  <b-overlay :show="isProcessing">
    <gec-dragger
      v-show="chatOpened"
      id="draggable-chat-bot"
      height="224"
      width="300"
      left="70%"
      draggable-section="chatbot-body"
      bottom="10%"
    >
      <section v-show="chatOpened"
               data-v-5e8ea5c2=""
               class="chat-app-window w-100 h-100  mb-2"
               style="background: transparent;"
      >
        <div data-v-5e8ea5c2=""
             class="active-chat w-100 h-100"
        >
          <chat-body ref="chatBotBody"
                     :topics="''"
                     :page-meta-info="[]"
                     :test-stat-id="testStatId"
                     :problem-id="0"
                     :is-speaking="isSpeaking"
                     @onSuccess="$emit('onSuccess')"
          />
        </div>
      </section>
    </gec-dragger>
    <div class="floating-bot-icon"
         draggable="false"
         @click.stop="closeTheBot"
    >
      <feather-icon
        size="40"
        class="text-white"
        icon="XIcon"
      />
    </div>
  </b-overlay>
</template>
<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import GecDragger from '@/layouts/components/GECDragger.vue'
import useJwt from '@/auth/jwt/useJwt'
import { BOverlay } from 'bootstrap-vue'
import ChatBody from './components/ChatBody.vue'

const chatOpened = ref(true)
const props = defineProps({
  modeInfo: {
    type: Object,
    required: true,
  },
  classId: {
    type: Number,
    required: true,
  },
})

const isProcessing = ref(false)
const emits = defineEmits(['close'])
const chatBotBody = ref(null)
const testStatId = ref(null)
const isSpeaking = ref(false)
const root = getCurrentInstance().proxy.$root

const setUpProblem = () => {
  isProcessing.value = true
  useJwt.setupConversationalModeProblem(props.classId, props.modeInfo.lesson_id, {
    page_id: props.modeInfo.page_id,
    set_no: props.modeInfo.set_no,
  }).then(response => {
    chatBotBody.value.getBase64FromTextAndPlay(response.data.data.bot_response)
    testStatId.value = response.data.data.test_id
    isSpeaking.value = response.data.data.preferredSkill === 'oe-speaking'
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}

const closeTheBot = () => {
  emits('close')
}

onMounted(() => {
  setUpProblem()
})

console.log(props)
</script>

<style lang="css">
.floating-bot-icon{
    z-index: 1031;
    background-color: rgb(253, 178, 39);
    padding:10px;
    border-radius:50%;
    cursor:pointer;
    transition: 0.3s;
    position: fixed;
    bottom: 22%;
    right: 5%;
}

</style>
