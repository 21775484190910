<template>
  <div>
    <b-modal
      :visible="showClassroomDetailModal"
      :title="formatClassName(room.name)"
      hide-footer
      @close="$emit('closeClassroomDetailModal')"
      @ok="$emit('closeClassroomDetailModal')"
      @hide="$emit('closeClassroomDetailModal')"
    >
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col
              v-if="!hideNoOfStudent"
              class="border-right"
            >
              <div
                class="d-flex justify-content-between align-items-center"
              >
                <span>
                  <feather-icon icon="UsersIcon" />
                  {{ $t("student-report-module.total-students") }}
                </span>

                <b-badge
                  variant="success"
                  pill
                >
                  {{ totalStudents ? totalStudents : "0" }}
                </b-badge>
              </div>
            </b-col>
            <b-col v-if="!hideTeacher">
              <span>
                <feather-icon icon="UserIcon" />
                {{ $t("class-module.teachers") }}:
              </span>
              <span v-if="room.teacher">
                {{ room.teacher.firstname+' '+room.teacher.lastname }}
              </span>
              <!-- if there are no teachers, show request link -->
              <div v-if="isAStudent && canRequestTeacher">
                <b-overlay
                  :show="requestProcessing"
                  bg-color="dark"
                >
                  <b-link
                    class="font-weight-bold ml-1"
                    @click="$emit('requestTeacher')"
                  >
                    [{{ $t('class-module.request-teacher') }}]
                  </b-link>
                </b-overlay>
              </div>
            </b-col>
          </b-row>

        </b-list-group-item>
        <b-list-group-item
          v-if="room.teachers && !hideCoTeacher"
        >
          <span>
            <feather-icon icon="UsersIcon" />
            {{ $t("class-module.co-teachers") }}:
          </span>

          <!-- if there is teacher, show them -->
          <span v-if="room.teachers.length">
            <span
              v-for="(teacher, index) in ARRAY_UNIQUE_BY_KEY(room.teachers, 'id')"
              :key="teacher.id"
            >
              <span>
                {{ teacher.id == self.id ? $t('you') : teacher.firstname + ' ' + teacher.lastname }}
              </span>
              <span
                v-if="index !== room.teachers.length - 1"
              >,
              </span>
            </span>
          </span>

          <span
            v-else
            class="text-danger"
          >
            {{ $t('class-module.no-teachers-assigned') }}
          </span>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col
              v-if="!hideOwner && room.owner && !isAStudent"
              class="border-right"
            >
              <span>
                <feather-icon icon="UserIcon" />
                {{ $t("class-module.class-table.owner") }}:
                {{
                  room.owner.id == self.id ? $t('you') : room.owner.firstname + " " + room.owner.lastname
                }}
              </span>
            </b-col>
            <b-col
              v-if="room.school && !hideSchool"
            >
              <span>
                <feather-icon icon="BookOpenIcon" />
                {{ $t("class-module.class-table.school") }}:
                {{ room.school.firstname }} {{ room.school.lastname }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="border-right">
              <p class="mb-0">
                <feather-icon icon="CalendarIcon" />
                {{ $t("class-module.start-date") }}:
                {{ room.created_at ? FORMAT_DATE(room.created_at, 'YYYY-MM-DD') : "N/A" }}
              </p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <feather-icon icon="CalendarIcon" />
                {{ $t("class-module.end-date") }}:
                {{ room.completed_at ? FORMAT_DATE(room.completed_at, 'YYYY-MM-DD') : "N/A" }}
              </p>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol,
  BModal, BBadge, BLink,
  BOverlay,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
} from '@/const/userType'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BBadge,
    BLink,
    BOverlay,
    BListGroup,
    BListGroupItem,
  },
  props: {
    showClassroomDetailModal: {
      type: Boolean,
      default: false,
    },
    hideClassroomListButton: {
      type: Number,
      default: 0,
    },
    room: {
      type: Object,
      default: () => {},
    },
    courses: {
      type: Array,
      default: () => [],
    },
    overAllCourseCompletion: {
      type: Number,
      default: 0.0,
    },
    totalStudents: {
      type: Number,
      default: 0,
    },
    hideTeacher: {
      type: Number,
      default: 0,
    },
    hideCoTeacher: {
      type: Number,
      default: 0,
    },
    hideSchool: {
      type: Number,
      default: 0,
    },
    hideOwner: {
      type: Number,
      default: 0,
    },
    requestProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    courseProgress() {
      const value = this.overAllCourseCompletion
      if (value >= 25.0 && value <= 50.0) { return 'warning' }
      if (value > 50 && value <= 75.0) {
        return 'primary'
      }
      if (value > 75.0) {
        return 'success'
      }
      return 'danger'
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    noOfStudentSettings() {
      return {
        distNoOfStudentInfo: this.getDistrictSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
        schoolNoOfStudentInfo: this.getSchoolSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
      }
    },
    hideNoOfStudent() {
      return this.isAStudent && (this.noOfStudentSettings?.distNoOfStudentInfo === '1' || this.noOfStudentSettings?.schoolNoOfStudentInfo === '1')
    },
    isFullAccessWithTutorUser() {
      return this.self.user_subscription_type === 'external_full_access_tutor' || !this.self.user_subscription_type
    },
    canRequestTeacher() {
      return this.isFullAccessWithTutorUser && ((!this.room.teacher || this.room.teacher.lastname.includes('default')) && this.room.reservation_id)
    },
  },
  methods: {
    formatClassName(name) {
      return name?.split('#')[0]
    },
    backToClassRoom() {
      if (window.PREVIOUS_ROUTE_PATH && window.PREVIOUS_ROUTE_PATH.includes('?page')) {
        this.GO_BACK()
      } else {
        this.$router.push({ name: `${this.AUTH_USER().usertype}-classes` })
      }
    },
  },
}
</script>
<style lang="scss">
.classroom-title {
    span {
        font-size: 18px;
        color: #104373;
    }
    .list-class-btn {
        margin-top: -4px;
        width:22px;
        height:22px;
    }
}
@media screen and (max-width: 576px) {
    .classroom-title {
      span {
        font-size: 16px;
        color: #104373;
      }
      .list-class-btn {
        width:22px  !important;
        height:22px;
      }
    }
}
</style>
