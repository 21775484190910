<template>
  <div class="class-part">
    <div class="d-flex justify-content-between class-head-title">
      <div>
        <h5 class="p-1 mb-0">
          <b class="text-bold">{{ $t("class-module.list-of-classes") }} </b>
          <span class="ml-md-1">
            <b-badge
              v-if="!isProcessing"
              pill
              variant="primary"
              :class="isDarkMode ? 'btn-light-dark' : 'bg-pink'"
            >{{ classes.total }}</b-badge>
          </span>
        </h5>
      </div>
      <div
        class="d-flex"
      >
        <div
          v-if="school"
          class="d-flex justify-content-between"
        >
          <h5 class="p-1 mb-0">
            <b class="text-bold">{{ $t("class-module.school") }}</b>: {{ school }}
          </h5>
        </div>
        <b-dropdown
          id="dropdown-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="link"
          toggle-class="text-decoration-none py-0"
          no-caret
          right
          class="pr-0 pl-0"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-left vertical-icon"
            />
          </template>
          <b-dropdown-form
            :disabled="isProcessing"
            style="width: 180px;"
          >
            <span
              class="d-flex"
            >
              <b-form-checkbox
                v-model="showTableView"
                checked="true"
                class="custom-control-primary ml-1"
                name="check-button"
                switch
                :disabled="isProcessing"
                @change="storeTableView"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="AlignJustifyIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="GridIcon" />
                </span>
              </b-form-checkbox>
              <span v-if="showTableView">{{ $t("user-module.table-view") }}</span>
              <span v-else>{{ $t("user-module.card-view") }}</span>
            </span>

          </b-dropdown-form>
          <b-dropdown-divider />
          <b-dropdown-item v-if="canUploadLessonTemplate"
                           v-b-tooltip.hover="'Upload Lesson Template'"
                           @click="$emit('onUploadLessonTemplate')"
          >

            <feather-icon icon="UploadIcon" /> Upload Lesson Template
          </b-dropdown-item>
        </b-dropdown>
      </div>

    </div>
    <div>
      <template v-if="showTableView">
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Class</b-th>
              <b-th v-if="!hideNoOfStudent">
                Total Students
              </b-th>
              <b-th>Progress</b-th>
              <b-th v-if="userLevelChecker(6)">
                School
              </b-th>
              <b-th v-if="!isAStudent">
                Owner
              </b-th>
              <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="cls, index of classes.data"
                  :key="index"
            >
              <b-td>
                <div class="d-flex ">
                  <div
                    style="margin-top: 10px; margin-left: 10px"
                  >
                    <span
                      class="cursor-pointer"
                      @click="viewStudent(cls)"
                    >
                      {{ cls.name?.split('#')[0] }}
                    </span>
                    <a
                      target="_"
                      class="mb-0"
                      @click.prevent="openClassLink(cls.link, cls.id)"
                    >
                      <b-badge
                        v-if="cls.link"
                        v-b-tooltip="$t('labels.live-link')"
                        pill
                        style="padding:4px"
                        variant="dark"
                      >
                        <feather-icon
                          icon="LinkIcon"
                          size="18"
                        />
                      </b-badge>
                    </a>
                  </div>
                </div>
              </b-td>
              <b-td
                v-if="!hideNoOfStudent"
              >
                <div
                  v-if="cls.students"
                  class="total-students"
                >
                  {{ cls.students.length }}
                </div>
                <div v-else
                     class="text-center"
                >
                  <feather-icon
                    disabled
                    icon="EyeOffIcon"
                  />
                </div>
              </b-td>
              <b-td>
                {{ cls.course_completion.toFixed(2) }}
              </b-td>
              <b-td
                v-if="userLevelChecker(6)"
              >
                <div
                  v-if="cls.school"
                >
                  <span
                    v-if="cls.school"
                  >
                    {{ cls.school.fullname }}
                  </span>
                </div>
              </b-td>
              <b-td
                v-if="!isAStudent"
              >
                <div
                  v-if="cls.owner"
                >
                  {{ cls.owner.id == self.id ? $t('you') : cls.owner.fullname }}
                </div>
                <div v-else>
                  <feather-icon
                    disabled
                    icon="EyeOffIcon"
                  /></div>
              </b-td>
              <b-td>
                <div class="d-flex">
                  <!-- add students -->

                  <feather-icon
                    v-if="cls.owner?.id == self.id || isHighUser || isATeacher"
                    v-b-tooltip="$t('actions.students')"
                    v-b-modal.modal-add-students
                    class="mr-1 text-success cursor-pointer"
                    style="margin-top:5px"
                    :disabled="isProcessing"
                    icon="PlusCircleIcon"
                    size="18"
                    @click.stop="
                      $emit('addStudent', cls.id)
                    "
                  />
                  <!-- add students -->
                  <!-- calendar -->
                  <feather-icon
                    v-if="(isATeacher || isHighUser || isAStudent) && !isAParent"
                    v-b-tooltip="$t('navigation-menu.calendar')"
                    :class="{ disabled_container: isArchived }"
                    class="ml-1 text-warning cursor-pointer"
                    style="margin-top:5px"
                    :disabled="isProcessing"
                    icon="CalendarIcon"
                    size="12"
                    @click.stop="$emit('onOpenCalendar', cls.id, index)"
                  />
                  <!-- calendar -->
                  <!-- More Options -->
                  <div
                    v-if="!isAParent && ((cls.owner && self.id == cls.owner.id) ||
                      isATeacher || isHighUser || isSchoolUser)"
                    class="col-2 px-0 text-right"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="link"
                      toggle-class="text-decoration-none py-0"
                      no-caret
                      right
                      class="pr-0"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-left vertical-icon"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="(isATeacher || isHighUser) && !isAParent"
                        :disabled="isProcessing"
                        @click.stop="$emit('onEditLink', cls.id, index)"
                      >
                        <feather-icon icon="LinkIcon" />
                        {{ $t("actions.edit-link") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="(cls.owner && self.id == cls.owner.id) || isHighUser || isSchoolUser || isAClassRoomTeacher(cls)"
                        :disabled="isProcessing"
                        @click.stop="$emit('onClassEdit', cls.id, index)"
                      >
                        <feather-icon icon="EditIcon" />
                        {{ $t("actions.edit") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="isATeacher || isHighUser || isSchoolUser"
                        :disabled="isProcessing"
                        @click.stop="$emit('onImportStudent', cls.id, index)"
                      >
                        <feather-icon icon="FileTextIcon" />
                        {{ $t('class-module.import-student') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="(cls.owner && self.id == cls.owner.id) || isHighUser"
                        :disabled="isProcessing"
                        @click.stop="$emit('onClassDelete', cls.id, 'archive')"
                      >
                        <feather-icon icon="ArchiveIcon" />
                        {{ $t("actions.archive") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="(cls.owner && self.id == cls.owner.id) || isHighUser"
                        :disabled="isProcessing"
                        @click.stop="$emit('onClassDelete', cls.id, 'delete')"
                      >
                        <feather-icon icon="TrashIcon" /> {{ $t("actions.delete") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="isArchived && cls.owner.id == self.id"
                        :disabled="isProcessing"
                        @click.stop="confirmRestore(cls.id)"
                      >
                        <feather-icon
                          icon="CornerDownLeftIcon"
                          size="12"
                        /> {{ $t('Restore') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                <!-- More Options -->
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
      <template v-else>
        <b-row v-if="!isProcessing && classes && classes.data">
          <b-col
            v-for="(cls, index) in classes.data"
            :key="cls.id"
            lg="3"
            md="6"
            xl="3"
            sm="12"
            class="pr-md-0 d-flex align-items-stretch"
          >
            <b-card
              v-if="cls"
              class="mb-1 cursor-pointer w-100"
              body-class="pt-1 px-1 pb-0"
              @click="viewStudent(cls)"
            >
              <b-row
                class="header pr-1"
              >
                <b-col
                  class="pr-0"
                >
                  <!-- Class Name -->
                  <div class="d-flex align-items-baseline">
                    <h2
                      class="m-0 p-0 class-title"
                    >
                      <span
                        class="cursor-pointer"
                      >
                        {{ cls.name?.split('#')[0] }}
                      </span>
                      <!-- Live Class Link -->
                      <a
                        target="_"
                        class="mb-0"
                        @click.stop="openClassLink(cls.link, cls.id)"
                      >
                        <b-badge
                          v-if="cls.link"
                          v-b-tooltip="$t('labels.live-link')"
                          pill
                          style="padding:4px"
                          variant="dark"
                        >
                          <feather-icon
                            icon="LinkIcon"
                            size="18"
                          />
                        </b-badge>
                      </a>
                    </h2>
                  </div>

                </b-col>

                <!-- More Options -->
                <b-col
                  v-if="!isAParent && ((cls.owner && self.id == cls.owner.id) ||
                    isATeacher || isHighUser || isSchoolUser)"
                  class="col-2 px-0 text-right"
                  style="margin-top:-10px;"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="link"
                    toggle-class="text-decoration-none pb-0"
                    no-caret
                    right
                    class="pr-0"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-left vertical-icon"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="(isATeacher || isHighUser) && !isAParent"
                      :disabled="isProcessing"
                      @click.stop="$emit('onEditLink', cls.id, index)"
                    >
                      <feather-icon icon="LinkIcon" />
                      {{ $t("actions.edit-link") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="(cls.owner && self.id == cls.owner.id) || isHighUser || isSchoolUser || isAClassRoomTeacher(cls)"
                      :disabled="isProcessing"
                      @click.stop="$emit('onClassEdit', cls.id, index)"
                    >
                      <feather-icon icon="EditIcon" />
                      {{ $t("actions.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="isATeacher || isHighUser || isSchoolUser"
                      :disabled="isProcessing"
                      @click.stop="$emit('onImportStudent', cls.id, index)"
                    >
                      <feather-icon icon="FileTextIcon" />
                      {{ $t('class-module.import-student') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="(cls.owner && self.id == cls.owner.id) || isHighUser"
                      :disabled="isProcessing"
                      @click.stop="$emit('onClassDelete', cls.id, 'archive')"
                    >
                      <feather-icon icon="ArchiveIcon" />
                      {{ $t("actions.archive") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="(cls.owner && self.id == cls.owner.id) || isHighUser"
                      :disabled="isProcessing"
                      @click.stop="$emit('onClassDelete', cls.id, 'delete')"
                    >
                      <feather-icon icon="TrashIcon" /> {{ $t("actions.delete") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="isArchived && cls.owner.id == self.id"
                      :disabled="isProcessing"
                      @click.stop="confirmRestore(cls.id)"
                    >
                      <feather-icon
                        icon="CornerDownLeftIcon"
                        size="12"
                      /> {{ $t('Restore') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <hr
                class="w-100"
              >
              <b-row
                class="body mb-1"
              >
                <b-col
                  class="col-4 p-0 d-flex align-items-center"
                >
                  <!-- <img
                    v-if="cls.image"
                    class="w-100 h-100"
                    style="height: 60px; width:100%"
                    :src="cls.image"
                  >
                  <avatar
                    :username="cls.name"
                    :rounded="false"
                    color="#fff"
                    :size="notResponsiveWindow ? 60 : 55"
                    class="avatar-image d-sm-block"
                    :style="{ width: '100%' }"
                  /> -->
                  <vue-apex-charts
                    :key="index"
                    :options="chartOptions(cls.course_completion)"
                    :series="[cls.course_completion]"
                  />
                </b-col>
                <b-col
                  class="col-8  d-flex align-items-center"
                >
                  <div class="w-100">
                    <!-- students count  -->
                    <div class="d-flex"
                         @click.stop="
                           $emit('addStudent', cls.id)
                         "
                    >
                      <feather-icon
                        v-if="cls.owner?.id == self.id || isHighUser || isATeacher"
                        v-b-tooltip="$t('actions.students')"
                        v-b-modal.modal-add-students
                        variant="outline-danger outline-pink"
                        :disabled="isProcessing"
                        class="p-0 cursor-pointer text-success"
                        icon="PlusCircleIcon"
                        size="18"
                        style="margin-top:-2px; margin-right: 5px"
                      />
                      <h5
                        v-if="cls.students && !hideNoOfStudent"
                        v-b-modal.modal-add-students
                        class="total-students"
                      >
                        {{ $t("actions.students") }}:
                        {{ cls.students.length }}
                      </h5>
                    </div>
                    <!-- students count  -->
                    <!-- calendar -->
                    <div class="d-flex"
                         @click.stop="
                           $emit('onOpenCalendar', cls.id, index)
                         "
                    >
                      <feather-icon
                        v-if="(isATeacher || isHighUser || isAStudent) && !isAParent"
                        v-b-tooltip="$t('navigation-menu.calendar')"
                        :class="{ disabled_container: isArchived }"
                        class="p-0 cursor-pointer text-warning"
                        size="18"
                        style="margin-top:-2px; margin-right: 5px"
                        :disabled="isProcessing"
                        icon="CalendarIcon"
                      />
                      <h5
                        class="total-students"
                      >
                        {{ $t("navigation-menu.calendar") }}
                      </h5>
                    </div>
                    <!-- calendar -->

                    <div class="class-action d-flex justify-content-end">
                      <!-- Owner -->
                      <div
                        v-if="cls.owner && !isAStudent"
                        style="margin-right: 5px"
                      >
                        <feather-icon
                          v-if="(isATeacher || isHighUser || isAStudent) && !isAParent"
                          v-b-tooltip="`${$t('class-module.class-table.owner')}: ${cls.owner.id == self.id ? $t('you') : cls.owner.fullname}`"
                          class="p-0 cursor-pointer text-primary"
                          size="18"
                          style="margin-top:-2px; margin-right: 5px"
                          :disabled="isProcessing"
                          icon="UserIcon"
                        />
                      </div>
                      <!-- Owner -->
                      <!-- School detail -->
                      <div
                        v-if="userLevelChecker(6) && cls.school"
                      >
                        <feather-icon
                          v-b-tooltip="`${$t('class-module.class-table.school')}: ${cls.school.fullname}`"
                          class="p-0 cursor-pointer text-info"
                          size="18"
                          style="margin-top:-2px; margin-right: 5px"
                          :disabled="isProcessing"
                          icon="BookIcon"
                        />

                      </div>
                      <!-- School detail -->
                    </div>
                  </div>
                </b-col>

              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row
          v-if="
            classes && !isProcessing && classes.data && classes.data.length === 0
          "
        >
          <b-col
            md="12"
            class="text-center"
          >
            <b-card>
              <span class="h4 text-dark-blue">{{
                $t("messages.no-data-available")
              }}</span>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="isProcessing"
            class="text-center"
          >
            <b-card>
              <b-spinner />
            </b-card>
          </b-col>
        </b-row>
      </template>

    </div>
    <confirm-restore-modal
      :show="restoreParams.show || false"
      varient="primary"
      :is-processing="restoreParams.isProcessing"
      :title="restoreParams.title"
      :sub-title="restoreParams.subTitle"
      :on-delete="handleRestore"
      @close="restoreParams = {}"
    />
    <b-modal
      :visible="!!redirectingLink"
      content-class="shadow"
      :title="$t('class-module.opening')"
      no-fade
      ok-only
      ok-title="Accept"
      hide-footer
      @hide="redirectingLink = null"
      @close="redirectingLink = null"
    >
      <b-overlay
        v-if="!isAttendanceDone"
        show
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        {{ $t('class-module.redirecting-to') }} {{ redirectingLink }}
      </b-overlay>
      <div v-else>
        <a :href="redirectingLink">
          Click here to join the live class if it does not redirect automatically.
        </a>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  // BButton,
  BBadge,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSpinner,
  BModal,
  BOverlay,
  // BProgress,
  // BProgressBar,
  BFormCheckbox,
  BTableSimple,
  BTr, BTd, BThead, BTbody, BTh,
  BDropdownForm,
} from 'bootstrap-vue'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import {
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  USER_TYPE_PARENT,
  USER_TYPE_SCHOOL,
  USER_TYPE_SUPER,
} from '@/const/userType'
import Ripple from 'vue-ripple-directive'
// import Avatar from 'vue-avatar'
import {
  CLASS_TYPE_DEFAULT,
  CLASS_TYPE_CUSTOM,
  CLASS_TYPES,
} from '@/const/classType'
import VueApexCharts from 'vue-apexcharts'
import ConfirmRestoreModal from '@/views/common/components/DeleteModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { isVisible } from '@/utils/visibilitySettings'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    // BButton,
    BBadge,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BDropdownDivider,
    // Avatar,
    BSpinner,
    ConfirmRestoreModal,
    BModal,
    BOverlay,
    // BProgress,
    // BProgressBar,
    BFormCheckbox,
    BTableSimple,
    BTr,
    BTd,
    BThead,
    BTbody,
    BTh,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  props: {
    classes: {
      type: [Object, Array],
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    selectedClassType: {
      type: String,
      default: '',
    },
    school: {
      type: String,
      default: '',
    },
    canUploadLessonTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedClass: null,
      self: getUserData(),
      tabIndex: 0,
      classTypes: CLASS_TYPES,
      classTypeDefault: CLASS_TYPE_DEFAULT,
      classTypeCustom: CLASS_TYPE_CUSTOM,
      restoreParams: {},
      userLevelChecker: isHighLevelUser,
      redirectingLink: null,
      isAttendanceDone: false,
      showTableView: false,
      // hideCourses: true,
      tableColumns: [
        {
          label: 'Class',
          field: 'class_name',
          sortable: false,
        },
        {
          label: 'Total Students',
          field: 'total_students',
          sortable: false,
        },
        {
          label: 'Progress',
          field: 'progress',
          sortable: false,
        },
        {
          label: 'School',
          field: 'school',
          sortable: false,
        },
        {
          label: 'Owner',
          field: 'owner',
          sortable: false,
        },
        {
          label: this.$i18n.tc('user-module.user-table.actions'),
          field: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    isHighUser() {
      return isHighLevelUser()
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isDarkMode() {
      return this.$store.getters['appConfig/isDarkMode']
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    isATeacher() {
      // here parent is also like teacher user who can only their child user report
      return this.self.usertype === USER_TYPE_TEACHER || this.self.usertype === USER_TYPE_PARENT
    },
    isArchived() {
      return this.selectedClassType === 'archived'
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isSuperAdmin() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    notResponsiveWindow() {
      return window.innerWidth >= 1650
    },
    schoolName() {
      return this.classes?.data?.[0]?.school?.fullname
    },
    noOfStudentSettings() {
      return {
        distNoOfStudentInfo: this.getDistrictSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
        schoolNoOfStudentInfo: this.getSchoolSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
      }
    },
    hideNoOfStudent() {
      return this.isAStudent && (this.noOfStudentSettings?.distNoOfStudentInfo === '1' || this.noOfStudentSettings?.schoolNoOfStudentInfo === '1')
    },
    hideCourses() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('class_card_courses', schoolSetting, distSetting)
    },
  },
  watch: {
    tabIndex(value) {
      this.$emit('onTabChanged', value)
    },
  },
  mounted() {
    // isVisible('class_card_courses', { fetch: this.$store.state.appConfig.justLoggedIn }).then(response => {
    //   // this.hideCourses = !response
    //   this.$store.commit('appConfig/UPDATE_JUST_LOGGED_IN', false)
    // })
    const storedValue = localStorage.getItem('showTableView')
    if (storedValue !== null) {
      this.showTableView = storedValue === 'true' // Converts to true if the string is "true"
    }
  },
  methods: {
    isAClassRoomTeacher(item) {
      const authUserId = this.AUTH_USER().id
      // eslint-disable-next-line eqeqeq
      return !!item.classroom_teachers.find(obj => obj.user?.id == authUserId)
    },
    viewLessons(id, classId) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
      })
    },
    activeClass(item, type) {
      if (!this.selectedClass) return ''
      if (!item || type !== 'row') return ''
      if (item.id === this.selectedClass.id) return 'active-column'
      return ''
    },
    onClassRowClick(params) {
      this.selectedClass = params
      this.$emit('onClassSelected', params)
    },
    confirmRestore(id) {
      this.restoreParams = {
        id,
        title: 'Restore Classroom',
        show: true,
        isProcessing: false,
        subTitle: 'Are you sure you want to restore classroom?',
        varient: 'primary',
      }
    },
    handleRestore() {
      const { id } = this.restoreParams
      this.restoreParams = { ...this.restoreParams, isProcessing: true }
      useJwt
        .retoreClassroom(id)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('reloadClassRoom', 'archived')
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.restoreParams = {}
        })
    },
    viewStudent(cls) {
      this.$router.push({
        name: `${this.self.usertype}-view-students`,
        params: { id: cls.id },
      })
    },
    openSSOLink(link) {
      this.isAttendanceDone = false
      const ssoProvider = link.split(process.env.VUE_APP_SSO_PREFIX)[1]
      useJwt.getSsoLink(ssoProvider).then(response => {
        window.open(response.data.data, '_self')
        setTimeout(() => {
          this.isAttendanceDone = true
        }, 1500)

        // this.redirectingLink = null
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    openClassLink(link, classId) {
      this.redirectingLink = link
      this.isAttendanceDone = false
      useJwt.updateStudentAttendanceWithLiveLink({
        classroom_id: classId,
      }).then(response => {
        this.showSuccessMessage(response)
        if (link.startsWith(process.env.VUE_APP_SSO_PREFIX)) {
          this.openSSOLink(link)
        } else {
          window.open(link, '_self')
          setTimeout(() => {
            this.isAttendanceDone = true
          }, 1500)
          // this.redirectingLink = null
        }
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    filterTeacherByRole(index) {
      /* eslint-disable prefer-destructuring */
      const teachers = this.classes?.data?.[index].classroom_teachers
      const teacher = teachers.find(user => user.role === 'teacher')

      if (Number(teacher?.user?.id) === this.self.id) return i18n.tc('you')
      return teacher?.user?.fullname
    },
    courseProgress(value) {
      if (value >= 25.0 && value <= 50.0) { return 'warning' }
      if (value > 50 && value <= 75.0) {
        return 'primary'
      }
      if (value > 75.0) {
        return 'success'
      }
      return 'danger'
    },
    // eslint-disable-next-line no-unused-vars
    chartOptions(value) {
      return {
        chart: {
          type: 'radialBar',
          width: '100%', // Chart takes full width of the parent container
          height: 160,
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: '50%',
            },
            dataLabels: {
              name: {
                show: true,
                offsetY: 15, // Adjust label position if necessary
                style: {
                  fontSize: '5px', // Set font size for the label
                  fontWeight: 'bold', // Optional: Set font weight
                },
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: [this.getColor(value)],
        labels: [`${value?.toFixed(2)}%`],
      }
    },
    getColor(value) {
      if (value >= 25.0 && value <= 50.0) return '#ff9f43' // Warning
      if (value > 50.0 && value <= 75.0) return '#7367f0' // Primary
      if (value > 75.0) return '#28c76f' // Success
      return '#ea5455' // Danger
    },
    storeTableView() {
      localStorage.setItem('showTableView', this.showTableView)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
.header-navbar.floating-nav {
  margin-right: 14px
}
.class-head-title {
  margin-right: -11px;
}
.class-title {
  font-size:15px;
}
.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}

.disabled_container {
  pointer-events: none !important;
}
.class-head-title {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
}
h5.total-students {
  font-size: 14px;
}
h5.class-owner {
  font-size: 12px;
}
.dark-layout {
  .class-head-title {
    background-color: $theme-dark-card-bg !important;
    background-color: $theme-dark-box-shadow;
  }
 }
 @media(max-width:768px) {
  .header-navbar.floating-nav {
    margin-right: 1.2rem
  }
  .class-head-title {
    margin-right: 0px;
  }
  .class-part h5 {
    font-size: 13px;
  }
 }

 .class-part {
  .apexcharts-canvas {
    margin-top: -25px;
  }
 }
</style>
