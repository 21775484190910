<template>
  <div>
    <!-- filters and create new button -->
    <b-col
      md="12"
      class="d-flex justify-content-between p-0"
    >
      <b-col md="4">
        <b-row class="d-flex justify-content-start p-0">
          <b-col
            md="12"
            sm="12"
            class="p-0"
          >
            <b-form-group>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('labels.type-to-search')"
                type="text"
                class="d-inline-block"
                @input="searchTable"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-col
          md="12"
          sm="12"
          class="p-0"
        >
          <b-form-group>
            <v-select
              v-model="filterDomain"
              :reduce="text => text.value"
              :options="filterDomains"
              :placeholder="$t('courses-module.please-select-domain')"
              label="text"
              multiple
              @input="getStandardsByDomain"
            />
            <!-- <b-form-select
              v-model="filterDomain"
              :options="filterDomains"
              :placeholder="$t('labels.domain-filter')"
              type="text"
            /> -->
          </b-form-group>
        </b-col>
      </b-col>
      <b-col md="4">
        <b-col
          md="12"
          sm="12"
          class="p-0"
        >
          <b-form-group>
            <v-select
              v-model="filterStandard"
              multiple
              label="text"
              :reduce="text => text.value"
              :options="filterStandards"
              :placeholder="$t('courses-module.please-select-standard')"
              @input="standardSelected"
            />
            <!-- <b-form-select
              v-model="filterStandard"
              :options="filterStandards"
              :placeholder="$t('labels.standard-filter')"
              type="text"
            /> -->
          </b-form-group>
        </b-col>
      </b-col>

    </b-col>

    <b-col
      md="12"
      sm="12"
      class="text-right p-0 pb-1"
    >
      <span>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mx-2"
          @click="showBatchUpload = true"
        >
          {{ $t('courses-module.batch-problem-upload') }}
        </b-button>
        <!-- Bulk Share Button -->
        <b-button
          variant="primary"
          class="mr-1"
          :disabled="!selectedRows.length"
          @click="openBulkShareModal"
        >
          {{ $t('actions.share-lesson') }}
        </b-button>

        <!-- Create Lesson Button -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="!selected_id"
          @click="toggleCreateModal('create-lesson', 'createLesson')"
        >
          {{ $t("actions.create-lesson") }}
        </b-button>
      </span>
    </b-col>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="table-responsive p-0 m-0">
        <b-overlay :show="isTableLoading">
          <b-table-simple
            v-show="lessonGroupId || filterStandard"
            ref="table"
            role="table"
            class="table b-table course-table"
            :class="{ 'min-height': (lessonGroupId && rows.length) }"
            responsive
            outlined
          >
            <thead
              role="rowgroup"
            >
              <tr
                role="row"
              >
                <th
                  v-for="column,index in columns"
                  :key="index"
                  role="columnheader"
                  scope="col"
                  aria-colindex="1"
                  :class="column.thClass"
                  :style="`width:${column.width ? column.width:'auto'}`"
                >
                  <div
                    v-if="column.label == 'select'"
                  >
                    <b-form-checkbox
                      v-model="selectAll"
                      @change="selectAllRows"
                    />
                  </div>
                  <div v-else>
                    {{ column.label }}
                  </div>
                </th>
              </tr>
            </thead>
            <draggable
              v-model="rows"
              tag="tbody"
              :options="{animation:300,}"
              handle=".handle"
              @change="updateOrder"
            >
              <tr
                v-for="(row,index) in rows"
                :key="index"
                :set="isSDPLesson = row.type && row.type.name === 'SDP'"
                role="row"
              >
                <td
                  v-for="column in columns"
                  :key="'row_'+column.label"
                  role="cell"
                  :class="{
                    'bg-light-secondary': isSDPLesson,
                    'px-0': column.field === 'select'
                  }"
                >
                  <span
                    v-if="column.field === '#'"
                    class="d-flex"
                  >
                    <feather-icon
                      icon="AlignJustifyIcon"
                      size="16"
                      class="text-body align-middle m-0 p-0 handle mr-2"
                    />
                  </span>
                  <!-- Select -->
                  <span
                    v-else-if="column.field === 'select'"
                  >
                    <b-form-checkbox
                      v-model="selectedRows"
                      name="selectRows"
                      :value="row.id"
                      @change="() => { selectedRows.length === rows.length ? selectAll = true : selectAll = false }"
                    />
                  </span>
                  <!-- Image  -->
                  <span
                    v-else-if="column.field === 'image'"
                    class="d-flex"
                  >
                    <b-img
                      v-if="row[column.field]"
                      rounded
                      :src="row[column.field]"
                      style="max-width:80px;max-height:80px"
                      thumbnail
                    />
                  </span>

                  <!-- PDF  -->
                  <span v-else-if="column.field==='file'">
                    <b-button
                      v-if="row[column.field]"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="openPdf(row[column.field])"
                    >
                      <feather-icon
                        icon="FileIcon"
                        size="16"
                      />
                    </b-button>
                    <span v-else>N/A</span>
                  </span>
                  <!-- Default Engine  -->
                  <span v-else-if="column.field === 'engine'">
                    <b-form-select
                      style="width: 90px"
                      :value="getEngineLowerCase(row.default_engine, row.type)"
                      :options="defaultEngineOptions"
                      @input="(value) => setEngine(row.id, value)"
                    >
                      <option value="linear">Linear</option>
                      <option
                        v-if="!row.type || row.type.name !== 'Normal'"
                        value="adp"
                      >ADP</option>
                    </b-form-select>
                  </span>
                  <!-- Public  -->
                  <span v-else-if="column.field === 'is_public'">
                    <b-form-checkbox
                      :checked="row.is_public === 1"
                      class="custom-control-primary pr-1"
                      name="check-button"
                      switch
                      @change="togglePublic(row.id, row.is_public)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </span>
                  <!-- Actions  -->
                  <span
                    v-else-if="column.field === 'action'"
                    class="d-flex align-items-center"
                  >
                    <template v-if="checkPermissionAction(row.id)">
                      <b-button
                        v-if="isSDPLesson"
                        v-b-tooltip.hover.top="'Build Problem'"
                        variant="outline-primary"
                        class="btn-icon mr-1"
                        @click="$router.push({ name: 'sdp-lesson', params: { id: row.id } })"
                      >
                        <feather-icon icon="BoxIcon" />
                      </b-button>
                      <b-button
                        v-else
                        v-b-tooltip.hover.top="'Create'"
                        variant="outline-primary"
                        class="btn-icon mr-1"
                        @click="$router.push({ path: `/${auth.usertype}/problem/create`, query: { lesson_id: row.id } })"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>

                      <b-button
                        v-b-tooltip.hover.top="'View'"
                        variant="outline-primary"
                        class="btn-icon mr-1"
                        @click="$router.push({ path: `/${auth.usertype}/problem`, query: { lesson_id: row.id } })"
                      >
                        <feather-icon
                          icon="EyeIcon"
                        />
                      </b-button>
                      <feather-icon
                        v-b-tooltip.hover.top="'Info'"
                        class="text-warning"
                        role="button"
                        icon="InfoIcon"
                        @click="lessonIdToShowDetail = row.id"
                      />
                    </template>
                    <span
                      v-else
                      class="text-center text-danger"
                    > {{ $t('permissions-module.no-permission') }}</span>
                    <!-- More Actions  -->
                    <b-dropdown
                      variant="link"
                      class="lesson-options"
                      toggle-class="text-decoration-none p-0"
                      no-caret
                    >
                      <!-- Dropdown button  -->
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body m-0 p-0"
                        />
                      </template>

                      <!-- Dropdown Item - Manage Permission  -->
                      <b-button
                        v-if="auth.usertype === 'super'"
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0"
                        @click="$emit('editPermissions',{module:'lesson','method':'show','id':row.id})"
                      >
                        <feather-icon
                          icon="SlidersIcon"
                          class="dropdown-action-icon"
                        />
                        <span>Permissions</span>
                      </b-button>

                      <!-- Dropdown Item - Edit  -->
                      <b-button
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0"
                        @click="
                          toggleUpdateModal(
                            row,
                            'update-lesson',
                            'updateLesson'
                          )
                        "
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="dropdown-action-icon"
                        />
                        <span>{{ $t('actions.update') }}</span>
                      </b-button>

                      <b-button
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0"
                        @click="toggleCalibrationModal(row)"
                      >
                        <feather-icon
                          icon="PercentIcon"
                          class="dropdown-action-icon"
                        />
                        <span>{{ $t('actions.calibration') }}</span>
                      </b-button>
                      <b-button
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0"
                        @click="() => { cbLessonToGenerate = row}"
                      >
                        <feather-icon
                          icon="Share2Icon"
                          class="dropdown-action-icon"
                        />
                        <span>{{ $t('actions.generate-cb-lesson') }}</span>
                      </b-button>

                      <!-- Dropdown Item - Share -->
                      <b-button
                        v-b-modal.share-modal
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0"
                        @click="() => { selected_id = row.id}"
                      >
                        <feather-icon
                          icon="Share2Icon"
                          class="dropdown-action-icon"
                        />
                        <span>{{ $t('actions.share') }}</span>
                      </b-button>

                      <!-- Dropdown item add lesson planner -->
                      <b-button
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0"
                        @click="showCreateLessonPlanner(row.id,row.name)"
                      >
                        <feather-icon
                          icon="BookIcon"
                          class="dropdown-action-icon"
                        />
                        <span>{{ $t('actions.update') }} Lesson Plans</span>
                      </b-button>

                      <!-- Dropdown item global and local lesson switch -->
                      <b-button
                        variant="light"
                        class="d-flex align-items-center dropdown-action border-0 rounded-0 w-100 py-1 pl-1 pr-0"
                      >
                        <b-form-checkbox
                          v-model="row.cefr_scope"
                          :value="'global'"
                          :unchecked-value="'local'"
                          class=""
                          switch
                          @change="toggleGlobalCEFR(row.id, row.cefr_scope)"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span>{{ $t('actions.global-cefr') }}</span>
                      </b-button>

                      <b-button
                        variant="light"
                        class="d-flex align-items-center dropdown-action border-0 rounded-0 w-100 py-1 pl-1 pr-0"
                      >
                        <b-form-checkbox
                          :checked="row.has_game_wrapper === 1"
                          class="custom-control-primary pr-1"
                          name="check-button"
                          switch
                          @change="toggleGameWrapper(row.id, row.has_game_wrapper)"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                        <span>Game Wrapper</span>
                      </b-button>
                      <!-- Dropdown Item - Delete  -->
                      <b-button
                        variant="light"
                        class="dropdown-action w-100 py-1 pl-1 pr-0 text-left border-0 rounded-0 text-danger"
                        @click="
                          row.is_shared
                            ? toggleDeleteModal(row.id, 'remove-shared', 'removeSharedLesson')
                            : toggleDeleteModal(row.id, 'delete-lesson', 'deleteLesson')
                        "
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="dropdown-action-icon"
                        />
                        <span v-if="row.is_shared">Remove Shared Lesson</span>
                        <span v-else>Delete Lesson</span>
                      </b-button>

                    </b-dropdown>
                  </span>
                  <span v-else>
                    {{ row[column.field] }}
                  </span>
                </td>

              </tr>
            </draggable>
            <tfoot v-if="rows.length">
              <tr>
                <td colspan="3">
                  <div class="d-flex justify-content-between">
                    <div>
                      Per Page
                    </div>
                    <div>
                      <select v-model="perPageFilter"
                              style="width: 70px;height: 25px;"
                              @change="getLesson(lessonGroupId, null, page)"
                      >
                        <option v-for="page of [15, 25, 50, 100]"
                                :key="page"
                                :value="page"
                        >
                          {{ page }}
                        </option>
                      </select>
                    </div>
                  </div>
                </td>
                <td colspan="100%" />
              </tr>
            </tfoot>
          </b-table-simple>
        </b-overlay>
        <p
          v-show="!lessonGroupId && !isTableLoading && filterStandard.length<=0"
          class="p-2 text-capitalize p-0 m-0"
        >
          <strong>{{ $t("messages.select-lesson-group") }}</strong>
        </p>
        <p
          v-show="(lessonGroupId && rows.length==0 && !isTableLoading) || (filterStandard.length>0 && rows.length==0 && !isTableLoading)"
          class="text-center p-2 p-0 m-0"
        >
          {{ $t("messages.no-data-available") }}
        </p>
      </div>
    </b-card>
    <pagination
      :pagination="lessonPagination"
      :is-processing="isTableLoading"
      @onPaginationChange="(page)=> getLesson(lessonGroupId, null, page)"
    />
    <!-- list of users table -->

    <!-- create  -->
    <b-modal
      id="modal-create"
      ref="modal-create"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.' + modalMode)"
      no-close-on-backdrop
      @close="createPdfUrl = ''"
      @hide="createPdfUrl = ''"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer ref="createItemForm">
          <b-form
            class="auth-login-form"
            @submit.prevent="createItem"
          >
            <button
              type="submit"
              class="d-none"
            />
            <b-row>
              <!-- image -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-image')"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="image|size:800"
                    :custom-messages="{ image: $t('messages.invalid-image') }"
                    vid="image"
                  >

                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>

                        <avatar
                          size="50px"
                          :rounded="true"
                          :image="newItem.image"
                        />
                        <!--/ avatar -->
                      </b-media-aside>

                      <b-media-body class="ml-50">
                        <!-- Plain mode -->
                        <div>
                          <b-form-file
                            ref="refInputEl1"
                            v-model="lessonImage"
                            :browse-text="$t('browseButton')"
                            :placeholder="$t('labels.file-placeholder')"
                            drop-placeholder="Drop file here..."
                            class="mb-1"
                            accept="image/jpeg, image/png, image/gif"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </div>
                        <!-- <div v-if="lessonImage">
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="resetLogo()"
                          >
                            {{ $t('account-setting-general.reset') }}
                          </b-button>
                        </div> -->

                        <div v-if="!lessonImage">
                          <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                        </div>
                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- image -->

              <!-- Checkboxes -->
              <b-col
                md="6"
                class="d-flex justify-content-between"
              >
                <b-form-group
                  :label="$t('labels.linear-lesson')"
                  label-for="linearLesson"
                >
                  <b-form-checkbox
                    id="linearLesson"
                    v-model="lessonLinearEngine"
                    value="1"
                    unchecked-value="0"
                    :disabled="lessonSDPEngine == 1"
                    switch
                    @change="handleLinearLessonChange"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.sdp-lesson')"
                  label-for="sdpLesson"
                >
                  <b-form-checkbox
                    id="sdpLesson"
                    v-model="lessonSDPEngine"
                    value="1"
                    unchecked-value="0"
                    :disabled="lessonLinearEngine == 1"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.hide-ebook')"
                  label-for="hideEbook"
                >
                  <b-form-checkbox
                    id="hideEbook"
                    v-model="hideEbook"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.enable-ai-tutor')"
                  label-for="hideAiTutor"
                >
                  <b-form-checkbox
                    id="hideAiTutor"
                    v-model="hideAiTutor"
                    :value="0"
                    :unchecked-value="1"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.weakest-skill')"
                  label-for="weakestSkill"
                >
                  <b-form-checkbox
                    id="weakestSkill"
                    v-model="weakestSkill"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="Scorm Lesson?"
                  label-for="scormLesson"
                >
                  <b-form-checkbox
                    id="scormLesson"
                    v-model="scormLesson"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <!-- Checkboxes -->

              <!-- Language Levels -->
              <b-col
                v-if="Number(lessonLinearEngine)"
                md="6"
              >
                <b-form-group
                  :label="$t('labels.language-level')"
                  label-for="langLevel"
                  rules="required"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="language level"
                    rules="required"
                  >
                    <b-form-radio-group
                      id="langLevel"
                      v-model="newItem.lang_level"
                      name="langLevel"
                      :options="langLevels"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  v-if="!hideAiTutor"
                  class="mb-1"
                  variant="outline-primary"
                  @click="showLessonPrompt = true"
                >
                  Manage AI Prompt
                </b-button>
              </b-col>
              <!-- name -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson')"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="newItem.name"
                      name="item_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- name -->

              <!-- Domain -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-domain')"
                  label-for="domain"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="domain"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedDomains"
                      :class="!!errors[0] ? 'v-select-error':''"
                      multiple
                      label="text"
                      :options="domains"
                      placeholder="Please select a Domain"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="scormLesson"
                     md="12"
              >
                <b-form-group
                  label="Scorm Lists"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Scorm Lists"
                  >
                    <v-select
                      v-model="selectedScorm"
                      :class="!!errors[0] ? 'v-select-error':''"
                      label="name"
                      :options="scormLists"
                      :reduce="i => i.cid"
                      :state="errors.length > 0 ? false : null"
                      @input="onScromListChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-row>
                  <b-col v-if="scormLesson">
                    <b-form-group
                      :label="$t('labels.max-score')"
                      label-for="max_score"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="max score"
                        rules="required"
                        vid="max_score"
                      >
                        <b-form-input
                          id="max_score"
                          v-model="maxScore"
                          name="max_score"
                          :state="errors.length > 0 ? false : null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="scormLesson ? 'Pass Score' : $t('labels.create-lesson-pass-percent')"
                      label-for="item-pass-percent"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="pass percentage"
                        rules="required"
                        vid="pass_percent"
                      >
                        <b-form-input
                          id="pass_percent"
                          v-model="newItem.pass_percent"
                          name="pass_percent"
                          :state="errors.length > 0 ? false : null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="$t('labels.create-lesson-access-type')"
                      label-for="access-type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="access type"
                        rules="required"
                      >
                        <b-form-select
                          id="access-type"
                          v-model="newItem.access_type"
                          :options="access_types"
                          name="access-type"
                          :state="errors.length > 0 ? false:null"
                          @change="updateUseOn"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Use On"
                      label-for="lesson-use-on"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Use On"
                        rules="required"
                      >
                        <b-form-select
                          v-model="newItem.use_on"
                          :options="use_on_types"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- access_type -->
              <!-- live link -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-live-link')"
                  label-for="live-link"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="live link"
                  >
                    <b-form-input
                      id="live_link"
                      v-model="newItem.live_link"
                      name="live_link"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- live link -->

              <!-- Upload Pdf -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-pdf')"
                  label-for="file"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="file"
                  >
                    <div>
                      <b-form-file
                        ref="refInputEl2"
                        v-model="newItem.lessonPdf"
                        :browse-text="$t('browseButton')"
                        :placeholder="$t('labels.file-placeholder')"
                        class="mb-1"
                        accept="application/pdf"
                        :state="errors.length > 0 ? false : null"
                        @change="handlePdfPreview($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </div>
                  </validation-provider>
                  <div v-if="newItem.lessonPdf">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      class="ml-1"
                      @click="openPdf(createPdfUrl)"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        size="18"
                      />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="ml-1"
                      @click="deletePdf()"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                      />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Upload PDF -->

            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="createItem(modalFunction)"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions." + modalMode) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- create  -->

    <!-- update  -->
    <b-modal
      id="modal-update"
      ref="modal-update"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      modal-class="modal-primary"
      centered
      :title="$t('actions.' + modalMode)"
    >
      <b-col md="12">
        <!-- form -->
        <validation-observer ref="updateItemForm">
          <b-form
            class="auth-login-form"
            @submit.prevent="updateItem(modalFunction)"
          >
            <button
              type="submit"
              class="d-none"
            />
            <b-row>
              <!-- image -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson')"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="image|size:800"
                    :custom-messages="{ image: $t('messages.invalid-image') }"
                    vid="image"
                  >

                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>

                        <avatar
                          size="50px"
                          :rounded="true"
                          :image="newItem.image"
                        />
                        <!--/ avatar -->
                      </b-media-aside>

                      <b-media-body class="ml-50">
                        <!-- Plain mode -->
                        <div>
                          <b-form-file
                            ref="refInputEl1"
                            v-model="lessonImage"
                            :browse-text="$t('browseButton')"
                            :placeholder="$t('issue-report-module.choose-a-file')"
                            class="mb-1"
                            accept="image/jpeg, image/png, image/gif"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </div>
                        <!-- <div v-if="lessonImage">
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="resetImage()"
                          >
                            {{ $t('account-setting-general.reset') }}
                          </b-button>
                        </div> -->

                        <div v-if="!lessonImage && errors.length==0">
                          <b-card-text> {{ $t('account-setting-general.allowed') }} </b-card-text>
                        </div>
                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- image -->
              <!-- Checkboxes -->
              <b-col
                md="12"
                class="d-flex justify-content-between"
              >
                <b-form-group
                  :label="$t('labels.linear-lesson')"
                  label-for="linearLesson"
                >
                  <b-form-checkbox
                    id="linearLesson"
                    v-model="lessonLinearEngine"
                    value="1"
                    unchecked-value="0"
                    switch
                    :disabled="lessonLinearEngine === 1 || lessonSDPEngine == 1"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.sdp-lesson')"
                  label-for="sdpLesson"
                >
                  <b-form-checkbox
                    id="sdpLesson"
                    v-model="lessonSDPEngine"
                    value="1"
                    unchecked-value="0"
                    :disabled="lessonLinearEngine == 1"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.hide-ebook')"
                  label-for="hideEbook"
                >
                  <b-form-checkbox
                    id="hideEbook"
                    v-model="hideEbook"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.enable-ai-tutor')"
                  label-for="hideAiTutor"
                >
                  <b-form-checkbox
                    id="hideAiTutor"
                    v-model="hideAiTutor"
                    :value="0"
                    :unchecked-value="1"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.weakest-skill')"
                  label-for="weakestSkill"
                >
                  <b-form-checkbox
                    id="weakestSkill"
                    v-model="weakestSkill"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="Scorm Lesson?"
                  label-for="scormLesson"
                >
                  <b-form-checkbox
                    id="scormLesson"
                    v-model="scormLesson"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>

                <!-- Summary Lesson Toggler -->
                <b-form-group
                  label="Summary Lesson"
                  label-for="summaryLesson"
                >
                  <b-form-checkbox
                    id="summaryLesson"
                    v-model="isSummaryLesson"
                    :disabled="generatingSummary"
                    switch
                    @change="toggleSummaryLesson"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <!-- Summary Lesson Toggler -->
              </b-col>
              <!-- Checkboxes -->

              <!-- Language Levels -->
              <b-col
                v-if="Number(lessonLinearEngine)"
                md="12"
              >
                <b-form-group
                  :label="$t('labels.language-level')"
                  label-for="langLevel"
                  rules="required"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="language level"
                    rules="required"
                  >
                    <b-form-radio-group
                      id="langLevel"
                      v-model="newItem.lang_level"
                      name="langLevel"
                      :options="langLevels"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <div class="d-flex">
                  <b-button
                    v-if="!hideAiTutor"
                    class="mb-1"
                    variant="outline-primary"
                    @click="showLessonPrompt = true"
                  >
                    Manage AI Prompt
                  </b-button>

                  <b-button
                    v-if="showGenerateSummaryButton"
                    variant="outline-primary"
                    class="mb-1"
                    :class="hideAiTutor ? '' : 'ml-1'"
                    :disabled="generatingSummary"
                    small
                    @click="generateSummary"
                  >
                    <b-spinner
                      v-if="generatingSummary"
                      small
                    />
                    {{ generatingSummary ? $t('Generating ...') : $t('Generate Summary') }}
                  </b-button>
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.' + modalMode)"
                  label-for="tem-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="item_name"
                      v-model="newItem.name"
                      name="item_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- name -->
              <!-- Domain -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-domain')"
                  label-for="domain"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="domain"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedDomainsUpdate"
                      :class="!!errors[0] ? 'v-select-error':''"
                      multiple
                      label="text"
                      :options="domains"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="scormLesson"
                     md="12"
              >
                <b-form-group
                  label="Scorm Lists"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Scorm Lists"
                  >
                    <v-select
                      v-model="selectedScorm"
                      :class="!!errors[0] ? 'v-select-error':''"
                      label="name"
                      :options="scormLists"
                      :reduce="i => i.cid"
                      :state="errors.length > 0 ? false : null"
                      @input="onScromListChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-row>
                  <b-col v-if="scormLesson">
                    <b-form-group
                      :label="$t('labels.max-score')"
                      label-for="max_score"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="max score"
                        rules="required"
                        vid="max_score"
                      >
                        <b-form-input
                          id="max_score"
                          v-model="maxScore"
                          name="max_score"
                          :state="errors.length > 0 ? false : null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="scormLesson ? 'Pass Score' : $t('labels.create-lesson-pass-percent')"
                      label-for="item-pass-percent"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="pass percentage"
                        rules="required"
                        vid="pass_percent"
                      >
                        <b-form-input
                          id="pass_percent"
                          v-model="newItem.pass_percent"
                          name="pass_percent"
                          :state="errors.length > 0 ? false : null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- access_type -->
                  <b-col>
                    <b-form-group
                      :label="$t('labels.create-lesson-access-type')"
                      label-for="access-type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="access type"
                        rules="required"
                      >
                        <b-form-select
                          id="access-type"
                          v-model="newItem.access_type"
                          :options="access_types"
                          name="access-type"
                          :state="errors.length > 0 ? false:null"
                          @change="updateUseOn"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- access_type -->
                  <b-col>
                    <b-form-group
                      label="Use On"
                      label-for="lesson-use-on"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Use On"
                        rules="required"
                      >
                        <b-form-select
                          v-model="newItem.use_on"
                          :options="use_on_types"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                <!-- access_type -->
                </b-row>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-live-link')"
                  label-for="live-link"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="live link"
                  >
                    <b-form-input
                      id="live_link"
                      v-model="newItem.live_link"
                      name="live_link"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- access_type -->
              <!-- Upload Pdf -->
              <b-col md="12">
                <b-form-group
                  :label="$t('labels.create-lesson-pdf')"
                  label-for="file"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="file"
                  >
                    <div>
                      <b-form-file
                        ref="refInputEl2"
                        v-model="newItem.lessonPdf"
                        :browse-text="$t('browseButton')"
                        :placeholder="$t('issue-report-module.choose-a-file')"
                        class="mb-1"
                        accept="application/pdf"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </div>
                    <div v-if="newItem.lessonPdf">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        class="ml-1"
                        @click="openPdf(newItem.lessonPdf)"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          size="18"
                        />
                      </b-button>
                      <b-button
                        variant="danger"
                        size="sm"
                        class="ml-1"
                        @click="deletePdf()"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="18"
                        />
                      </b-button>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                class="mb-1"
              >
                <b-form-group
                  :label="$t('Tags')"
                  label-for="item-tag"
                  class="mb-0"
                >
                  <b-form-tags
                    v-model="tags"
                    input-id="tags-basic"
                    separator=" ,;"
                    class="mb-1"
                    @input="updateTags()"
                  />

                  <small class="muted">Seperate tags by comma ",". eg: tag1, tag2</small>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <div v-if="Object.entries(lessonSummary).length">
          <h4>{{ $t('Lesson Summary') }}</h4>

          <b-overlay :show="generatingSummary">
            <div
              v-for="[key, value] in Object.entries(lessonSummary)"
              :key="key"
              class="ml-2"
            >
              <b-form-group
                class="text-capitalize"
                :label="key.split('-').join(' ')"
              >
                <b-form-textarea
                  v-model="lessonSummary[key]"
                  rows="4"
                >
                  {{ value }}
                </b-form-textarea>
              </b-form-group>
            </div>
          </b-overlay>
        </div>
      </b-col>
      <template #modal-footer="{ }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              :disabled="isProcessing"
              @click="updateItem(modalFunction)"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("actions." + modalMode) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- update  -->

    <pdf-viewer
      ref="pdfViewer"
      :pdf="activePdf"
      :toggle="showPdf"
      @pdfModalClosed="showPdf=false"
    />

    <!-- delete item -->
    <delete-modal
      :modal-delete-mode="modalDeleteMode"
      :modal-function="modalFunction"
      :delete-item-index="deleteItemIndex"
      :lesson_group_id="lessonGroupId"
      @deleted="reloadData"
    />
    <!-- delete item -->

    <!-- share item -->
    <share-modal
      :courses="courses"
      :current-course="courseId"
      :lesson-id="selected_id"
      @shared="clearSelection"
    />

    <b-modal
      id="modal-calibration"
      ref="modal-calibration"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('save')"
      modal-class="modal-primary"
      centered
      title="Calibration"
      @ok="updateCalibration"
    >
      <weighage v-model="skillWeightage" />
    </b-modal>
    <b-modal
      id="lessonPlannerCreateModal"
      size="xl"
      hide-footer
    >
      <create-lesson-planner
        :lesson-name="activeLesson"
        :lesson-id="activeLessonId"
        @lessonPlannerCreated="()=>{$bvModal.hide('lessonPlannerCreateModal')}"
        @lessonPlannerUpdated="()=>{$bvModal.hide('lessonPlannerCreateModal')}"
      />
    </b-modal>

    <ProblemBatchUpload
      :show="showBatchUpload"
      @close="showBatchUpload = false"
    />
    <lesson-detail-model
      :show="!!lessonIdToShowDetail"
      :lesson-id="lessonIdToShowDetail"
      @close="lessonIdToShowDetail=0"
    />
    <GenerateCbLesson
      v-if="!!cbLessonToGenerate"
      :lesson-id="cbLessonToGenerate?.id"
      :lesson-info="cbLessonToGenerate"
      @close="cbLessonToGenerate = null"
    />
    <LessonPrompt
      ref="lessonPrompt"
      :show="showLessonPrompt"
      :lesson-id="newItem.id"
      @close="showLessonPrompt = false"
    />
    <GenerateCbLesson
      v-if="!!factorLessonInfo"
      type="FactorLesson"
      :lesson-id="factorLessonInfo.lesson_id"
      :main-points="factorLessonInfo.main_points"
      @close="factorLessonInfo = null"
    />
  </div>
</template>

<script>
import {
  BFormSelect,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  VBToggle,
  BForm,
  BSpinner,
  BCardText,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BCard,
  BTableSimple,
  BFormCheckbox,
  BFormRadioGroup,
  BOverlay,
  VBTooltip,
  BDropdown,
  BFormTags,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { required } from '@validations'
// import { VueGoodTable } from 'vue-good-table'
import { ref } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import Avatar from '@/views/common/components/Avatar.vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import PdfViewer from '@/views/common/components/PdfViewer.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import Pagination from '@/views/common/components/Pagination.vue'
import Weighage from '@/views/common/course/components/Weighage.vue'
import CreateLessonPlanner from '@/views/super/lesson-planner/Create.vue'
import ProblemBatchUpload from '@/views/super/problem/components/ProblemBatchUpload.vue'
import i18n from '@/libs/i18n'
import ShareModal from './components/ShareModal.vue'
import DeleteModal from './components/DeleteModal.vue'
import LessonDetailModel from '../components/LessonDetailModel.vue'
import GenerateCbLesson from './components/GenerateCbLessons.vue'
import LessonPrompt from './lesson-prompt/index.vue'

export default {
  components: {
    BImg,
    LessonPrompt,
    // VueGoodTable,
    BFormSelect,
    BCol,
    BRow,
    draggable,
    BFormGroup,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormInput,
    BCard,
    BButton,
    ValidationProvider,
    BTableSimple,
    ValidationObserver,
    BForm,
    BSpinner,
    BCardText,
    Avatar,
    vSelect,
    BFormCheckbox,
    BFormRadioGroup,
    BOverlay,
    PdfViewer,
    BDropdown,
    Pagination,
    ShareModal,
    DeleteModal,
    Weighage,
    CreateLessonPlanner,
    ProblemBatchUpload,
    LessonDetailModel,
    BFormTags,
    BFormTextarea,
    GenerateCbLesson,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    subjectId: {
      type: Number,
      default: () => null,
    },
    lessonGroupId: {
      type: Number,
      default: () => null,
    },
    courses: {
      type: Array,
      default: () => [],
    },
    courseId: {
      type: Number,
      default: () => null,
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      lessonId: 0,
      skillWeightage: {},
      modalMode: null,
      modalFunction: null,
      modalDeleteMode: null,
      isProcessing: false,
      newItem: {},
      deleteItemIndex: null,
      pageLength: 10,
      selected_id: null,
      lessonImage: null,
      lessonLinearEngine: 0,
      lessonSDPEngine: 0,
      lessonPdf: null,
      searchTerm: '',
      activeCourseId: null,
      allLessons: [],
      rows: [],
      showPdf: false,
      activePdf: null,
      activeLessonId: null,
      activeLesson: '',
      columns: [
        {
          label: '#',
          field: '#',
        },
        {
          label: 'select',
          field: 'select',
          thClass: 'px-0',
        },
        {
          label: i18n.tc('courses-module.table-head.image'),
          field: 'image',
        },

        {
          label: i18n.tc('courses-module.table-head.image'),
          field: 'name',
          searchable: 'true',
        },
        // {
        //   label: 'Pass percent (%)',
        //   field: 'pass_percent',
        //   searchable: 'true',
        // },
        // {
        //   label: 'Lesson Type',
        //   field: 'type',
        //   searchable: 'true',
        // },
        {
          label: i18n.tc('courses-module.table-head.file'),
          field: 'file',
        },
        {
          label: i18n.tc('courses-module.table-head.engine'),
          field: 'engine',
        },

        {
          label: i18n.tc('courses-module.table-head.is_public'),
          field: 'is_public',
        },
        {
          label: i18n.tc('courses-module.table-head.action'),
          field: 'action',
          sortable: false,
          width: '115px',
          thClass: 'text-center',
        },
      ],
      access_types: [
        { value: null, text: 'Please select an Access Type' },
        { value: 'trial', text: 'Trial' },
        { value: 'full_access', text: 'Full Access' },
      ],
      use_on_types: [
        { value: 'all', text: 'All' },
        { value: 'only-test', text: 'Only Test' },
        { value: 'homework', text: 'Only Lesson' },
      ],
      selectedDomains: [],
      selectedDomainsUpdate: [],
      // validation rules
      required,
      domains: [],
      lessonTypes: [],
      isTableLoading: false,
      grades: [],
      filterDomains: [],
      filterStandards: [],
      filterDomain: [],
      filterStandard: [],
      lessionPermission: [],
      createPdfUrl: '',
      auth: getUserData(),
      removePdf: false,
      defaultEngineOptions: [
        { text: 'Default', value: null },
        { text: 'DDF', value: 'ddf' },
        { text: 'CAF', value: 'caf' },
      ],
      lessonPagination: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
      },
      selectedRows: [],
      selectAll: false,
      hideEbook: 0,
      weakestSkill: 0,
      perPageFilter: 15,
      showBatchUpload: false,
      lessonIdToShowDetail: 0,
      maxScore: null,
      tags: [],
      scormLesson: 0,
      hideAiTutor: 0,
      tagsArray: [],
      scormLists: [],
      selectedScorm: null,
      cbLessonToGenerate: null,
      showLessonPrompt: false,
      lessonSummary: {},
      generatingSummary: false,
      isSummaryLesson: false,
      factorLessonInfo: null,
    }
  },
  computed: {
    langLevels() {
      return [
        { value: 1, text: this.getLangLevelTransText('A1') },
        { value: 2, text: this.getLangLevelTransText('A2') },
        { value: 3, text: this.getLangLevelTransText('B1') },
        { value: 4, text: this.getLangLevelTransText('B2') },
        { value: 5, text: this.getLangLevelTransText('C1') },
        { value: 6, text: this.getLangLevelTransText('C2') },
      ]
    },
    showGenerateSummaryButton() {
      return this.tags.includes('summary')
    },
  },
  watch: {
    scormLesson: {
      handler(value) {
        if (value) {
          this.getScormLists()
        }
      },
      immediate: true,
    },
    lessonImage() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.showPreview = true
          this.newItem = { ...this.newItem, ...{ image: reader.result } }
        },
        false,
      )
      if (this.lessonImage) {
        if (/\.(jpe?g|png|gif)$/i.test(this.lessonImage.name)) {
          reader.readAsDataURL(this.lessonImage)
        }
      }
    },
    subjectId() {
      useJwt.getDomainBySubject({ subject_id: this.subjectId })
        .then(res => {
          this.domains = res.data.data.map(domain => ({
            id: domain.id, text: domain.name,
          }))
        })
    },
  },
  created() {
    this.$root.$off('getLesson')
    this.$root.$on('getLesson', id => {
      this.filterStandard = []
      this.activeCourseId = id
      this.getLesson(id, [])
    })
    this.getDomains()
    this.getLessonTypes()
    this.getLessonPermission()
    this.getAlgorithms()
  },
  methods: {
    onScromListChanged() {
      const passScore = this.scormLists.find(item => item.cid === this.selectedScorm)?.passing_score || 0
      this.newItem.pass_percent = passScore
    },
    getScormLists() {
      useJwt.getGecScromList().then(response => {
        this.scormLists = response.data.data
      })
    },
    handleLinearLessonChange() {
      if (!this.newItem.lang_level) {
        this.newItem.lang_level = 1
      }
    },
    getAlgorithms() {
      useJwt.getLearningAlgorithms({
        parent: {
          is_public: 1,
        },
      }).then(res => {
        this.defaultEngineOptions = [...this.defaultEngineOptions, ...res.data.data.map(item => ({ text: item.name, value: item.name.toString() }))]
      }).finally(() => {
        // this.isProcessing = false
      })
    },
    standardSelected() {
      this.selected_id = null
      this.$emit('removedLesson')
      this.getLesson(null, this.filterStandard)
    },

    async getLessonPermission() {
      // const permission = await useJwt.getLessonsPermissions()
      // this.lessionPermission = permission.data.data.permissions
    },
    checkPermissionAction(id) {
      const check = this.lessionPermission.find(arr => Number(arr.permission.lesson_id) === id)
      return check ? check.allow : true
    },
    getLessonTypes() {
      useJwt.getLessonTypes()
        .then(res => {
          this.lessonTypes = res.data.data.map(type => ({ text: type.name, value: type.id }))
        })
    },
    getDomains() {
      useJwt.getDomain().then(res => {
        this.filterDomains = []
        res.data.data.data.map(domain => this.filterDomains.push({ value: domain.id, text: domain.name }))
      })
    },
    resetImage() {
      this.lessonImage = null
      this.newItem.image = this.rows.find(e => e.id === this.newItem.id).image
    },
    getLesson(id, sid, page = 1) {
      this.selected_id = id
      this.selectAll = false
      this.selectedRows = []
      this.isTableLoading = true
      const normalType = this.lessonTypes?.filter(a => a.text === 'Normal')
      const sdpType = this.lessonTypes?.filter(a => a.text === 'SDP')
      // const scormType = this.lessonTypes?.filter(a => a.text === 'ScormLesson')
      useJwt.getLesson(id, sid, page, this.perPageFilter).then(response => {
        this.rows = []
        this.allLessons = []
        this.rows = response.data.data.data.map(row => ({ ...row, type_id: row.type?.id }))
        if (response.data.data.data[0].type?.id === normalType[0]?.value) {
          this.lessonLinearEngine = 1
        } else this.lessonLinearEngine = 0
        if (response.data.data.data[0].type?.id === sdpType[0]?.value) {
          this.lessonSDPEngine = 1
        } else this.lessonSDPEngine = 0
        this.allLessons = JSON.parse(JSON.stringify(this.rows))
        this.lessonPagination = response.data.data.pagination
      }).finally(() => {
        this.isTableLoading = false
      })
    },
    setEngine(id, value) {
      const ind = this.rows.findIndex(row => row.id === id)
      let type = ''
      if (value === 'linear') {
        const typeObj = this.lessonTypes?.filter(a => a.text === 'Normal')[0]
        type = typeObj.value
      }

      const args = { default_engine: value, type }
      useJwt.updateLessonEngine(id, args)
        .then(res => {
          this.showSuccessMessage(res)
        })
        .catch(error => {
          this.showError(error)
        })
        .finally(() => {
          if (value === 'linear') this.getLesson(this.selected_id)
          this.rows[ind].engine = value
        })
    },
    // toggle public
    togglePublic(id, isPublic) {
      const args = {
        id,
        is_public: !isPublic ? 1 : 0,
        model: 'lesson',
        lesson_group_id: this.lessonGroupId,
      }
      useJwt.changeVisibility(args)
        .then(res => {
          this.showSuccessMessage(res)
        })
        .finally(() => {
          const index = this.rows.findIndex(row => row.id === id)
          this.rows[index].is_public = !isPublic
        })
    },
    toggleGameWrapper(id, isPublic) {
      const args = {
        id, has_game_wrapper: !isPublic ? 1 : 0, model: 'lesson_table_view', type: 'game',
      }
      useJwt.changeVisibility(args)
        .then(res => {
          this.showSuccessMessage(res)
        })
        .finally(() => {
          const index = this.rows.findIndex(row => row.id === id)
          this.rows[index].has_game_wrapper = !isPublic
        })
    },

    toggleGlobalCEFR(id, value) {
      const args = {
        id, cefr_scope: value,
      }
      useJwt.changeGlobalCEFR(args)
        .then(res => {
          this.showSuccessMessage(res)
        })
        .finally(() => {
          const index = this.rows.findIndex(row => row.id === id)
          this.rows[index].global_cefr = value
        })
    },

    toggleCreateModal(mode, funct, courseId) {
      this.lessonLinearEngine = 0
      this.lessonSDPEngine = 0
      this.course_id = courseId
      this.modalFunction = funct
      this.modalMode = mode
      this.newItem = {
        type_id: null,
        access_type: 'trial',
        use_on: 'all',
      }
      this.$refs['modal-create'].toggle()
    },

    toggleUpdateModal(item, mode, funct, courseId) {
      if (item.type?.name === 'ScormLesson') {
        this.scormLesson = 1
        useJwt.getLessonWiseScorm(item.scorm_map.scorm_lesson_id)
          .then(response => {
            this.maxScore = response.data.max_score
          })
      } else if (item.type?.name === 'Normal') this.lessonLinearEngine = 1
      else this.lessonLinearEngine = 0
      if (item.type?.name === 'SDP') this.lessonSDPEngine = 1
      else this.lessonSDPEngine = 0
      this.newItem = {
        id: item.id, type_id: item.type_id, name: item.name, pass_percent: item.pass_percent, access_type: item.access_type, image: item.image, lang_level: item.lang_level, live_link: item.live_link || '', lessonPdf: item.file || '', use_on: item.use_on,
      }
      this.selectedDomainsUpdate = item.domain ? item.domain.map(d => ({ id: d.id, text: d.name })) : []
      this.course_id = courseId
      this.modalFunction = funct
      this.hideEbook = item.hide_ebook
      this.hideAiTutor = item.hide_ai_tutor || 0
      this.weakestSkill = item.weakest_skill
      this.modalMode = mode
      this.selectedScorm = item.scorm_map?.scorm_lesson_id || null
      this.lessonSummary = {}
      if (this.modalFunction === 'updateLesson') {
        this.getLessonsTag(this.newItem.id)
      }
      this.$refs['modal-update'].toggle()
    },

    toggleDeleteModal(index, mode, funct) {
      this.deleteItemIndex = index
      this.modalDeleteMode = mode
      this.modalFunction = funct
      this.$bvModal.show('lesson-delete')
    },

    createItem() {
      this.$refs.createItemForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          if (this.selected_id) {
            this.newItem.lesson_group_id = this.selected_id
          }
          if (this.course_id) {
            this.newItem.course_id = this.course_id
          }

          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }

          const data = new FormData()
          let type
          if (this.$refs.refInputEl1.files[0]) data.append('image', this.$refs.refInputEl1.files[0])
          if (this.$refs.refInputEl2.files[0]) data.append('file', this.$refs.refInputEl2.files[0])
          data.append('name', this.newItem.name)
          data.append('pass_percent', this.newItem.pass_percent)
          data.append('access_type', this.newItem.access_type)
          data.append('hide_ebook', this.hideEbook)
          data.append('hide_ai_tutor', this.hideAiTutor)
          data.append('weakest_skill', this.weakestSkill)
          data.append('is_scorm_lesson', this.scormLesson)
          data.append('use_on', this.newItem.use_on)
          data.append('selected_scorm', this.selectedScorm)
          console.log(this.$refs.lessonPrompt)
          if (this.$refs.lessonPrompt) {
            // eslint-disable-next-line no-underscore-dangle
            data.append('lesson_prompts', JSON.stringify(this.$refs.lessonPrompt._setupProxy.getSaveParams()))
          }
          if (this.newItem.lang_level) data.append('lang_level', this.newItem.lang_level)
          if (this.scormLesson) {
            data.append('max_score', this.maxScore)
            type = this.lessonTypes?.filter(a => a.text === 'ScormLesson')
          } else if (this.lessonLinearEngine) {
            type = this.lessonTypes?.filter(a => a.text === 'Normal')
          } else if (this.lessonSDPEngine) {
            type = this.lessonTypes?.filter(a => a.text === 'SDP')
          } else {
            type = this.lessonTypes?.filter(a => a.text === 'Readability')
          }
          data.append('type_id', type[0]?.value)
          data.append('lesson_group_id', this.selected_id)
          if (this.newItem.live_link) data.append('live_link', this.newItem.live_link)
          for (let i = 0; i < this.selectedDomains.length; i += 1) {
            data.append(`domain_id[${i}]`, this.selectedDomains[i].id)
          }

          useJwt.axiosIns.post(`${jwtDefaultConfig.lessonsEndpoint}`, data, config).then(response => {
            this.isProcessing = false
            this.$refs['modal-create'].hide()
            this.reloadData(this.newItem, {})
            this.showSuccessMessage(response)
            this.scormLesson = 0
            this.selectedScorm = null
          })
            .catch(error => {
              this.isProcessing = false
              this.showErrorMessage(error)
              if (error.response?.data?.errors) {
                this.$refs.createItemForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
        }
      })
    },

    updateItem() {
      this.$refs.updateItemForm.validate().then(success => {
        if (success) {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }
          let type = ''
          const data = new FormData()
          if (this.$refs.refInputEl1.files[0]) data.append('image', this.$refs.refInputEl1.files[0])
          if (this.$refs.refInputEl2.files[0]) data.append('file', this.$refs.refInputEl2.files[0])
          data.append('name', this.newItem.name)
          data.append('pass_percent', this.newItem.pass_percent)
          data.append('access_type', this.newItem.access_type)
          data.append('live_link', this.newItem.live_link)
          data.append('hide_ebook', this.hideEbook)
          data.append('hide_ai_tutor', this.hideAiTutor)
          data.append('weakest_skill', this.weakestSkill)
          data.append('use_on', this.newItem.use_on)
          data.append('is_scorm_lesson', this.scormLesson)
          data.append('selected_scorm', this.selectedScorm)
          if (this.scormLesson) {
            data.append('max_score', this.maxScore)
            type = this.lessonTypes?.filter(a => a.text === 'ScormLesson')
          } else if (parseInt(this.lessonLinearEngine, 10)) {
            type = this.lessonTypes?.filter(a => a.text === 'Normal')
          } else if (parseInt(this.lessonSDPEngine, 10)) {
            type = this.lessonTypes?.filter(a => a.text === 'SDP')
          } else {
            type = this.lessonTypes?.filter(a => a.text === 'Readability')
          }
          data.append('type_id', type[0].value)
          data.append('id', this.newItem.id)
          data.append('lesson_group_id', this.selected_id)
          if (this.newItem.lang_level) data.append('lang_level', this.newItem.lang_level)

          for (let i = 0; i < this.selectedDomainsUpdate.length; i += 1) {
            data.append(`domain_id[${i}]`, this.selectedDomainsUpdate[i].id)
          }
          if (this.removePdf) data.append('remove_pdf', true)
          data.append('_method', 'put')

          this.isProcessing = true
          useJwt.axiosIns.post(`${jwtDefaultConfig.lessonsEndpoint}/${this.newItem.id}`, data, config).then(response => {
            this.$refs['modal-update'].hide()
            this.newItem = {}
            this.scormLesson = 0
            this.selectedScorm = null
            this.getLesson(this.selected_id)
            this.showSuccessMessage(response)
          })
            .catch(error => {
              this.showErrorMessage(error)
              if (error.response?.data?.errors) {
                this.$refs.updateItemForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },

    updateOrder() {
      const input = this.rows.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: 'lesson',
        input,
      })
    },

    searchTable() {
      let matchingLessons = []
      if (this.searchTerm.length > 0) {
        this.columns.filter(e => e.searchable).forEach(prop => {
          for (let index = 0; index < this.allLessons.length; index += 1) {
            const lesson = this.allLessons[index]
            const value = lesson[prop.field].toString()
            if (value && value.toLowerCase().includes(this.searchTerm.toLowerCase())) {
              matchingLessons.push(lesson)
              // eslint-disable-next-line no-continue
              continue
            }
          }
        })
      } else {
        matchingLessons = this.allLessons
      }

      this.rows = matchingLessons
    },

    openPdf(file) {
      this.activePdf = file
      this.showPdf = true
    },
    deletePdf() {
      this.newItem.lessonPdf = null
      this.$refs.refInputEl2.files[0] = null
      this.removePdf = true
    },
    getStandardsByDomain() {
      useJwt.getStandardsByDomain(this.filterDomain, true).then(res => {
        this.filterStandards = []
        this.filterStandard = []
        res.data.data.map(standard => this.filterStandards.push({ value: standard.id, text: standard.name }))
      })
    },
    handlePdfPreview(event) {
      this.createPdfUrl = window.URL.createObjectURL(event.target.files[0])
    },
    getEngineLowerCase(engine, type) {
      if (engine === null) {
        if (type && type.name === 'Normal') return 'linear'
        return 'adp'
      }
      return engine.toLowerCase()
    },
    selectAllRows() {
      if (this.selectAll) this.selectedRows = this.rows.map(row => row.id)
      else this.selectedRows = []
    },
    openBulkShareModal() {
      this.selected_id = this.selectedRows
      this.$bvModal.show('share-modal')
    },
    reloadData(item, value) {
      this[item] = value
      this.clearSelection()
      this.getLesson(this.selected_id)
    },
    clearSelection() {
      this.selectedRows = []
      this.selectAll = false
    },
    toggleCalibrationModal(row) {
      this.lessonId = row.id
      this.skillWeightage = {}
      useJwt.getLessonLevelCalibration(row.id).then(res => {
        if (Object.keys(res.data.data).length > 0) { this.skillWeightage = res.data.data }
      })
      this.$refs['modal-calibration'].show()
    },
    updateCalibration() {
      const args = { weightage: this.skillWeightage }
      useJwt.updateLessonLevelCalibration(this.lessonId, args).then(res => {
        this.showSuccessMessage(res)
      }).catch(err => {
        this.showErrorMessage(err)
      })
    },
    showCreateLessonPlanner(id, name) {
      this.activeLessonId = id
      this.activeLesson = name
      this.$bvModal.show('lessonPlannerCreateModal')
    },
    updateTags() {
      const tagAsString = this.tags.join(', ')
      useJwt.updateLessonTags(this.newItem.id, { tags: tagAsString })
        .catch(error => {
          console.log(error)
        })

      if (this.tags.includes('summary')) this.isSummaryLesson = true
      else {
        this.isSummaryLesson = false
        this.lessonSummary = {}
      }
    },
    toggleSummaryLesson(value) {
      if (value) {
        this.tags = [...this.tags, 'summary']
      } else {
        this.tags = this.tags.filter(tag => tag !== 'summary')
        this.lessonSummary = {}
      }
      this.updateTags()
    },
    generateSummary() {
      this.generatingSummary = true
      useJwt.getCBParagraphsByLesson(this.newItem.id)
        .then(response => {
          this.factorLessonInfo = {
            lesson_id: this.newItem.id,
            main_points: response.data.data.main_points,
          }
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.generatingSummary = false
        })
    },
    getLessonsTag(lessonId) {
      useJwt.getLessonsTags(lessonId)
        .then(response => {
          this.tags = response.data.data.split(',').map(item => item.toLowerCase())
          if (this.tags.includes('summary')) this.isSummaryLesson = true
          // if (this.tags.includes('scorm')) {
          //   this.scormLesson = 1
          // }
        }).catch(error => {
          console.log(error)
        })
    },
    updateUseOn() {
      if (this.newItem.access_type === 'trial') {
        // this.newItem.use_on = 'all'
        this.newItem = {
          ...this.newItem,
          use_on: 'all',
        }
      }
    },
  },
}
</script>

<style scoped>
.v-select-error{
  border: 1px solid #ea5455;
  border-radius: 8px;
}
.handle{
  cursor: grab;
  user-select: none;
}
.table-responsive .min-height {
  min-height: 380px;
}
.dropdown-action-icon {
  margin-right: 8px;
}
.dropdown-action:hover {
  background-color: rgba(115, 103, 240, 0.12);
}
.dark-layout .dropdown-action:hover {
  color: #fff;
}
</style>

<style>
.lesson-options .dropdown-menu {
  width: 220px;
  transform: translate3d(-190px, -84px, 0px) !important;
}
</style>
