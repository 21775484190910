<template>
  <div>
    <b-row
      v-if="!classRoomId"
    >
      <b-col
        md="12"
      >
        <div class="py-1 bg-white mb-1 rounded">
          <!-- Back to Courses -->
          <b-button
            v-if="$route.params.type == 'courses'"
            variant="flat-secondary"
            class="btn-icon"
            @click="$router.push({name: `${self.usertype}-courses`, params: { data: $route.params }})"
          >
            <span v-if="classRoomInfo"> &#8701;{{ $t('student-lab-module.courses') }}</span>
          </b-button>

          <!-- Back to Classroom -->
          <b-button
            v-else
            variant="flat-secondary"
            class="btn-icon"
            @click="viewClass"
          >
            <span v-if="classRoomInfo"> &#8701; {{ classRoomInfo.name }}</span>
          </b-button>
          |
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="$router.push({ name: self.usertype +'-classes' })"
          >
            {{ $t('student-lab-module.classroom-list') }}
          </b-button>
          |
          <b-button
            variant="flat-secondary"
            class="btn-icon"
            @click="$router.push({
              name: `${self.usertype}-class-calendar`,
              params: { id: $route.params.classId },
            })"
          >
            {{ $t('student-lab-module.calendar') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-overlay :show="isProcessing">
      <b-row>
        <b-col v-for="vl of vocabLessons"
               :key="vl.id"
               md="4"
        >
          <EventCard :item="vl"
                     :class-id="+classId"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import useApollo from '@/@core/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import EventCard from './components/EventCard.vue'

export default {
  components: {
    EventCard,
    BRow,
    BCol,
    BButton,
    BOverlay,
  },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      classRoomInfo: null,
      self: getUserData(),
      vocabLessons: [],
      isProcessing: false,
    }
  },
  computed: {
    classId() {
      return this.classRoomId ? this.classRoomId : this.$route.params.classId
    },
  },
  created() {
    this.getClassRoomInfo()
  },
  methods: {
    getClassRoomInfo() {
      useApollo.getClassRoomInfo(this.classId).then(response => {
        this.classRoomInfo = response.data.room
        this.getVocabLessonInfo()
      })
    },
    getVocabLessonInfo() {
      this.isProcessing = true
      useJwt.getVocabClassRoomDashboard(this.classId).then(response => {
        this.vocabLessons = response.data.data.event_data
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    viewClass() {
      this.$router.push({
        name: `${this.AUTH_USER().usertype}-view-students`,
        params: { id: this.classId },
      })
    },
  },
}
</script>
