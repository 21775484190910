<template>
  <b-card class="mt-1">
    <b-table
      :items="items"
      striped
      responsive
    />
  </b-card>
</template>

<script>
import {
  BTable, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BTable,
    BCard,
  },
  props: {
    activeTable: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    items: [],
  }),
  created() {
    useJwt.getStudentTableData(this.requestType())
      .then(response => {
        const res = response.data.data
        Object.keys(res).forEach((k, index) => {
          if (res[k]) this.items.push({ sn: index + 1, name: res[k].name })
        })
      })
  },
  methods: {
    requestType() {
      let type = ''
      if (this.activeTable === 'Courses') type = 'course'
      else if (this.activeTable === 'Lessons') type = 'lesson'
      else if (this.activeTable === 'Problems') type = 'problem'
      else if (this.activeTable === 'Standards') type = 'standard'
      return type
    },
  },
}
</script>
EventCard
