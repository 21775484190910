<template>
  <b-overlay
    id="report-card"
    :show="isProcessing"
  >
    <div>
      <b-row>
        <b-col
          cols="12"
          class="text-right pb-2"
        >
          <b-button
            variant="primary"
            data-html2canvas-ignore="true"
            @click="exportPdf()"
          >
            {{ $t('student-report-module.report-pages.export-pdf') }}
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <h4>
            {{ $t('student-report-module.report-pages.classroom-aggregate-skills-chart') }}
          </h4>
          <vue-apex-charts
            width="550"
            type="bar"
            :options="options"
            :series="series"
          />
        </b-col>
        <b-col md="6">
          <b-card no-body>
            <div class="card-body p-0">
              <h4 class="p-1">
                {{ $t('student-report-module.summary') }}
              </h4>
              <strength-table
                :title="$t('student-report-module.report-pages.skill')"
                :summary="getMinMax"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <h3 class="light">
              {{ $t('student-report-module.report-pages.language-level-of-students') }}
            </h3>
            <group-report-skill-charts
              :response="rawGroupReport"
              :categories="langLevelList"
            />
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <h3 class="light">
              {{ $t('student-report-module.report-pages.language-skill-level-of-students') }}
            </h3>
            <group-report-skill-charts
              :response="rawGroupReport"
              :categories="['reading', 'writing', 'listening', 'speaking', 'typing', 'pronunciation']"
              :colors="['#90EE90', '#77DD77', '#4CBB17', '#006400', '#32CD32', '#228B22']"
              type="by_skill"
            />

          </b-card>
        </b-col>

      </b-row>
    </div>
    <div>
      <b-row>
        <b-col md="4">
          <div class="light  pb-1 lead font-weight-bold">
            {{ $t('student-report-module.report-pages.cefr-rate-on-skills') }}
          </div>
        </b-col>
        <DateFilter
          :cols="8"
          @getFilteredDate="getGroupReportByDate"
        />
      </b-row>

    </div>
    <b-card body-class="p-0 table-responsive mb-0">
      <table class="table table-hover table-striped table-bordered table-sm">
        <thead>
          <tr>
            <th
              class="bg-skill-title"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UsersIcon"
                  class="mr-1"
                />{{ $t('student-report-module.report-pages.student-name') }}
              </div>
            </th>
            <th
              v-for="skill of skills"
              :key="skill.name"
              class="bg-skill-title text-nowrap"
            >
              <div class="d-flex">
                <feather-icon
                  :icon="skill.icon"
                  class="mr-1"
                />
                {{ $t(`student-report-module.report-pages.${skill.name}`) }}
              </div>
            </th>
            <th
              class="bg-skill-title text-nowrap"
            >
              <feather-icon
                icon="TrendingUpIcon"
                class="mr-1"
              />
              {{ $t('student-report-module.report-pages.level') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(report, index) of getTableReport"
            :key="index"
          >
            <td>
              <b>{{ report.fullName }}</b>
            </td>
            <td
              v-for="skill of skills"
              :key="skill.name"
              v-b-tooltip.hover
              class="text-center"
              :title="report[skill.name] && report[skill.name].title"
              :style="report[skill.name] && report[skill.name].color"
            >
              <div
                v-if="report[skill.name].value"
              >
                {{ numberFormat(report[skill.name].value) }}
              </div>
              <div v-else>
                0
              </div>
            </td>
            <td
              class="text-center"
              :style="{background: report.levelColor, color: 'white'}"
            >
              <b class="text-bold">
                {{ report.level }}
              </b>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!groupReport.length">
          <td
            colspan="100"
            class="text-center"
          >
            <h4>
              {{ $t('student-report-module.report-pages.no-data-found') }}
            </h4>
          </td>
        </tfoot>
      </table>
    </b-card>
  </b-overlay>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  VBTooltip, BOverlay, BCard, BRow, BButton,
  BCol,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import { getLevelFromScore, getColorForLevel, LANG_LEVELS } from '@/const/langLevel'
import GroupReportSkillCharts from '@/views/common/Charts/GroupReportSkillCharts.vue'
import VueApexCharts from 'vue-apexcharts'
import DateFilter from '@/views/common/components/DateFilter.vue'
import StrengthTable from './StrengthTable.vue'
import { ClassRoomSkillReport } from './helper/reportHelper'

export default {
  components: {
    BOverlay,
    GroupReportSkillCharts,
    BCard,
    BRow,
    BCol,
    StrengthTable,
    VueApexCharts,
    BButton,
    DateFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      classes: [
        { value: null, text: 'Please select a class', disabled: true },
      ],
      groupReport: [],
      skills: [
        {
          name: 'reading',
          icon: 'BookOpenIcon',
        },
        {
          name: 'writing',
          icon: 'PenToolIcon',
        },
        {
          name: 'listening',
          icon: 'Volume1Icon',
        },
        {
          name: 'speaking',
          icon: 'SpeakerIcon',
        },
        {
          name: 'typing',
          icon: 'TypeIcon',
        },
        {
          name: 'pronunciation',
          icon: 'MicIcon',
        },
      ],
      isProcessing: false,
      langLevelList: LANG_LEVELS,
      rawGroupReport: [],
      total_skill_type: 4,
      classroomSkillReport: [],
    }
  },
  computed: {
    series() {
      return [{
        name: 'series-1',
        data: Object.values(this.classroomSkillReport),
      }]
    },

    options() {
      return {
        colors: ['#28c76f'],
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: Object.keys(this.classroomSkillReport),
        },
        dataLabels: {
          formatter(val) {
            return `${val}%`
          },
        },
      }
    },
    getTableReport() {
      const finalReport = []
      this.groupReport.forEach(report => {
        const value = {
          fullName: report.fullName,
          level: report.averageLevel,
          levelColor: getColorForLevel(report.averageLevel),
        }
        this.skills.forEach(skill => {
          value[skill.name] = {
            title: report.data[skill.name]?.level,
            color: this.getLanguageColor(report.data[skill.name]?.level),
            value: this.numberFormat(report.data[skill.name]?.cefr_rate || 0),
          }
        })
        finalReport.push(value)
      })
      // this.groupReport.forEach(report => {
      //   const totalAttempt = this.getTotalAttempt(report.data)
      //   const value = {
      //     fullName: report.fullName,
      //   }
      //   this.skills.forEach(skill => {
      //     value[skill.name] = {
      //       title: report.data[skill.name] ? this.getReportHover(report.data[skill.name]) : '',
      //       color: this.getLanguageColor(report.data[skill.name]),
      //       value: this.numberFormat(report.data[skill.name]?.cefr_rate || 0),
      //     }
      //   })
      //   value.levelColor = totalAttempt.levelColor
      //   value.level = totalAttempt.level
      //   finalReport.push(value)
      // })
      // return finalReport
      return finalReport
    },
    getMinMax() {
      const rawSummary = []
      this.getTableReport.forEach(report => {
        const summary = {
          fullName: report.fullName,
        }
        this.skills.forEach(skill => {
          summary[skill.name] = Number(report[skill.name]?.value)
        })
        rawSummary.push(summary)
      })
      const finalSummary = {}
      this.skills.forEach(skill => {
        const array = rawSummary.map(raw => raw[skill.name])
        if (array.every(c => c >= 0)) {
          const maxValue = Math.max(...array)
          const minValue = Math.min(...array)
          finalSummary[skill.name] = {
            title: i18n.tc(`student-report-module.report-pages.${skill.name}`),
            strongest: rawSummary.filter(raw => raw[skill.name] === maxValue),
            weakest: rawSummary.filter(raw => raw[skill.name] === minValue),
          }
        }
      })
      return finalSummary
    },
  },
  mounted() {
    this.getGroupReport()
  },
  methods: {
    getGroupReport() {
      this.isProcessing = true
      useJwt.getGroupReport(this.classRoom.id).then(response => {
        this.classroomSkillReport = response.data.data?.classroomSkillReport
        const groupReport = ClassRoomSkillReport({ skillReport: response.data.data?.data }, this)

        this.groupReport = groupReport
        this.rawGroupReport = response.data.data?.data.map(item => ([...item.skill_type])).flat(1)
        this.total_skill_type = response.data?.data.total_skill_type
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getReportHover(report) {
      return `${getLevelFromScore(report.cefr_rate)}`
    },
    numberFormat(number) {
      return number ? Number(number).toFixed(2) : number
    },
    getLanguageColor(value) {
      if (!value) {
        return {
        }
      }
      return {
        background: getColorForLevel(value),
        color: 'black',
      }
    },
    // getTotalAttempt(summary) {
    //   let totalProblem = 0
    //   let cefrRateTotal = 0
    //   let totalAttempt = 0
    //   Object.entries(summary).forEach(([, value]) => {
    //     if (value) {
    //       totalProblem += Number(value?.attempt)
    //       cefrRateTotal += Number(value?.cefr_rate)
    //       totalAttempt += 1
    //     }
    //   })

    //   const languageLevel = totalAttempt > 0 ? getLevelFromScore(cefrRateTotal / Number(this.total_skill_type)) : ''
    //   return {
    //     total: totalProblem,
    //     cefrRateTotal,
    //     level: languageLevel,
    //     levelColor: getColorForLevel(languageLevel),
    //   }
    // },
    exportPdf() {
      const element = document.getElementById('report-card')
      this.printPdf(element, 'skill-report.pdf')
    },
    getGroupReportByDate(date) {
      this.isProcessing = true

      useJwt.getGroupReport(this.classRoom.id,
        {
          params: {
            from: date.from,
            to: date.to,
          },
        }).then(response => {
        const groupReport = ClassRoomSkillReport({ skillReport: response.data.data?.data }, this)

        this.groupReport = groupReport
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style>
  .bg-skill-title {
    background-color: #3b5346 !important;
    color: white;
  }
</style>
