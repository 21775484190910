<template>
  <b-modal
    :visible="visible"
    size="lg"
    hide-footer
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div v-if="!resultInfo">
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </div>
    <ResultView
      v-else
      :route-params="props.params"
      :result-info="resultInfo"
      view-type="modal"
      :teachers-student-id="studentId"
      :view-detail-table="viewDetailTable"
    />
  </b-modal>
</template>
<script setup>
import { BModal, BSkeleton } from 'bootstrap-vue'
import { ref, onMounted, watch } from 'vue'
import ResultView from '@/views/student/problem/compoments/Result.vue'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  studentId: {
    type: Number,
    required: true,
  },
  viewDetailTable: {
    type: Boolean,
    default: true,
  },
  showResult: {
    type: Boolean,
    default: true,
  },
})
const visible = ref(props.showResult)
const resultInfo = ref(null)

watch(() => props.showResult, value => {
  visible.value = value
})

// Fetch result information
const fetchResultInfo = () => {
  try {
    visible.value = true
    // Adjust the API endpoint and parameters as needed
    useJwt.getLessonResult(props.params.loaderId).then(res => {
      resultInfo.value = res.data.data
    })
  } catch (err) {
    console.error('Failed to fetch result information:', err)
  } finally {
    visible.value = false
  }
}
// Fetch data when component is mounted
onMounted(() => {
  fetchResultInfo()
})
</script>
