<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group>
          <label>{{ $t('class-module.start-date') }}</label>
          <b-form-datepicker
            v-model="start_date"
            class="mb-1 mr-2"
            :max="end_date"
            @input="getStudentAttendance"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label>{{ $t('class-module.end-date') }}</label>
          <b-form-datepicker
            v-model="end_date"
            :min="start_date"
            class="mb-1"
            @input="getStudentAttendance"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-between pb-1">
      <b-col
        md="5"
        cols="12"
        :class="{'mb-1': isMobileDevice()}"
      >
        <b-row>
          <b-col
            md="4"
            cols="6"
          >
            <button
              type="button"
              class="btn btn-icon rounded-circle btn-default"
              style="background: rgb(76, 175, 79);"
            /><span class="ml-1">{{ $t('day-on') }}</span>
          </b-col>
          <b-col
            md="4"
            cols="6"
          >
            <button
              type="button"
              class="btn btn-icon rounded-circle btn-default"
              style="background: grey;"
            /><span class="ml-1">{{ $t('day-off') }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="7">
        <b-row>
          <b-col
            md="3"
            cols="6"
            :class="{'mb-1': isMobileDevice()}"
          >
            <feather-icon
              v-b-tooltip.hover="$t('stop-all-timer')"
              icon="ClockIcon"
              size="18"
              class="text-danger"
              style="cursor: pointer; margin-top:-3px; margin-right: 3px;"
              @click="updateAllStudyTime"
            /> {{ $t('attendance-module.stop-timer') }}
          </b-col>
          <b-col
            class="d-flex"
            md="3"
            cols="6"
          >
            <b-form-checkbox
              v-model="checked"
              class="custom-control-success"
              disabled
            />
            <span>{{ $t('labels.verified') }}</span>
          </b-col>
          <b-col
            class="d-flex"
            md="3"
            cols="6"
          >
            <b-form-checkbox
              v-model="checked"
              class="custom-control-warning"
              disabled
            />
            <span>{{ $t('labels.not-verified') }}</span>
          </b-col>
          <b-col
            md="3"
            cols="6"
          >
            <feather-icon
              v-b-tooltip.hover="$t('refresh-attendances')"
              icon="RefreshCwIcon"
              size="18"
              style="cursor: pointer; margin-top: -3px; margin-right: 3px;"
              class="text-success"
              @click="getStudentAttendance"
            />
            <span>{{ $t('attendance-module.refresh') }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay :show="isProcessing">
      <b-table
        :sticky-header="true"
        :no-border-collapse="false"
        responsive
        :items="items"
        :fields="fields"
      >
        <template
          v-for="field in daysArray"
          #[`cell(${field.key})`]="{ item }"
        >
          <span
            :key="field.key"
            class="text-center d-flex"
          >
            <b-form-checkbox
              v-b-tooltip.hover="item[field.key] && item[field.key].remarks"
              :checked="!!item[field.key]"
              :disabled="isProcessing || (item[field.key]?.remarks === 'auto verified by system')"
              role="button"
              :class="getCheckBoxClass(item[field.key])"
              @change="createAttendance({
                date: field.key,
                userId: item.studentId,
              })"
            />
            <span v-if="item[field.key] && item[field.key].study_time !== null">

              <span v-if="item[field.key].study_time>0"
                    v-b-tooltip.hover="`${$t('completed')}`"
                    style="font-size: 13px; color:#ea5455;"
              >
                {{ getTimeFromSecondToHour(item[field.key].study_time) }}
              </span>
              <span v-else
                    v-b-tooltip.hover="`${$t('individual-report-module.status.in_progress')}`"
              >
                <span v-if="field.key !== today_date"
                      style="font-size: 13px; color:#28c76f; cursor: pointer;"
                      @click="editUpdateStudyTime(item.studentId, field.key )"
                >
                  <AttendanceTimer
                    :time-in-second="studyTimeDuration(item[field.key])"
                  />
                </span>
                <span v-else
                      style="font-size: 13px; color:#28c76f;"
                >
                  <AttendanceTimer
                    :time-in-second="studyTimeDuration(item[field.key])"
                  />
                </span>
                <span style="font-size: 11px; color:#28c76f;">
                  Live
                </span>
              </span>
            </span>

          </span>
        </template>
        <template #cell(total_verified)="{ item }">
          <b
            v-if="item.total"
            :set="totalAttendance = item.total.filter(t => t.verified_by != null).length"
          >
            {{ totalAttendance }}
            <span v-if="totalAttendance > 1">{{ $t('labels.days') }}</span><span v-else-if="totalAttendance">{{ $t('labels.day') }}</span>
          </b>
        </template>
        <template #cell(total_unverified)="{ item }">
          <b
            v-if="item.total"
            :set="totalAttendance = item.total.filter(t => t.verified_by == null).length"
          >
            {{ totalAttendance }}
            <span v-if="totalAttendance > 1">{{ $t('labels.days') }}</span><span v-else-if="totalAttendance">{{ $t('labels.day') }}</span>
          </b>
        </template>
        <template #cell(total_time)="{ item }">
          {{ getTotalTimeSpend(item) }}
        </template>
        <template #cell(action)="{ item }">
          <template v-if="checkAttendance(item)">
            <b-button v-if="studyTimeDuration(item[today_date]) > classMaxTime"
                      v-b-tooltip.hover="`Stop Class`"
                      variant="outline-danger"
                      @click="editUpdateStudyTime(item.studentId)"
            >
              <feather-icon
                icon="StopCircleIcon"
                size="18"
                class="mx-1"
                style="cursor: pointer;"
              />
            </b-button>
            <b-button v-else
                      v-b-tooltip.hover="`Stop Class`"
                      variant="outline-danger"
                      @click="updateStudyTime(item.studentId)"
            >
              <feather-icon
                icon="StopCircleIcon"
                size="18"
                class="mx-1"
                style="cursor: pointer;"
              />
            </b-button>
          </template>
        </template>
      </b-table>
    </b-overlay>
    <b-row class="w-100">
      <b-col
        md="8"
      >
        <p class="text-muted">
          {{ $t('attendance-module.attendance-operate-info') }}
          <feather-icon icon="InfoIcon"
                        style="cursor:pointer"
                        @click="attendanceInfoModal = true"
          />
        </p>
      </b-col>
      <b-col
        md="4"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="success"
          @click="activeModal = 'bulk-verify'"
        >
          {{ $t('labels.verify') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- </template> -->
    <!-- </b-modal> -->
    <confirm-modal
      :show="activeModal === 'bulk-verify' "
      :title="$t('labels.verify')"
      varient="success"
      :sub-title="$t('attendance-module.confirm-verify')"
      :on-delete="makeBulkVerification"
      :is-processing-delete="isVerifying"
      @close="activeModal = ''"
    />
    <b-modal
      content-class="shadow"
      :title="$t('attendance-module.edit-live-class-time')"
      no-fade
      size="sm"
      ok-only
      :visible="editLiveClassTimeModal"
      @close="closeLiveClassTimeModal()"
    >
      <b-row>
        <b-col>
          <b-form-group>
            <label>{{ $t('attendance-module.add-time-for-live-class') }})</label>
            <b-form-input
              v-model="editClassTime.time"
            />
          </b-form-group>
          <p class="text-muted"
             style="font-size:13px; font-style: italic;"
          >
            <feather-icon icon="InfoIcon" />
            {{ $t('attendance-module.add-time-for-live-class-info') }}
          </p>
        </b-col>
      </b-row>
      <template #modal-footer="">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="success"
              @click="updateStudyTime(editClassTime.studentId, editClassTime.date)"
            >
              {{ $t('labels.update') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      content-class="shadow"
      :title="$t('attendance-module.info-title')"
      no-fade
      size="lg"
      ok-only
      :visible="attendanceInfoModal"
      no-close-on-backdrop
      @close="attendanceInfoModal = false"
      @ok="attendanceInfoModal = false"
    >
      <b-row>
        <b-col
          md="12"
        >
          <ul
            class="infolist"
          >
            <li>
              <span>{{ $t('initiating-timer') }}</span>
              <ul>
                <li>
                  {{ $t('initiating-timer-info') }}
                </li>
              </ul>
            </li>
            <li>
              <span>{{ $t('scheduled-day-restriction') }}</span>
              <ul>
                <li>
                  {{ $t('scheduled-day-restriction-info') }}
                </li>
              </ul>
            </li>
            <li>
              <span>{{ $t('automatic-timer-stop') }}</span>
              <ul>
                <li>
                  {{ $t('automatic-timer-stop-info') }}
                </li>
              </ul>
            </li>
            <li>
              <span>
                {{ $t('default-maximum-session-time') }}
              </span>
              <ul>
                <li>
                  {{ $t('default-maximum-session-time-info') }}
                </li>
              </ul>
            </li>
            <li>
              <span>
                {{ $t('teacher-manual-adjustment') }}
              </span>
              <ul>
                <li>
                  {{ $t('teacher-manual-adjustment-info') }}
                </li>
              </ul>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BTable, BFormDatepicker, BOverlay, BFormGroup, BRow, BCol, BButton, BFormCheckbox, VBTooltip, BFormInput,
} from 'bootstrap-vue'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmModal from '@/views/common/components/DeleteModal.vue'
import AttendanceTimer from '@/views/student/attendance/components/AttendanceTimer.vue'

export default {
  components: {
    BModal,
    BTable,
    BFormDatepicker,
    BOverlay,
    BFormGroup,
    BRow,
    BCol,
    ConfirmModal,
    BButton,
    BFormCheckbox,
    AttendanceTimer,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classId: {
      type: Number,
      default: 0,
    },
    students: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      today_date: moment().format('YYYY-MM-DD'),
      start_date: moment().subtract('6', 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      items: [],
      studentAttendance: [],
      isProcessing: false,
      isVerifying: false,
      activeModal: '',
      today: new Date(),
      checked: true,
      teacherSchedular: [],
      classMaxTime: null,
      editLiveClassTimeModal: false,
      editClassTime: {},
      attendanceInfoModal: false,
    }
  },
  computed: {
    daysArray() {
      const startOfWeek = moment(this.start_date)
      const endOfWeek = moment(this.end_date)

      const days = []
      let day = startOfWeek
      while (day <= endOfWeek) {
        const key = day.format('Y-MM-DD')
        const schedular = this.teacherSchedular.find(schedule => schedule.day === key)
        let thClass
        if (schedular) {
          thClass = schedular.type === 'reserver' ? 'bg-success text-white' : 'bg-secondary text-white'
        }
        days.push({
          label: day.format('MMM Do, ddd'),
          key,
          thClass,
        })
        day = day.clone().add(1, 'd')
      }
      return days
    },
    fields() {
      return [
        {
          key: 'student',
          stickyColumn: true,
          isRowHeader: true,
          tdClass: 'text-nowrap',
          label: this.$i18n.tc('student'),
        },
        ...this.daysArray,
        {
          key: 'total_verified',
          label: this.$i18n.tc('total_verified'),
        },
        {
          key: 'total_unverified',
          label: this.$i18n.tc('total_unverified'),
        },
        {
          key: 'total_time',
          label: this.$i18n.tc('total_time'),
        },
        {
          key: 'action',
          label: this.$i18n.tc('actions.action'),
        },
      ]
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.getStudentAttendance()
        this.getMaxTimeLimit()
      }
    },
  },
  created() {
    this.getMaxTimeLimit()
    this.getStudentAttendance()
  },
  methods: {
    getStudentAttendance() {
      this.isProcessing = true
      useJwt.getStudentAttendanceWithLiveLink({
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          classroom_id: this.classId,
        },
      }).then(response => {
        const { attendance, teacherSchedular } = response.data.data
        this.teacherSchedular = teacherSchedular
        const items = []
        this.students.forEach(student => {
          const item = {}
          item.student = student.fullname
          item.studentId = student.id
          const totalPresent = []
          this.daysArray.forEach(day => {
            const isPresent = attendance.find(record => Number(record.user_id) === Number(student.id) && record.attendance_date === day.key) ?? null
            item[day.key] = isPresent
            if (isPresent) totalPresent.push(isPresent)
          })
          items.push(item)
          item.total = totalPresent
        })
        this.items = items
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    makeBulkVerification() {
      this.isVerifying = true
      useJwt.bulkVerifyStudentAttendanceWithLiveLink({
        start_date: this.start_date,
        end_date: this.end_date,
        classroom_id: this.classId,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getStudentAttendance()
        this.activeModal = ''
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isVerifying = false
      })
    },
    createAttendance({ date, userId }) {
      useJwt.updateStudentAttendanceByTeacher({
        date,
        student_id: userId,
        classroom_id: this.classId,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getStudentAttendance()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isVerifying = false
      })
    },
    getCheckBoxClass(item) {
      if (!item) return 'custom-control-primary'
      if (item.verified_by) return 'custom-control-success'
      return 'custom-control-warning'
    },
    checkAttendance(data) {
      const today = moment().format('YYYY-MM-DD')
      const hasValueForDate = data.total.filter(item => item.attendance_date === today)[0]?.id !== undefined
      const completed = data.total.filter(item => item.attendance_date === today)[0]?.study_time === 0
      let hasData = false
      if (hasValueForDate && completed) {
        hasData = true
      }
      return hasData
    },
    updateStudyTime(studentId, date = null) {
      useJwt.updateStudentStudyTime({
        date: date || moment().format('YYYY-MM-DD'),
        student_id: studentId,
        classroom_id: this.classId,
        classTime: this.editClassTime?.time,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getStudentAttendance()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.editClassTime = {}
        this.editLiveClassTimeModal = false
        this.isVerifying = false
      })
    },
    getTimeFromSecondToHour(timeInSecond) {
      const hours = Math.floor(timeInSecond / 3600)
      const minutes = Math.floor((timeInSecond % 3600) / 60)
      const formattedHours = hours.toString().padStart(2, '0')
      const formattedMinutes = minutes.toString().padStart(2, '0')
      return `${formattedHours}:${formattedMinutes}`
    },
    studyTimeDuration(item) {
      const currentTime = moment()
      const createdTime = moment(item?.created_at)
      const durationInSeconds = currentTime.diff(createdTime, 'seconds')
      return durationInSeconds
    },
    getTotalTimeSpend(data) {
      const sumOfStudyTime = data.total
        .filter(item => item.study_time > 0)
        .reduce((sum, item) => sum + item.study_time, 0)
      return this.getTimeFromSecondToHour(sumOfStudyTime)
    },
    updateAllStudyTime() {
      useJwt.updateAllStudyTime({
        classroom_id: this.classId,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getStudentAttendance()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isVerifying = false
      })
    },
    getMaxTimeLimit() {
      useJwt.getClassroomSetting(this.classId, 'live_class_max_time_limit').then(res => {
        this.classMaxTime = res.data.data * 60 // converted to second
        this.editClassTime.time = res.data.data ? res.data.data : 60
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    editUpdateStudyTime(studentId, date = null) {
      this.editClassTime.studentId = studentId
      if (date) {
        this.editClassTime.date = date
      } else {
        this.editClassTime.date = null
      }
      this.editLiveClassTimeModal = true
    },
    closeLiveClassTimeModal() {
      this.editClassTime = {}
      this.editLiveClassTimeModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.infolist {
  padding-left:16px;
  li {
    list-style:decimal;
    span {
      font-weight: 500; font-size:15px
    }
    ul {
      padding-left:0px; margin: 10px; margin-left: 16px;
      li {
        margin-bottom:5px;
        list-style:disc;
      }
    }
  }
}
</style>
<style lang="scss">
.dark-layout {
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    background-color: transparent  !important;
  }
}
</style>
