var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"title":_vm.$t('report.latest-rubric')}},[_c('div',[_c('v-select',{staticClass:"m-md",attrs:{"options":_vm.searchRooms,"filterable":false,"reduce":(room) => room.id,"disabled":_vm.isProcessing,"placeholder":_vm.$t('class-module.search-classroom'),"label":"name","clearable":false},on:{"input":_vm.getRubrics,"search":_vm.searchClass},model:{value:(_vm.searchTermForClasses),callback:function ($$v) {_vm.searchTermForClasses=$$v},expression:"searchTermForClasses"}})],1),_c('div',{staticClass:"pt-2"},[_c('b-overlay',{attrs:{"show":_vm.isProcessing}},[_c('student-rubric-table',{attrs:{"is-a-teacher":false,"no-action":true,"rubrics":_vm.studentRubrics},on:{"navigateToCoursePage":(id, lessons) =>{
              _vm.$router.push({
                name: `${_vm.self.usertype}-lessons`,
                params: { classId: _vm.searchTermForClasses, id: id },
                query: { student_id: _vm.userId },
              })
            }}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }