<template>
  <b-tabs
    pills
    class="social-group-report"
  >
    <b-tab
      v-for="report of filteredReportTypes"
      :key="report.value"
      :title="report.text"
      lazy
      @click="selectedReport = report.value"
    >
      <component
        :is="report.component"
        :class-room="classRoom"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  VBTooltip, BTabs, BTab,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import NoteReport from '../GroupReport/NoteReport.vue'
import ChatReport from '../GroupReport/ChatReport.vue'
import RoleReport from '../GroupReport/RoleReport.vue'

export default {
  components: {
    NoteReport,
    ChatReport,
    RoleReport,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    classRoom: {
      type: Object,
      default: () => {},
    },
    socialSkillsReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedReport: 'note-report',
      reportType: [
        {
          text: i18n.tc('setting-module.note-report-title'),
          value: 'note-report',
          component: 'NoteReport',
        },
        {
          text: i18n.tc('setting-module.chat-report-title'),
          value: 'chat-report',
          component: 'ChatReport',
        },
        {
          text: i18n.tc('setting-module.role-report-title'),
          value: 'role-report',
          component: 'RoleReport',
        },
      ],
    }
  },
  computed: {
    filteredReportTypes() {
      let filteredTypes = this.reportType.slice() // Make a copy of the report types
      if (this.socialSkillsReport.note === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'note-report')
      }
      if (this.socialSkillsReport.chat === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'chat-report')
      }
      if (this.socialSkillsReport.role === 1) {
        filteredTypes = filteredTypes.filter(type => type.value !== 'role-report')
      }
      return filteredTypes
    },
  },
}
</script>
<style lang="scss">
.social-group-report {
  ul.nav {
    .nav-item {
      a {
        border-radius: 23px;
        padding: 7px 10px;
      }
    }
  }
}
</style>
